import {
  ADD_REFERENCE_RATE,
  DeliveryService,
  ReferenceActions,
  Seller,
  SET_DELIVERY_SERVICES,
  SET_RATE_LIST,
  SET_REFERENCE_RATE_GROUP_LIST,
  SET_SELLER_LIST,
  SET_SURCHARGE_TYPES_LIST,
  SurchargeType,
  REMOVE_REFERENCE_RATE,
  UPDATE_REFERENCE_RATE,
  SetRateList,
  AddReferenceRate,
  RemoveReferenceRate,
  UpdateReferenceRate,
  SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  AdditionalServiceDescription,
} from './types';
import { Rate, RateGroup } from '../rate/types';
import removeByFieldValue from '../../utils/array/remove';
import replaceByFieldValue from '../../utils/array/replace';

export interface ReferenceState {
  deliveryServicesList: Array<DeliveryService>,
  deliveryServices: {[key: string]: DeliveryService},
  sellerList: Seller[],
  surchargeTypesList: SurchargeType[],
  rateGroupList: RateGroup[],
  rateList: Rate[],
  rates: {[key: string]: Rate},
  additionalServicesDesc: Array<AdditionalServiceDescription>

}

const initialState: ReferenceState = {
  deliveryServicesList: [],
  deliveryServices: {},
  sellerList: [],
  surchargeTypesList: [],
  rateGroupList: [],
  rateList: [],
  rates: {},
  additionalServicesDesc: [],
};

function prepareDeliveryServices(rawDeliveryServices: Array<DeliveryService>): {[key: string]: DeliveryService} {
  const deliveryServices: {[key: string]: DeliveryService} = {};
  if (rawDeliveryServices) {
    for (let i = 0; i < rawDeliveryServices.length; i++) {
      deliveryServices[rawDeliveryServices[i].key] = rawDeliveryServices[i];
    }
  }
  return deliveryServices;
}

const prepareRates = (rateList: Rate[]) => {
  const rates: {[key: string]: Rate} = {};
  rateList.forEach((rate: Rate) => {
    rates[rate.code] = rate;
  });
  return rates;
};

const handleRateActions = (state: ReferenceState, action: SetRateList | AddReferenceRate | RemoveReferenceRate | UpdateReferenceRate) => {
  const newState = { ...state };

  switch (action.type) {
    case SET_RATE_LIST:
      newState.rateList = action.list;
      break;
    case ADD_REFERENCE_RATE:
      newState.rateList = [...newState.rateList, action.rate];
      break;
    case REMOVE_REFERENCE_RATE:
      newState.rateList = removeByFieldValue(newState.rateList, 'rateId', action.rateId);
      break;
    case UPDATE_REFERENCE_RATE:
      newState.rateList = replaceByFieldValue(newState.rateList, 'rateId', action.rate);
      break;
    default:
      break;
  }

  newState.rates = prepareRates(newState.rateList);

  return newState;
};

export default function (state = initialState, action: ReferenceActions): ReferenceState {
  switch (action.type) {
    case SET_DELIVERY_SERVICES:
      return { ...state, deliveryServicesList: action.deliveryServices, deliveryServices: prepareDeliveryServices(action.deliveryServices) };
    case SET_SELLER_LIST:
      return { ...state, sellerList: action.list };
    case SET_SURCHARGE_TYPES_LIST:
      return { ...state, surchargeTypesList: action.list };
    case SET_REFERENCE_RATE_GROUP_LIST:
      return { ...state, rateGroupList: action.list };
    case SET_RATE_LIST:
    case ADD_REFERENCE_RATE:
    case REMOVE_REFERENCE_RATE:
    case UPDATE_REFERENCE_RATE:
      return handleRateActions(state, action);
    case SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST:
      return { ...state, additionalServicesDesc: action.payload };
    default: return state;
  }
}
