import moment from "moment";

type anyObject = {
  [key: string]: any;
}

type sortArrayOfObjectConfig = {
  reversed?: boolean,
  date?: boolean
}

export default function sortArrayOfObject<T extends anyObject>(arr: Array<T>, key: string, config?: sortArrayOfObjectConfig): Array<T> {
  const tmp = arr.slice();
  if (config?.date) {
    tmp.sort((a, b) => {
      // @ts-ignore
      return new Date(b[key]) - new Date(a[key]);
    })
  } else {
    tmp.sort((a, b) => {
      if (moment.isMoment(a[key]) && moment(b[key])) {
        if (a[key].isBefore(b[key])) return -1;
        if (a[key].isAfter(b[key])) return 1;
        return 0;
      }
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  }
  if (config?.reversed) tmp.reverse();
  return tmp;
}


let sort_by: any;
(function () {
  // utility functions
  const default_cmp = function <T extends anyObject>(a: T, b: T) {
    if (a == b) return 0;
    return a < b ? -1 : 1;
  };
  const getCmpFunc = function (primer: any, reverse: boolean, moment: boolean) {
    let cmp = default_cmp;
    if (moment) {
      cmp = function (a, b) {
        if (a.isSame(b)) return 0;
        return a.isBefore(b) ? -1 : 1;
      };
    }
    if (primer) {
      cmp = function (a, b) {
        return default_cmp(primer(a), primer(b));
      };
    }
    if (reverse) {
      return function <T extends anyObject>(a: T, b: T) {
        return -1 * cmp(a, b);
      };
    }
    return cmp;
  };

  // actual implementation
  sort_by = function () {
    const fields: anyObject[] = [];
    const n_fields = arguments.length;
    let field; let name; let reverse; let
      cmp;

    // preprocess sorting options
    for (let i = 0; i < n_fields; i++) {
      field = arguments[i];
      if (typeof field === 'string') {
        name = field;
        cmp = default_cmp;
      } else {
        name = field.name;
        cmp = getCmpFunc(field.primer, field.reverse, field.moment);
      }
      fields.push({
        name,
        cmp,
      });
    }

    return function<T extends anyObject>(A: T, B: T) {
      let a; let b; let name; let cmp; let
        result;
      let i = 0; const
        l = n_fields;
      for (; i < l; i++) {
        result = 0;
        field = fields[i];
        name = field.name;
        cmp = field.cmp;

        result = cmp(A[name], B[name]);
        if (result !== 0) break;
      }
      return result;
    };
  };
}());

export { sort_by };

