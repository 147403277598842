import {
  ADD_REFERENCE_RATE,
  AdditionalServiceDescription,
  AddReferenceRate,
  DeliveryService,
  REMOVE_REFERENCE_RATE,
  RemoveReferenceRate,
  Seller,
  SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  SET_DELIVERY_SERVICES,
  SET_RATE_LIST,
  SET_REFERENCE_RATE_GROUP_LIST,
  SET_SELLER_LIST,
  SET_SURCHARGE_TYPES_LIST,
  SetAdditionalServiceDescList,
  SetDeliveryServices,
  SetRateList,
  SetReferenceRateGroupList,
  SetSellerList,
  SetSurchargeTypesList,
  SurchargeType,
  UPDATE_REFERENCE_RATE,
  UpdateReferenceRate,
} from './types';
import { ThunkAction } from '../../utils/types';
import { Rate, RateGroup } from '../rate/types';

export const setDeliveryServices = (deliveryServices: Array<DeliveryService>): SetDeliveryServices => ({
  type: SET_DELIVERY_SERVICES,
  deliveryServices,
});

export const setSellerList = (list: Seller[]): SetSellerList => ({
  type: SET_SELLER_LIST,
  list,
});

export const setSurchargeTypesList = (list: SurchargeType[]): SetSurchargeTypesList => ({
  type: SET_SURCHARGE_TYPES_LIST,
  list,
});

export const setReferenceRateGroupList = (list: RateGroup[]): SetReferenceRateGroupList => ({
  type: SET_REFERENCE_RATE_GROUP_LIST,
  list,
});

export const setRateList = (list: Rate[]): SetRateList => ({
  type: SET_RATE_LIST,
  list,
});

export const addReferenceRate = (rate: Rate): AddReferenceRate => ({
  type: ADD_REFERENCE_RATE,
  rate,
});

export const removerReferenceRate = (rateId: number): RemoveReferenceRate => ({
  type: REMOVE_REFERENCE_RATE,
  rateId,
});

export const updateReferenceRate = (rate: Rate): UpdateReferenceRate => ({
  type: UPDATE_REFERENCE_RATE,
  rate,
});

export const setAdditionalServiceDescList = (payload: Array<AdditionalServiceDescription>): SetAdditionalServiceDescList => ({
  type: SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  payload,
});

export const loadDeliveryServices = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/rates/delivery_services/').then(
  (response: {deliveryServices: Array<DeliveryService>}) => {
    dispatch(setDeliveryServices(response.deliveryServices));
    return Promise.resolve(response);
  },
);

export const getRateList = (deliveryService?: string | null, query?: string | null): ThunkAction => (dispatch, getState, http) => {
  const params: any = {};

  if (deliveryService) params.delivery_service = deliveryService;
  if (query) params.query = query;

  return http.get('/api/v1/rates/reference/rate/list/', params);
};

export const loadSellerList = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/contract/seller/list/',
).then(
  (response: {list: Seller[]}) => {
    dispatch(setSellerList(response.list));
    return Promise.resolve(response);
  },
);

export const loadSurchargeTypesList = (): ThunkAction<void> => (dispatch, getState, http) => http.get(
  '/api/v1/billing/surcharge/type/list/',
).then(
  ({ items }: {items: SurchargeType[]}) => {
    dispatch(setSurchargeTypesList(items));
  },
);

export const loadReferenceRateGroupList = (): ThunkAction<void> => (dispatch, getState, http) => http.get(
  '/api/v1/rates/rate/group/list/',
  { page: 0 },
).then(
  ({ groups }: { groups: RateGroup[] }) => dispatch(setReferenceRateGroupList(groups)),
);

export const loadRateReference = (): ThunkAction<void> => (dispatch, getState, http) => http.get(
  '/api/v1/rates/rate/list/',
  { return_all: true },
).then(
  ({ rates }: { rates: Rate[] }) => {
    dispatch(setRateList(rates));
  },
);

export const getAdditionalServicesByKeys = (): ThunkAction => (dispatch, getState, http) => {
  return http.get('/api/v1/rates/additional_services/list/', { keys: ["ALL"] }).then(
    ({ items }: { items: any }) => {
      dispatch(setAdditionalServiceDescList(items))
      return Promise.resolve(items);
    },
  );
}

export const loadReferences = (): ThunkAction<void> => (dispatch) => {
  dispatch(loadDeliveryServices());
  dispatch(loadSellerList());
  dispatch(loadSurchargeTypesList());
  dispatch(loadReferenceRateGroupList());
  dispatch(loadRateReference());
};
