import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ClaimList = React.lazy(() => import('../components/ClaimList'));
function ClaimListView(params: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ClaimList {...params} />
    </Suspense>
  );
}

export default ClaimListView;
