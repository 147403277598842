export const SET_OPERATOR_KEY_LIST = 'SET_OPERATOR_KEY_LIST';
export const SET_OPERATOR_SCHEDULER_SETTINGS = 'SET_OPERATOR_SCHEDULER_SETTINGS';
export const SET_GEO_POLYGON_LIST = 'SET_GEO_POLYGON_LIST';
export const SET_GEO_POLYGON_COUNT = 'SET_GEO_POLYGON_COUNT';
export const SET_GEO_POLYGON_DETAIL = 'SET_GEO_POLYGON_DETAIL';
export const RESET_GEO_POLYGON_DETAIL = 'RESET_GEO_POLYGON_DETAIL';
export const UPDATE_GEO_POLYGON_DETAIL = 'UPDATE_GEO_POLYGON_DETAIL';
export const SET_APISHIP_DELIVERY_SERVICE_LIST = 'SET_APISHIP_DELIVERY_SERVICE_LIST';
export const REPLACE_APISHIP_DELIVERY_SERVICE = 'REPLACE_APISHIP_DELIVERY_SERVICE';
export const REMOVE_APISHIP_DELIVERY_SERVICE = 'REMOVE_APISHIP_DELIVERY_SERVICE';
export const ADD_APISHIP_DELIVERY_SERVICE = 'ADD_APISHIP_DELIVERY_SERVICE';

export type OperatorKey = {
  key: string;
  title: string;
  sellerId: number;
  operator: string;
};

export type SchedulerSetting = {
  weekday: number,
  limit: string,
};

export type OperatorSchedulerSetting = {
  operator: string,
  settings: SchedulerSetting[],
};

export type GeoPolygonListItem = {
  geoPolygonId: number,
  alias: string,
  city: string,
  region: string,
  district: string,
  postcode: string,
  iso: string,
  deliveryService: string,
  enabled: string,
};

export type MultiPolygon = Array<Array<Array<Array<number>>>>;

export type GeoPolygonDetailItem = GeoPolygonListItem & {
  polygon: MultiPolygon,
};

export type ApishipDeliveryService = {
  deliveryServiceId: number,
  key: string,
  publicEnabled: boolean,
  carrierEnabled: boolean,
  shopEnabled: boolean,
  icon: string,
  title: string,
};

export interface SetOperatorKeyList {
  type: typeof SET_OPERATOR_KEY_LIST,
  list: OperatorKey[],
}

export interface SetOperatorSchedulerSettings {
  type: typeof SET_OPERATOR_SCHEDULER_SETTINGS,
  settings: OperatorSchedulerSetting[]
}

export interface SetGeoPolygonList {
  type: typeof SET_GEO_POLYGON_LIST,
  list: GeoPolygonListItem[],
}

export interface SetGeoPolygonCount {
  type: typeof SET_GEO_POLYGON_COUNT,
  count: number,
}

export interface SetGeoPolygonDetail {
  type: typeof SET_GEO_POLYGON_DETAIL,
  detail: GeoPolygonDetailItem,
}

export interface ResetGeoPolygonDetail {
  type: typeof RESET_GEO_POLYGON_DETAIL,
}

export interface UpdateGeoPolygonDetail {
  type: typeof UPDATE_GEO_POLYGON_DETAIL,
  detail: RequireOne<Partial<GeoPolygonDetailItem>, 'geoPolygonId'>,
}

export interface SetApishipDeliveryServiceList {
  type: typeof SET_APISHIP_DELIVERY_SERVICE_LIST,
  list: ApishipDeliveryService[],
}
export interface ReplaceApishipDeliveryService {
  type: typeof REPLACE_APISHIP_DELIVERY_SERVICE,
  service: ApishipDeliveryService,
}
export interface RemoveApishipDeliveryService {
  type: typeof REMOVE_APISHIP_DELIVERY_SERVICE,
  serviceId: number,
}
export interface AddApishipDeliveryService {
  type: typeof ADD_APISHIP_DELIVERY_SERVICE,
  service: ApishipDeliveryService,
}

export type DeliveryServiceActions = SetOperatorKeyList | SetOperatorSchedulerSettings | SetGeoPolygonList |
SetGeoPolygonCount | SetGeoPolygonDetail | ResetGeoPolygonDetail | UpdateGeoPolygonDetail | SetApishipDeliveryServiceList |
  ReplaceApishipDeliveryService | RemoveApishipDeliveryService | AddApishipDeliveryService;
