import React from 'react';
import _camelCase from 'lodash/camelCase';
import _snakeCase from 'lodash/snakeCase';
import {
  Col, Modal, Row, Input as OriginalInput, Form as AntForm,
} from 'antd';
import { Input, Select } from 'formik-antd';
import { Formik, Form, Field } from 'formik';
import {
  TenderRatePriority,
  TenderRatePriorityConfiguration,
  TenderRatePriorityConfigurationConditionChoices, TenderRatePriorityConfigurationFieldChoices,
} from '../types';
import { AntInput, AntSelect } from '../../../components/AntField';
import useSelector from '../../../utils/hooks/useSelector';
import AntCustomSelect from '../../../components/AntField/AntCustomSelect';
import { getRateList } from '../../reference/action-creators';
import useDispatch from '../../../utils/hooks/useDispatch';

type TenderRatePriorityModalProps = {
  visible: boolean,
  onSave: (data: anyObject) => Promise<any>,
  initialData?: TenderRatePriority,
  close: () => void,
};

function TenderRatePriorityModal({
  visible, close, initialData, onSave,
}: TenderRatePriorityModalProps) {
  const dispatch = useDispatch();
  const { deliveryServicesList } = useSelector((state) => state.reference);

  const handleFormSubmit = React.useCallback(
    (values, actions) => {
      const {
        weight, weightCondition, rateCode, deliveryService, ...rest
      } = values;
      const configurations: Array<TenderRatePriorityConfiguration> = [];
      Object.keys(rest).forEach((key) => {
        if (rest[key]) {
          configurations.push({
            // @ts-ignore
            field: _snakeCase(key),
            condition: TenderRatePriorityConfigurationConditionChoices.EXACT,
            textValue: rest[key],
          });
        }
      });

      if (weight) {
        configurations.push({
          field: TenderRatePriorityConfigurationFieldChoices.WEIGHT,
          condition: weightCondition,
          value: weight,
        });
      }

      const data = {
        deliveryService,
        rateCode: rateCode?.key,
        configurations,
      };

      onSave(data).then(
        () => {
          close();
          actions.resetForm();
        },
        (errors) => {
          actions.setErrors(errors);
        },
      ).finally(() => actions.setSubmitting(false));
    },
    [],
  );

  const filterDeliveryServices = React.useCallback(
    (input, option) => option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    [],
  );

  const loadRateSearch = React.useCallback(
    (deliveryService: null | string, query: null | string) =>
      // setRateLoading(true);
      // setRateList([]);
      dispatch(getRateList(deliveryService, query)).then(
        ({ rates }: { rates: any }) =>
          // setRateLoading(false);
          // setRateList(items);
          Promise.resolve(rates.map((_: any) => ({
            value: _.code,
            text: _.code,
            component: (<div>
              <div>{_.code}</div>
              <div>{_.title}</div>
            </div>),
          }))),
        () =>
          // setRateLoading(false);
          Promise.reject()
        ,
      ),
    [dispatch],
  );

  const initial = React.useMemo(() => {
    if (initialData) {
      const data: any = {};
      data.deliveryService = initialData.deliveryService;
      if (initialData.rateCode) {
        data.rateCode = {
          key: initialData.rateCode,
          label: (
            <div>
              <div>{initialData.rateCode}</div>
              <div>{initialData.rateDisplay}</div>
            </div>
          ),
        };
      }
      // data.rateDisplay = initialData.rateDisplay;
      initialData.configurations.forEach((cfg) => {
        if (cfg.field !== 'weight') data[_camelCase(cfg.field)] = cfg.textValue;
        else {
          data.weight = cfg.value;
          data.weightCondition = cfg.condition;
        }
      });
      console.debug('data', data);
      return data;
    }
    return {
      weightCondition: 'exact',
    };
  }, [initialData]);

  const getNestedError = React.useCallback(
    (errors: any, fieldName: string) => {
      if (errors?.configurations) {
        const err = errors.configurations.find((_: { [key: string]: any }) => Object.keys(_).includes(fieldName));
        if (err) return err[fieldName];
      }
      return undefined;
    },
    [],
  );

  return (
    <Formik initialValues={initial} onSubmit={handleFormSubmit}>
      {({
        isSubmitting, submitForm, values, setFieldValue,
      }) => (
        <Modal
          okText="Сохранить"
          cancelText="Отменить"
          visible={visible}
          confirmLoading={isSubmitting}
          onOk={submitForm}
          onCancel={close}
          className="ant-modal_full-width"
        >
          <Form>
            <Row>
              <Col xs={12}>
                <Field
                  component={AntSelect}
                  name="deliveryService"
                  label="Служба доставки"
                  selectOptions={deliveryServicesList.map((_) => ({ key: _.key, title: _.title }))}
                  filterOption={filterDeliveryServices}
                  showSearch
                  allowClear
                  placeholder="CSE"
                  onChange={(val: any) => {
                    if (!val || val !== values.deliveryService) {
                      setFieldValue('rateCode', undefined);
                    }
                  }}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={AntCustomSelect}
                  name="rateCode"
                  label="Тариф"
                  disabled={!values.deliveryService && !values.rateCode}
                  onSearch={(query: string) => loadRateSearch(values.deliveryService, query)}
                  value={values.rateCode}
                  placeholder="Введите название или код тарифа"
                  labelInValue
                  // onChange={({ key, label }: any) => {
                  //   // console.debug('change', val);
                  //   setFieldValue('rateCode', key);
                  //   setFieldValue('rateDisplay', label);
                  // }}
                  loadOnFocus
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={AntInput}
                  name="sendIso"
                  type="text"
                  label="ISO страны отправителя"
                  placeholder="RU"
                  getError={getNestedError}
                />

              </Col>
              <Col xs={12}>
                <Field
                  component={AntInput}
                  name="recIso"
                  type="text"
                  label="ISO страны получателя"
                  placeholder="RU"
                  getError={getNestedError}
                />
              </Col>

              <Col xs={12}>
                <Field
                  component={AntInput}
                  name="sendRegion"
                  type="text"
                  label="Регион отправителя"
                  getError={getNestedError}
                  placeholder="Москва"
                />
              </Col>

              <Col xs={12}>

                <Field
                  component={AntInput}
                  name="recRegion"
                  type="text"
                  label="Регион получателя"
                  getError={getNestedError}
                  placeholder="Москва"
                />
              </Col>

              <Col xs={12}>
                <Field
                  component={AntInput}
                  name="sendDistrict"
                  type="text"
                  label="Район отправителя"
                  getError={getNestedError}
                  placeholder="Тверской"
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={AntInput}
                  name="recDistrict"
                  type="text"
                  label="Район получателя"
                  getError={getNestedError}
                  placeholder="Тверской"
                />
              </Col>

              <Col xs={12}>
                <Field
                  component={AntInput}
                  name="sendCity"
                  type="text"
                  label="Город отправителя"
                  placeholder="Москва"
                  getError={getNestedError}
                />
              </Col>
              <Col xs={12}>

                <Field
                  component={AntInput}
                  name="recCity"
                  type="text"
                  label="Город получателя"
                  placeholder="Москва"
                  getError={getNestedError}
                />
              </Col>
              <Col xs={12}>

                <AntForm.Item
                  label="Вес"
                  wrapperCol={{ span: 24 }}
                  labelCol={{ xs: 24 }}
                  labelAlign="left"
                >
                  <OriginalInput.Group compact>
                    <Select defaultValue="exact" style={{ width: '23%' }} name="weightCondition">
                      <Select.Option value="exact">
                        Равно
                      </Select.Option>
                      <Select.Option value="gt">
                        Больше
                      </Select.Option>
                      <Select.Option value="gte">
                        Больше или равно
                      </Select.Option>
                      <Select.Option value="lt">
                        Меньше
                      </Select.Option>
                      <Select.Option value="lte">
                        Меньше или равно
                      </Select.Option>
                    </Select>
                    <Input placeholder="10" style={{ width: '77%' }} name="weight" />
                  </OriginalInput.Group>
                </AntForm.Item>
                {/* <Field */}
                {/*  component={AntInput} */}
                {/*  name="weight" */}
                {/*  type="number" */}
                {/*  label="Вес" */}
                {/*  getError={getNestedError} */}
                {/*  placeholder={0.2} */}
                {/* /> */}
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default TenderRatePriorityModal;
