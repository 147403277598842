import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import NonAuthorizedRoute from './NonAuthorizeRoute';
import NotFound from '../views/NotFound';
import WrappedNormalLoginForm from '../app/users/views/UserLoginView';
import AuthorizedRouter from './AuthorizedRouter';
import PrivateRoute from './PrivateRoute';
import ChatView from '../app/chat/views/ChatView';

function BaseRouter() {
  return (
    <Switch>
      <Redirect exact path="/" to="/waybills" />
      <NonAuthorizedRoute exact path="/login" component={WrappedNormalLoginForm} />
      <Route exact path="/404" component={NotFound} />
      <PrivateRoute exact path="/chat" component={ChatView} />
      <Route path="/" component={AuthorizedRouter} />
      <Redirect to="/404" />
    </Switch>
  );
}

export default BaseRouter;
