import React from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ApishipDeliveryServiceTable = React.lazy(() => import('../components/ApishipDeliveryServiceTable'));

function ApishipDeliveryServiceTableView() {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <ApishipDeliveryServiceTable/>
    </React.Suspense>
  );
}

export default ApishipDeliveryServiceTableView;
