import React, { useState } from 'react';
import { Layout, Icon } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HorizontalNav from './HorizontalNav';
import VerticalNav from './VerticalNav';
import UserProfile from '../Sidebar/UserProfile';
import AppsNavigator from '../Sidebar/AppsNavigation';
import { parseJwt } from '../../utils/jwt';
import useDispatch from "../../utils/hooks/useDispatch";
import {setHeaderHeight} from "../../app/settings/action-creators";

const { Header } = Layout;

function Topbar() {
  const dispatch = useDispatch();
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const accessToken = useSelector((state: any) => state.users.token.accessToken);
  const jwtData = accessToken && accessToken !== 'null' ? parseJwt(accessToken) : null;
  const displayApps = jwtData && (
    jwtData.modules.includes('crm')
    || jwtData.modules.includes('shop')
    || jwtData.modules.includes('tools')
  );

  const headerRef = React.useRef<null | HTMLDivElement>(null);

  const handleResize = () => {
    if (headerRef.current) dispatch(setHeaderHeight(headerRef.current?.clientHeight));
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (headerRef.current) dispatch(setHeaderHeight(headerRef.current?.clientHeight));
  }, [headerRef.current]);

  return (
    <div
      className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal"
      style={{ overflow: 'hidden' }}
      ref={(el) => headerRef.current = el}
    >
      <Header className="gx-header-horizontal-main">
        <div className="gx-container gx-header-horizontal-nav">
          <div style={{ height: '71px' }} className="gx-header-horizontal-main-flex">
            <div className="gx-d-lg-none gx-burder-menu-trigger" onClick={() => setShowBurgerMenu(!showBurgerMenu)}>
              <Icon type={showBurgerMenu ? 'cross' : 'menu'} />
            </div>
            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo gx-m-auto">
              TMS
            </Link>

            <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              TMS
            </Link>

            <div className="ant-menu-dark gx-d-none gx-d-sm-none gx-d-xs-none gx-d-md-none gx-d-lg-block">
              <HorizontalNav />
            </div>

            <ul className="gx-header-notifications gx-ml-lg-auto">
              <li className="gx-user-nav"><UserProfile /></li>
              {displayApps && (
                <li className="gx-user-nav" style={{ marginLeft: '0' }}><AppsNavigator /></li>
              )}
            </ul>
          </div>
          <div className={`ant-menu-dark gx-d-lg-none gx-burder-menu${!showBurgerMenu && ' gx-burder-menu-hide'}`}>
            <VerticalNav onClick={() => setShowBurgerMenu(false)} />
          </div>
        </div>
      </Header>
    </div>
  );
}


export default Topbar;
