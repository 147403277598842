import { UploadFile } from 'antd/es/upload/interface';
import moment from 'moment';
import {
  ADD_DIALOGS,
  ADD_MESSAGE,
  ADD_MESSAGES,
  ADD_UPLOAD_FILE,
  AddDialogs,
  AddMessage,
  AddMessages,
  AddUploadFile,
  ChatTab,
  Dialog,
  Message,
  REMOVE_DIALOG_BY_ID,
  REMOVE_UPLOAD_FILE,
  RemoveDialogById,
  RemoveUploadFile,
  REPLACE_DIALOG,
  REPLACE_MESSAGE,
  ReplaceDialog,
  ReplaceMessage,
  RESET_CURRENT_DIALOG,
  RESET_UPLOAD_FILES, RESET_WAYBILL_FOR_DIALOG,
  ResetCurrentDialog,
  ResetUploadFiles, ResetWaybillForDialog,
  SET_CHAT_TAB,
  SET_CURRENT_DIALOG,
  SET_DIALOG_CHECKER_TIMER,
  SET_DIALOG_COUNT,
  SET_DIALOG_LIST,
  SET_MESSAGES_COUNT,
  SET_MESSAGES_LIST, SET_WAYBILL_FOR_DIALOG,
  SetChatTab,
  SetCurrentDialog,
  SetDialogCheckerTimer,
  SetDialogCount,
  SetDialogList,
  SetMessagesCount,
  SetMessagesList, SetWaybillForDialog,
} from './types';
import { ThunkAction } from '../../utils/types';
import { adaptToApi } from '../../utils/adapter';
import { loadGlobalCounters } from '../counter/action-creators';
import { Waybill } from '../waybills/types';

export const setDialogList = (list: Dialog[]): SetDialogList => ({
  type: SET_DIALOG_LIST,
  list,
});

export const setDialogCount = (count: number): SetDialogCount => ({
  type: SET_DIALOG_COUNT,
  count,
});

export const setMessagesList = (messages: Message[]): SetMessagesList => ({
  type: SET_MESSAGES_LIST,
  messages,
});

export const setMessagesCount = (count: number): SetMessagesCount => ({
  type: SET_MESSAGES_COUNT,
  count,
});

export const addMessage = (message: Message): AddMessage => ({
  type: ADD_MESSAGE,
  message,
});

export const addMessages = (messages: Message[]): AddMessages => ({
  type: ADD_MESSAGES,
  messages,
});

export const addUploadFile = (file: UploadFile): AddUploadFile => ({
  type: ADD_UPLOAD_FILE,
  file,
});

export const resetUploadFiles = (): ResetUploadFiles => ({
  type: RESET_UPLOAD_FILES,
});

export const removeUploadFile = (file: UploadFile): RemoveUploadFile => ({
  type: REMOVE_UPLOAD_FILE,
  file,
});

export const replaceMessage = (message: Message, messageId: string): ReplaceMessage => ({
  type: REPLACE_MESSAGE,
  messageId,
  message,
});

export const addDialogs = (list: Dialog[]): AddDialogs => ({
  type: ADD_DIALOGS,
  list,
});

export const setCurrentDialog = (dialog: Dialog): SetCurrentDialog => ({
  type: SET_CURRENT_DIALOG,
  dialog,
});

export const replaceDialog = (dialog: Dialog): ReplaceDialog => ({
  type: REPLACE_DIALOG,
  dialog,
});

export const resetCurrentDialog = (): ResetCurrentDialog => ({
  type: RESET_CURRENT_DIALOG,
});

export const setChatTab = (tab: ChatTab): SetChatTab => ({
  type: SET_CHAT_TAB,
  tab,
});

export const removeDialogById = (dialogId: string): RemoveDialogById => ({
  type: REMOVE_DIALOG_BY_ID,
  dialogId,
});

export const setDialogCheckerTimer = (timer: any): SetDialogCheckerTimer => ({
  type: SET_DIALOG_CHECKER_TIMER,
  timer,
});

export const setWaybillForDialog = (waybill: Waybill): SetWaybillForDialog => ({
  type: SET_WAYBILL_FOR_DIALOG,
  waybill,
});

export const resetWaybillForDialog = (): ResetWaybillForDialog => ({
  type: RESET_WAYBILL_FOR_DIALOG,
});

export const loadDialogList = (page = 1, postponedOnly = false): ThunkAction => (dispatch, getState, http) => {
  const limit = 20;
  const params = {
    skip: (page * limit) - limit,
    limit,
    postponed_only: postponedOnly,
  };
  return http.get(
    '/api/v1/chat/dialog/list/',
    params,
  ).then(
    (response: { dialogs: Dialog[], count: number }) => {
      dispatch(setDialogList(response.dialogs));
      dispatch(setDialogCount(response.count));
      return Promise.resolve(response);
    },
  );
};

export const loadMoreDialogs = (page = 1, postponedOnly = false): ThunkAction => (dispatch, getState, http) => {
  const limit = 20;
  const params = {
    skip: (page * limit) - limit,
    limit,
    postponed_only: postponedOnly,
  };
  return http.get(
    '/api/v1/chat/dialog/list/',
    params,
  ).then(
    (response: { dialogs: Dialog[], count: number }) => {
      dispatch(addDialogs(response.dialogs));
      dispatch(setDialogCount(response.count));
      return Promise.resolve(response);
    },
  );
};

export const loadMessages = (page = 1, dialogId: string): ThunkAction => (dispatch, getState, http) => {
  const limit = 14;
  const params = {
    skip: (page * limit) - limit,
    limit,
    dialog_id: dialogId,
  };
  return http.get(
    '/api/v1/chat/messages/list/',
    params,
  ).then(
    (response: { count: number, messages: Message[] }) => {
      dispatch(addMessages(response.messages));
      dispatch(setMessagesCount(response.count));
      return Promise.resolve(response);
    },
  );
};

export const createMessage = (message: string): ThunkAction<Promise<any>> => (dispatch, getState, http) => {
  const { chat: { currentDialog, dialogMessagesCount }, users: { user } } = getState();
  const dialogId = currentDialog?.dialogId;
  if (!dialogId) return Promise.reject();
  return http.post('/api/v1/chat/message/create/', {
    message,
    dialog_id: dialogId,
  }).then(
    (data: Message) => {
      dispatch(addMessage({ ...data, user: { firstName: user?.firstName, lastName: user?.lastName, avatar: '' } }));
      dispatch(setMessagesCount(dialogMessagesCount + 1));
      return Promise.resolve(data);
    },
  );
};

export const uploadFiles = (): ThunkAction => (dispatch, getState, http) => {
  const { chat: { currentDialog, uploadFiles }, users: { user } } = getState();
  const dialogId = currentDialog?.dialogId;
  if (!dialogId) return Promise.reject();
  const promises: Array<Promise<any>> = [];
  uploadFiles.filter((_) => _.status !== 'error').forEach((file) => {
    const message: Message = {
      messageId: file.uid,
      createdAt: new Date().toISOString(),
      userId: 0,
      accountId: currentDialog?.accountId,
      dialogId,
      type: 'attachment',
      sender: 'logist',
      user: {
        firstName: user?.firstName,
        lastName: user?.lastName,
        avatar: '',
      },
      file: {
        name: file.name,
        fileName: file.fileName,
        uploadPercent: 0,
        status: 'uploading',
      },
    };
    dispatch(addMessage(message));
    const req = new FormData();
    req.append('dialog_id', dialogId);
    // @ts-ignore
    req.append('file', file);
    // @ts-ignore
    req.append('account_id', currentDialog?.accountId);
    const call = http.post('/api/v1/chat/message/upload/', req, { json: false }, (progress: any) => {
      if (message.file) {
        const cur = Math.floor((progress.loaded * 100) / progress.total);
        message.file.uploadPercent = cur;
        // if (cur === 100) message.file.status = 'done';
      }
      dispatch(replaceMessage(message, message.messageId));
    }).then(
      (response: Message) => dispatch(replaceMessage({ ...response, user: message.user }, message.messageId)),
    );
    dispatch(resetUploadFiles());
    promises.push(call);
  });
  return Promise.all(promises);
};

export const WSDialogEvent = (data: anyObject, event: 'new_message' | 'new_dialog' | 'close_dialog' | 'return_to_work' | 'postpone_dialog'): ThunkAction<void> => (dispatch, getState, http) => {
  const { chat: { currentDialog, dialogList, currentTab } } = getState();
  const handleNewMessage = () => {
    const { dialogId } = data;
    if (currentDialog && currentDialog.dialogId === dialogId) {
      http.get('/api/v1/chat/messages/list/', { dialog_id: dialogId, limit: 10 }).then(
        (response: { messages: Array<Message>, count: number }) => {
          dispatch(setMessagesCount(response.count));
          dispatch(addMessages(response.messages));
        },
      );
      dispatch(setCurrentDialog({ ...currentDialog, closed: false }));
    }
    http.get(`/api/v1/chat/dialog/${dialogId}/detail/`).then(
      (response: Dialog) => {
        if (response.postponedUntil) {
          const tmp = moment(response.postponedUntil);
          if (tmp.isAfter(moment())) {
            if (currentTab === ChatTab.POSTPONED) {
              const exist = dialogList.find((_) => _.dialogId === dialogId);
              if (exist) dispatch(replaceDialog(response));
              else dispatch(addDialogs([response]));
            } else if (response.postponedAt) {
              const postponedAt = moment(response.postponedAt);
              if (postponedAt.isBefore(response.lastMessageDate)) {
                const exist = dialogList.find((_) => _.dialogId === dialogId);
                if (exist) dispatch(replaceDialog(response));
                else dispatch(addDialogs([response]));
              }
            }
          } else if (tmp.isBefore(moment()) && currentTab === ChatTab.ACTIVE) {
            const exist = dialogList.find((_) => _.dialogId === dialogId);
            if (exist) dispatch(replaceDialog(response));
            else dispatch(addDialogs([response]));
          }
        } else if (currentTab === ChatTab.ACTIVE) {
          const exist = dialogList.find((_) => _.dialogId === dialogId);
          if (exist) dispatch(replaceDialog(response));
          else dispatch(addDialogs([response]));
        }
      },
    );
    const dialog = dialogList.find((_) => _.dialogId === dialogId);
    if (dialog && dialog.closed) dispatch(replaceDialog({ ...dialog, closed: false }));
  };

  const handleDialogClosed = () => {
    const { dialogId } = data;
    if (currentDialog && currentDialog.dialogId === dialogId) {
      http.get('/api/v1/chat/messages/list/', { dialog_id: dialogId, limit: 10 }).then(
        (response: { messages: Array<Message>, count: number }) => {
          dispatch(setMessagesCount(response.count));
          dispatch(addMessages(response.messages));
        },
      );
      dispatch(setCurrentDialog({ ...currentDialog, closed: true }));
    }
    const dialog = dialogList.find((_) => _.dialogId === dialogId);
    if (dialog && !dialog.closed) dispatch(replaceDialog({ ...dialog, closed: true }));
  };

  const handleReturnToWork = (dialogs: string[]) => {
    const missing = dialogs.filter((dialogId: string) => !dialogList.find((_) => _.dialogId === dialogId));
    if (missing.length) {
      http.get('/api/v1/chat/dialog/list/', { limit: 10, postponed_only: false }).then(
        (response: { dialogs: Dialog[], count: number }) => {
          dispatch(setDialogCount(response.count));
          dispatch(addDialogs(response.dialogs));
        },
      );
    }
  };

  const refreshList = () => {
    http.get('/api/v1/chat/dialog/list/', { limit: 10, postponed_only: currentTab === ChatTab.POSTPONED }).then(
      (response: { dialogs: Dialog[], count: number }) => {
        dispatch(setDialogCount(response.count));
        dispatch(addDialogs(response.dialogs));
      },
    );
  };

  switch (event) {
    case 'new_message':
      handleNewMessage();
      break;
    case 'new_dialog':
      refreshList();
      break;
    case 'close_dialog':
      handleDialogClosed();
      break;
    case 'return_to_work':
      if (currentTab === ChatTab.ACTIVE) {
        handleReturnToWork(data.dialogs);
      } else {
        data.dialogs.forEach((dialogId: string) => {
          dispatch(removeDialogById(dialogId));
        });
      }
      break;
    case 'postpone_dialog':
      if (currentTab === ChatTab.ACTIVE) {
        dispatch(removeDialogById(data.dialogId));
      } else {
        refreshList();
      }
      break;
    default:
      break;
  }
};

export const closeDialog = (dialogId: string): ThunkAction => (dispatch, getState, http) => http.put(`/api/v1/chat/dialog/${dialogId}/close/`);

export const loadDialogByWaybillNumber = (plsWaybillNumber: string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/chat/dialog/${plsWaybillNumber}/by_waybill/`,
).then(
  (response: Dialog) => {
    dispatch(setCurrentDialog(response));
    return Promise.resolve(response);
  },
);

export const loadDialogByClaim = (claimNumber: string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/chat/dialog/${claimNumber}/by_claim/`,
).then(
  (response: Dialog) => {
    dispatch(setCurrentDialog(response));
    return Promise.resolve(response);
  },
);

export const createDialog = (data: anyObject): ThunkAction<Promise<Dialog>> => (dispatch, getState, http) => http.post(
  '/api/v1/chat/dialog/create/',
  adaptToApi(data),
).then(
  (response: Dialog) => {
    dispatch(setCurrentDialog(response));
    return Promise.resolve();
  },
);

export const loadCurrentDialog = (dialogId: string): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/chat/dialog/${dialogId}/detail/`,
).then(
  (response: Dialog) => {
    dispatch(setCurrentDialog(response));
    dispatch(replaceDialog(response));
    return Promise.resolve(response);
  },
);

export const postponeDialog = (dialogId: string, period: number): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/chat/dialog/${dialogId}/postpone/`,
  { period },
).then(
  () => {
    const { currentTab } = getState().chat;
    dispatch(loadCurrentDialog(dialogId));
    if (currentTab === ChatTab.ACTIVE) {
      dispatch(removeDialogById(dialogId));
    }
    return Promise.resolve();
  },
);

export const returnDialogToWork = (dialogId: string): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/chat/dialog/${dialogId}/return_to_work/`,
).then(
  () => {
    const { currentTab } = getState().chat;
    dispatch(loadCurrentDialog(dialogId));
    if (currentTab === ChatTab.POSTPONED) {
      dispatch(removeDialogById(dialogId));
    }
    return Promise.resolve();
  },
);

export const checkDialogList = (): ThunkAction<void> => (dispatch, getState, http) => {
  const { currentTab, dialogList } = getState().chat;
  let limit = dialogList.length;
  if (limit < 10) limit = 10;
  http.get('/api/v1/chat/dialog/list/', { limit, postponed_only: currentTab === ChatTab.POSTPONED }).then(
    (response: { dialogs: Dialog[], count: number }) => {
      const { currentDialog } = getState().chat;
      if (currentDialog) {
        const update = response.dialogs.find((_) => _.dialogId === currentDialog.dialogId);
        if (update) dispatch(setCurrentDialog(update));
      }
      dispatch(setDialogCount(response.count));
      dispatch(addDialogs(response.dialogs));
    },
  );
  dispatch(loadGlobalCounters());
};

export const startDialogChecker = (): ThunkAction<void> => (dispatch, getState) => {
  const { dialogCheckerTimer } = getState().chat;
  if (dialogCheckerTimer) clearInterval(dialogCheckerTimer);
  const interval = setInterval(() => dispatch(checkDialogList()), 60000);
  dispatch(setDialogCheckerTimer(interval));
};

export const stopDialogChecker = (): ThunkAction<void> => (dispatch, getState) => {
  const { dialogCheckerTimer } = getState().chat;
  if (dialogCheckerTimer) clearInterval(dialogCheckerTimer);
};
