import {
  CargoCast, CargoCastAdditionalServices, ContactCast, RateCast} from '../generic_types';
import { Waybill } from '../waybills/types';

export const SET_FOREIGN_INVOICE_LIST = 'SET_FOREIGN_INVOICE_LIST';
export const SET_FOREIGN_INVOICE_COUNT = 'SET_FOREIGN_INVOICE_COUNT';
export const SET_CURRENT_FOREIGN_INVOICE = 'SET_CURRENT_FOREIGN_INVOICE';
export const RESET_CURRENT_FOREIGN_INVOICE = 'RESET_CURRENT_FOREIGN_INVOICE';

export const ForeignInvoiceState = ['new', 'accept', 'complete', 'wait', 'dismiss'];

export type ForeignInvoiceAccountDetail = {
  accountId: number,
  accountTitle: string,
};

export type ForeignInvoice = {
  foreignInvoiceId: number,
  state: typeof ForeignInvoiceState[number],
  number: string,
  accountId: number,
  createdAt: string,

  senderContactId: string,
  receiverContactId: string,
  cargoId: string,
  rateResultId: string,
  rateCastId: string,
  
  pickupAt: string,
  account: null | ForeignInvoiceAccountDetail,
  waybill: null | Waybill,
  deliveryService?: string,
  senderIso: string,
  receiverIso: string,
  cargoType: string
  estimatedProcessingDate?: string
};

export type ForeignInvoiceDetail = ForeignInvoice & {
  cargo: CargoCast,
  senderContact: null | ContactCast,
  receiverContact: null | ContactCast,
  rateResult: null | RateCast,
  pickupPeriod: string,
  additionalServices?: CargoCastAdditionalServices[],
};

export interface SetForeignInvoiceList {
  type: typeof SET_FOREIGN_INVOICE_LIST,
  payload: ForeignInvoice[]
}

export interface SetForeignInvoiceCount {
  type: typeof SET_FOREIGN_INVOICE_COUNT,
  payload: number,
}

export interface SetCurrentForeignInvoice {
  type: typeof SET_CURRENT_FOREIGN_INVOICE,
  payload: ForeignInvoiceDetail,
}

export interface ResetCurrentForeignInvoice {
  type: typeof RESET_CURRENT_FOREIGN_INVOICE,
}

export type ForeignInvoiceActions = SetForeignInvoiceList | SetForeignInvoiceCount | SetCurrentForeignInvoice | ResetCurrentForeignInvoice;
