import {Waybill, WaybillHistory, WaybillPricing, WaybillTracking, Rate, InsuranceListItem, WaybillControlFilter} from "./objects";

export const SET_WAYBILLS_LIST = 'SET_WAYBILLS_LIST';
export const SET_CURRENT_WAYBILL = 'SET_CURRENT_WAYBILL';
export const SET_CURRENT_WAYBILL_TRACKING = 'SET_CURRENT_WAYBILL_TRACKING';
export const SET_CURRENT_WAYBILL_PRICING = 'SET_CURRENT_WAYBILL_PRICING';
export const SET_CURRENT_WAYBILL_HISTORY = 'SET_CURRENT_WAYBILL_HISTORY';
export const SET_WAYBILLS_COUNT = 'SET_WAYBILLS_COUNT';
export const SET_WAYBILLS_PAGE = 'SET_WAYBILLS_PAGE';
export const SET_WAYBILLS_SEARCH = 'SET_WAYBILLS_SEARCH';
export const SET_RATE_TASK = 'SET_RATE_TASK';
export const SET_RATE_LIST = 'SET_RATE_LIST';
export const SET_SURCHARGE_TYPES = 'SET_SURCHARGE_TYPES';
export const RESET_CURRENT_WAYBILL = 'RESET_CURRENT_WAYBILL';
export const SET_INSURANCE_ITEMS = 'SET_INSURANCE_ITEMS';
export const SET_INSURANCE_ITEM_COUNT = 'SET_INSURANCE_ITEM_COUNT';
export const SET_WAYBILL_FILTERS_LIST = 'SET_WAYBILL_FILTERS_LIST';
export const ADD_WAYBILL_FILTER = 'ADD_WAYBILL_FILTER';
export const SET_WAYBILL_FILTERS_COUNT = 'SET_WAYBILL_FILTERS_COUNT';
export const REMOVE_WAYBILL_FILTER = 'REMOVE_WAYBILL_FILTER';
export const UPDATE_WAYBILL_FILTER = 'UPDATE_WAYBILL_FILTER';


export interface SetWaybillsList {
  type: typeof SET_WAYBILLS_LIST,
  waybills: Waybill[]
}

export interface SetCurrentWaybill {
  type: typeof SET_CURRENT_WAYBILL,
  waybill: Waybill,
}

export interface SetCurrentWaybillTracking {
  type: typeof SET_CURRENT_WAYBILL_TRACKING,
  tracking: WaybillTracking[],
}

export interface SetCurrentWaybillPricing {
  type: typeof SET_CURRENT_WAYBILL_PRICING,
  pricing: WaybillPricing[],
}

export interface SetCurrentWaybillHistory {
  type: typeof SET_CURRENT_WAYBILL_HISTORY,
  history: WaybillHistory[],
}

export interface SetWaybillsCount {
  type: typeof SET_WAYBILLS_COUNT,
  count: number,
}

export interface SetWaybillsPage {
  type: typeof SET_WAYBILLS_PAGE,
  page: number,
}

export interface SetWaybillsSearch {
  type: typeof SET_WAYBILLS_SEARCH,
  search: string,
}

export interface SetRateTask {
  type: typeof SET_RATE_TASK,
  taskId: string,
}

export interface SetRateList {
  type: typeof SET_RATE_LIST,
  rates: Rate[],
}

export interface SetSurchargeTypes {
  type: typeof SET_SURCHARGE_TYPES,
  surchargeTypes: any[]
}

export interface ResetCurrentWaybill {
  type: typeof RESET_CURRENT_WAYBILL,
}

export interface SetInsuranceItems {
  type: typeof SET_INSURANCE_ITEMS,
  items: InsuranceListItem[]
}

export interface SetInsuranceItemsCount {
  type: typeof SET_INSURANCE_ITEM_COUNT,
  count: number,
}

export interface SetWaybillFiltersList {
  type: typeof SET_WAYBILL_FILTERS_LIST,
  payload: WaybillControlFilter[],
}

export interface AddWaybillFilter {
  type: typeof ADD_WAYBILL_FILTER,
  payload: WaybillControlFilter,
}

export interface SetWaybillFiltersCount {
  type: typeof SET_WAYBILL_FILTERS_COUNT,
  payload: {filterId: string, count: number}[],
}

export interface RemoveWaybillFilter {
  type: typeof REMOVE_WAYBILL_FILTER,
  payload: string
}

export interface UpdateWaybillFilter {
  type: typeof UPDATE_WAYBILL_FILTER,
  payload: WaybillControlFilter
}

export type WaybillActions =
  SetWaybillsList |
  SetCurrentWaybill |
  SetCurrentWaybillTracking |
  SetCurrentWaybillPricing |
  SetCurrentWaybillHistory |
  SetWaybillsCount |
  SetWaybillsPage |
  SetWaybillsSearch |
  SetRateTask |
  SetRateList |
  SetSurchargeTypes |
  ResetCurrentWaybill |
  SetInsuranceItems |
  SetInsuranceItemsCount |
  SetWaybillFiltersList |
  AddWaybillFilter |
  SetWaybillFiltersCount |
  RemoveWaybillFilter |
  UpdateWaybillFilter;
