import React from 'react';
import {
  Row, Col, Button, Dropdown, Menu, Modal, Divider, Table, Empty, Card,
} from 'antd';
import useDispatch from '../../../utils/hooks/useDispatch';
import useSelector from '../../../utils/hooks/useSelector';
import {
  TenderRatePriority,
  TenderRatePriorityConfiguration, TenderRatePriorityConfigurationConditionChoices,
  TenderRatePriorityConfigurationFieldChoices,
} from '../types';
import {
  createTenderRatePriority,
  deleteTenderRatePriority,
  loadTenderRatePriorityList, resetTenderRatePriorityList,
  switchTenderRatePriority, updateTenderRatePriority,
} from '../action-creators';
import TenderRatePriorityModal from './TenderRatePriorityModal';
import AsyncSwitch from '../../../components/AsyncSwitch';
import List from '../../../components/List';
import RateCreateModal from '../../rate/components/RateCreateModal';

const { confirm } = Modal;

const getFieldData = (configurations: Array<TenderRatePriorityConfiguration>, field: TenderRatePriorityConfigurationFieldChoices):
null | TenderRatePriorityConfiguration => configurations.find((_) => _.field === field) || null;

const getConditionRender = (condition: TenderRatePriorityConfigurationConditionChoices) => {
  switch (condition) {
    case TenderRatePriorityConfigurationConditionChoices.EXACT:
      return 'Равно';
    case TenderRatePriorityConfigurationConditionChoices.GT:
      return 'Больше';
    case TenderRatePriorityConfigurationConditionChoices.LT:
      return 'Меньше';
    case TenderRatePriorityConfigurationConditionChoices.GTE:
      return 'Больше или равно';
    case TenderRatePriorityConfigurationConditionChoices.LTE:
      return 'Меньше или равно';
    default:
      return '';
  }
};

function TenderRatePriorityList() {
  const dispatch = useDispatch();
  const { tenderRatePriorityList } = useSelector((state) => state.tender);
  const { deliveryServices } = useSelector((state) => state.reference);

  const [loading, setLoading] = React.useState(false);
  const [addModalActive, setAddModalActive] = React.useState(false);
  const [editRate, setEditRate] = React.useState<null | TenderRatePriority>(null);

  const handleLoad = React.useCallback(
    () => {
      setLoading(true);
      dispatch(loadTenderRatePriorityList()).finally(() => setLoading(false));
    },
    [],
  );

  const handleSwitch = React.useCallback(
    (tenderRateId, checked) => dispatch(switchTenderRatePriority(tenderRateId, checked)),
    [],
  );

  const handleDelete = React.useCallback(
    (tenderRateId) => {
      dispatch(deleteTenderRatePriority(tenderRateId));
    },
    [],
  );

  const handleAdd = React.useCallback(
    (data: anyObject) => dispatch(createTenderRatePriority(data)),
    [],
  );

  const handleUpdate = React.useCallback(
    (data) => {
      if (editRate) {
        return dispatch(updateTenderRatePriority(editRate.tenderRatePriorityId, data));
      }
      return Promise.reject();
    },
    [editRate],
  );

  React.useEffect(() => {
    handleLoad();
    return () => {
      dispatch(resetTenderRatePriorityList());
    };
  }, []);

  return (
    <List
      title={<div className="title h1"><p>Приоритет тарифов тендера</p></div>}
      addon={(
        <div className="gx-text-right">
          <Button onClick={() => setAddModalActive(true)} type="primary" ghost>Добавить</Button>
        </div>
)}
      // totalCount={ratesCount}
      // currentPage={page}
      // pageSize={10}
      // handlePageChange={this.handlePageChange}
      // searchValue={query}
      // onSearchChange={this.handleSearchChange}
    >
      <Card>
        <Row>
          <TenderRatePriorityModal
            visible={addModalActive}
            onSave={handleAdd}
            close={() => setAddModalActive(false)}
          />
          {editRate
          && (
            <TenderRatePriorityModal
              visible
              onSave={handleUpdate}
              close={() => setEditRate(null)}
              initialData={editRate}
            />
          )}
          <Col xs={24}>
            <Table
              loading={loading}
              locale={{ emptyText: <Empty description="Ничего не найдено" /> }}
              pagination={false}
              dataSource={tenderRatePriorityList}
              className="gx-table-responsive gx-table-cancel-padding"
              columns={[
                {
                  render: (text, record) => (
                    <AsyncSwitch
                      checked={record.enabled}
                      size="small"
                      onChange={(checked) => handleSwitch(record.tenderRatePriorityId, checked)}
                    />
                  ),
                },
                {
                  title: 'Служба и тариф',
                  render: (text, { deliveryService, rateDisplay }) => (
                    <td>
                      <img src={deliveryServices[deliveryService]?.icon} style={{ maxHeight: 20, maxWidth: 80 }} alt={deliveryService} />
                      {' / '}
                      {rateDisplay || 'Все тарифы'}
                    </td>
                  ),
                },
                {
                  title: 'Откуда',
                  render: (text, { configurations }) => {
                    const iso = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.SEND_ISO);
                    const city = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.SEND_CITY);
                    const district = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.SEND_DISTRICT);
                    const region = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.SEND_REGION);
                    if (!iso && !city && !district && !region) return <td>Нет ограничений</td>;
                    const limit = [
                      iso?.textValue, city?.textValue, district?.textValue, region?.textValue,
                    ].filter((_) => !!_).join('/');
                    return <td>{limit}</td>;
                  },
                },
                {
                  title: 'Куда',
                  render: (text, { configurations }) => {
                    const iso = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.REC_ISO);
                    const city = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.REC_CITY);
                    const district = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.REC_DISTRICT);
                    const region = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.REC_REGION);
                    if (!iso && !city && !district && !region) return <td>Нет ограничений</td>;
                    const limit = [
                      iso?.textValue, city?.textValue, district?.textValue, region?.textValue,
                    ].filter((_) => !!_).join('/');
                    return <td>{limit}</td>;
                  },
                },
                {
                  title: 'Вес',
                  render: (text, { configurations }) => {
                    const weight = getFieldData(configurations, TenderRatePriorityConfigurationFieldChoices.WEIGHT);
                    if (weight) return <td>{getConditionRender(weight.condition)} {weight.value}</td>;
                    return <td>Нет ограничений</td>;
                  },
                },
                {
                  render: (text, record) => (
                    <Dropdown overlay={(
                      <Menu>
                        <Menu.Item onClick={() => {
                          setEditRate(record);
                        }}
                        >
                          Редактировать
                        </Menu.Item>
                        {/* {record.tenderRateId && ( */}
                        {/*  <Menu.Item onClick={() => (record.tenderRateId ? setCurrentLog(record.marketingId) : () => { */}
                        {/*  })}> */}
                        {/*    История изменения */}
                        {/*  </Menu.Item> */}
                        {/* )} */}
                        <Menu.Item onClick={() => {
                          confirm({
                            title: 'Вы уверены?',
                            content: 'Вы точно хотите удалить этот тариф?',
                            onOk: () => handleDelete(record.tenderRatePriorityId),
                            okText: 'Подтвердить',
                            cancelText: 'Отменить',
                            okType: 'danger',
                          });
                        }}
                        >
                          Удалить
                        </Menu.Item>
                      </Menu>
                    )}
                    ><Button>...</Button>
                    </Dropdown>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </Card>
    </List>
  );
}

export default TenderRatePriorityList;
