import { 
  RESET_RATES_CASHBACK, 
  RateCashback, 
  RatesCashbackAction, 
  SET_RATES_CASHBACK_COUNT, 
  SET_RATES_CASHBACK_ITEM, 
  SET_RATES_CASHBACK_LIST 
} from './types';

export interface RatesCashbackState {
  ratesCashbackList: Array<RateCashback>,
  ratesCashbackCount: number,
  currentRatesCashback: RateCashback | null,
}

const initialState: RatesCashbackState = {
  ratesCashbackList: [],
  ratesCashbackCount: 0,
  currentRatesCashback: null,
};

export default function (state = initialState, action: RatesCashbackAction): RatesCashbackState {
  switch (action.type) {
    case SET_RATES_CASHBACK_LIST:
      return { ...state, ratesCashbackList: action.payload }
    case SET_RATES_CASHBACK_COUNT:
      return { ...state, ratesCashbackCount: action.payload }
    case SET_RATES_CASHBACK_ITEM:
      return { ...state, currentRatesCashback: action.payload };

    case RESET_RATES_CASHBACK:
      return { ...initialState };

    default: return state;
  }
}
