export const SET_COUNTRIES_LIST = 'SET_COUNTRIES_LIST';
export const SET_COUNTRIES_COUNT = 'SET_COUNTRIES_COUNT';

export type CountryItem = {
  iso: string,
  title: string,
  icon: string,
  capital: string,
  capitalDeliveryService: string,
};

export type CapitalItem = {
  original?: string,
  cdek?: string,
  dpd?: string,
  cse?: string,
  pony?: string,
  pochta?: string
}

export interface SetCountriesList {
  type: typeof SET_COUNTRIES_LIST,
  payload: CountryItem[]
}

export interface SetCountriesCount {
  type: typeof SET_COUNTRIES_COUNT,
  payload: number,
}

export type CountryActions =
  SetCountriesList |
  SetCountriesCount;
