import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const CollisionReportSummary = React.lazy(() => import('../components/CollisionReportSummary'));

function CollisionReportSummaryView(props: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CollisionReportSummary {...props} />
    </Suspense>
  );
}

export default CollisionReportSummaryView;
