export const SET_WAYBILL_DASHBOARD_LIST = 'SET_WAYBILL_DASHBOARD_LIST';
export const SET_WAYBILL_DASHBOARD_COUNT = 'SET_WAYBILL_DASHBOARD_COUNT';
export const SET_COLLISION_REPORT_SUMMARY = 'SET_COLLISION_REPORT_SUMMARY';

export type DashboardItem = {
  waybillId: number;
  plsWaybillNumber: string;
  serviceWaybillNumber: string;
  serviceOrderNumber: string;
  deliveryService: string;
  pickupDate: string;
  deliveryDate: string;
  senderCity: string;
  receiverCity: string;
  currentCity: string;
  lastStatus: string;
  lastStatusDate: string;
  collisions: number[];
};

export type CollisionReportGroup = {
  waybillCount: number;
  firstIndex: number;
  secondIndex: number;
};

export type CollisionReportSummary = {
  cargoSafeGroup: CollisionReportGroup;
  shelfLifeGroup: CollisionReportGroup;
  cargoTransitGroup: CollisionReportGroup;
  deliveryService: string,
};

export interface SetWaybillDashboardList {
  type: typeof SET_WAYBILL_DASHBOARD_LIST,
  list: DashboardItem[],
}

export interface SetWaybillDashboardCount {
  type: typeof SET_WAYBILL_DASHBOARD_COUNT,
  count: number,
}

export interface SetCollisionReportSummary {
  type: typeof SET_COLLISION_REPORT_SUMMARY,
  summary: CollisionReportSummary[]
}

export type ReportAction = SetWaybillDashboardList | SetWaybillDashboardCount | SetCollisionReportSummary;
