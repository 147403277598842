import { pluralize } from '../../helpers';
import { WaybillProcessing } from './types';

export const REPORT_TYPES = {
  days_in_status_gte: 'По текущему статусу',
  days_duration_in_status_gte: 'По истории',
};

export const getWaybillProcessingRateName = (waybillProcessing: WaybillProcessing) => {
  let daysString = null;
  if (waybillProcessing) {
    if (waybillProcessing.minDays === 0 && waybillProcessing.maxDays === 0) {
      daysString = 'В течение дня';
    } else if (waybillProcessing.minDays === waybillProcessing.maxDays) {
      daysString = `${waybillProcessing.minDays} ${pluralize(waybillProcessing.minDays, 'день,дня,дней')}`;
    } else {
      daysString = `${waybillProcessing.minDays}-${waybillProcessing.maxDays} ${pluralize(waybillProcessing.maxDays, 'день,дня,дней')}`;
    }
  }
  return `${waybillProcessing.rate} ${daysString ? ` (${daysString})` : ''}`;
};
