import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const CartMarketingTable = React.lazy(() => import('../components/CartMarketingTable'));

function CartMarketingTableView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CartMarketingTable />
    </Suspense>
  );
}

export default CartMarketingTableView;
