import { Moment } from 'moment';

export const SET_CHAT_ANSWER_STAT = 'SET_CHAT_ANSWER_STAT';

export type ChatAnswerSeries = {
  date: string;
  latency: number;
};

export interface SetChatAnswerStat {
  type: typeof SET_CHAT_ANSWER_STAT,
  stat: ChatAnswerSeries[],
}

export type StatisticActions = SetChatAnswerStat;
