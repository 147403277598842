import { 
  FakeConfiguration, 
  FakeServiceAction, 
  FakeSetting, 
  SET_CURRENT_FAKE_CONFIGURATION, 
  SET_FAKE_CONFIGURATIONS_COUNT, 
  SET_FAKE_CONFIGURATIONS_LIST,
  SET_FAKE_CONFIGURATIONS_GROUPS_LIST,
  SET_FAKE_CONFIGURATIONS_GROUPS_COUNT,
  FakeConfigurationGroup,
  SET_CURRENT_FAKE_CONFIGURATION_GROUP,
  RESET_CURRENT_GROUP,
} from './types';

export interface FakeServiceState {
  fakeGroupsList: Array<FakeConfigurationGroup>,
  fakeGroupsCount: number,
  currentFakeGroup: FakeConfigurationGroup | null,
  fakeConfigurationsList: Array<FakeConfiguration>,
  fakeConfigurationsCount: number,
  currentFakeConfiguration: null | FakeConfiguration
}

const initialState: FakeServiceState = {
  fakeGroupsList: [],
  fakeGroupsCount: 0,
  currentFakeGroup: null,
  fakeConfigurationsList: [],
  fakeConfigurationsCount: 0,
  currentFakeConfiguration: null
};

export default function (state = initialState, action: FakeServiceAction): FakeServiceState {
  switch (action.type) {
    case SET_FAKE_CONFIGURATIONS_GROUPS_LIST:
      return { ...state, fakeGroupsList: action.payload }
    case SET_FAKE_CONFIGURATIONS_GROUPS_COUNT:
      return { ...state, fakeGroupsCount: action.payload }
    case SET_CURRENT_FAKE_CONFIGURATION_GROUP:
      return { ...state, currentFakeGroup: action.payload };

    case SET_FAKE_CONFIGURATIONS_LIST:
      return { ...state, fakeConfigurationsList: action.payload };
    case SET_FAKE_CONFIGURATIONS_COUNT:
      return { ...state, fakeConfigurationsCount: action.payload };
    case SET_CURRENT_FAKE_CONFIGURATION:
      return { ...state, fakeConfigurationsList: [...state.fakeConfigurationsList.map(i => i.configurationId === action.payload.configurationId ? action.payload : i)]};

    case RESET_CURRENT_GROUP:
      return { ...state, fakeConfigurationsList: initialState.fakeConfigurationsList, fakeConfigurationsCount: initialState.fakeConfigurationsCount, currentFakeGroup: initialState.currentFakeGroup };

    default: return state;
  }
}
