import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const CountryList = React.lazy(() => import('../components/CountryList'));

function CountryListView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <CountryList {...props} />
    </Suspense>
  );
}

export default CountryListView;
