export const addPackage = () => ({
  type: 'ADD_PACKAGE',
});

export const deletePackage = (packageId) => ({
  type: 'DELETE_PACKAGE',
  packageId,
});

export const replacePackage = (packageId, _package) => ({
  type: 'REPLACE_PACKAGE',
  packageId,
  package: _package,
});

export const resetCargo = () => ({
  type: 'RESET_CARGO',
});

export const setPackages = (packages) => ({
  type: 'SET_PACKAGE',
  packages,
});
