import {
  CollisionReportItem,
  RESET_TRACKING_REPORT_ITEM_LIST,
  ResetTrackingReportItemList,
  SET_COLLISION_REPORT_ITEM_LIST,
  SET_TRACKING_REPORT_ITEM_COUNT,
  SET_TRACKING_REPORT_ITEM_LIST,
  SET_WAYBILL_PROCESSING_COUNT,
  SET_WAYBILL_PROCESSING_LIST,
  SetCollisionReportItemList,
  SetTrackingReportItemCount,
  SetTrackingReportItemList,
  SetWaybillProcessingCount,
  SetWaybillProcessingList,
  TrackingReport,
  TrackingReportItem,
  TrackingStatus,
  WaybillProcessing,
} from './types';
import { ThunkAction } from '../../utils/types';
import {Moment} from "moment";
import {SERVER_DATE_FORMAT} from "../../utils/constants";

export const setTrackingStatusesList = (statuses: Array<TrackingStatus>) => ({
  type: 'SET_TRACKING_STATUSES_LIST',
  statuses,
});

export const setTrackingReportList = (reportList: Array<TrackingReport>) => ({
  type: 'SET_TRACKING_REPORT_LIST',
  reportList,
});

export const setTrackingReportCount = (count: number) => ({
  type: 'SET_TRACKING_REPORT_COUNT',
  count,
});

export const setTrackingReportItemList = (list: TrackingReportItem[]): SetTrackingReportItemList => ({
  type: SET_TRACKING_REPORT_ITEM_LIST,
  list,
});

export const resetTrackingReportItemList = (): ResetTrackingReportItemList => ({
  type: RESET_TRACKING_REPORT_ITEM_LIST,
});

export const setTrackingReportItemCount = (count: number): SetTrackingReportItemCount => ({
  type: SET_TRACKING_REPORT_ITEM_COUNT,
  count,
});

export const setWaybillProcessingList = (list: WaybillProcessing[]): SetWaybillProcessingList => ({
  type: SET_WAYBILL_PROCESSING_LIST,
  list,
});

export const setWaybillProcessingCount = (count: number): SetWaybillProcessingCount => ({
  type: SET_WAYBILL_PROCESSING_COUNT,
  count,
});

export const setCollisionReportItemList = (list: CollisionReportItem[]): SetCollisionReportItemList => ({
  type: SET_COLLISION_REPORT_ITEM_LIST,
  list,
});

export const getTrackingStatusesList = (delivery_service: string): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { delivery_service };
  return http.get('/api/v1/core/tracking/statuses/', params).then(
    (response: any) => {
      dispatch(setTrackingStatusesList(response.statuses));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const getTrackingReportsList = (page: number = 1): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { limit: 25, skip: page * 25 - 25 };
  return http.get('/api/v1/core/tracking/report/list/', params).then(
    (response: any) => {
      dispatch(setTrackingReportList(response.items));
      dispatch(setTrackingReportCount(response.count));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const getTrackingReport = (
  deliveryService: string,
  statuses: Array<string>,
  daysInStatusFrom: string,
  reportType: string,
  daysInStatusTo: string,
): ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => {
  const params: anyObject = { delivery_service: deliveryService };
  const filters = [];
  filters.push({
    statuses: statuses.map((code) => ({ code })),
    condition: reportType,
    value: parseInt(daysInStatusFrom, 10),
  },
  {
    statuses: statuses.map((code) => ({ code })),
    condition: reportType.replace('get', 'lte'),
    value: parseInt(daysInStatusTo, 10),
  });
  params.filter_data = JSON.stringify(filters);
  return http.post('/api/v1/core/tracking/report/', params, { json: true }).then(
    (response: any) => Promise.resolve(response),
    (errors: any) => Promise.reject(errors),
  );
};

export const loadWaybillProcessingList = (page: number, query?: string | null): ThunkAction => (dispatch, getState, http) => {
  const limit = 25;
  const params: anyObject = { skip: page * limit - limit, limit };
  if (query) params.query = query;
  return http.get(
    '/api/v1/core/waybill/processing/list/',
    params,
  ).then(
    ({ count, items }: { count: number, items: WaybillProcessing[] }) => {
      dispatch(setWaybillProcessingList(items));
      dispatch(setWaybillProcessingCount(count));
      return Promise.resolve();
    },
  );
};

export const getTrackingServiceList = (
  deliveryService: string,
  statuses: Array<string>,
  daysInStatusFrom: string,
  reportType: string,
  daysInStatusTo: string,
  page: 1,
): ThunkAction => (
  dispatch, getState, http,
) => {
  const limit = 25;
  const skip = page * limit - limit;
  const params: anyObject = { delivery_service: deliveryService, limit, skip };
  const filters = [];
  filters.push({
    statuses: statuses.map((code) => ({ code })),
    condition: reportType,
    value: parseInt(daysInStatusFrom, 10),
  },
  {
    statuses: statuses.map((code) => ({ code })),
    condition: reportType.replace('gte', 'lte'),
    value: parseInt(daysInStatusTo, 10),
  });
  params.filter_data = JSON.stringify(filters);
  return http.post('/api/v1/core/tracking/service/list/', params, { json: true }).then(
    (response: { items: TrackingReportItem[], count: number }) => {
      dispatch(setTrackingReportItemList(response.items));
      dispatch(setTrackingReportItemCount(response.count));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const loadCollisionReport = (dateFrom: Moment, dateTo: Moment, deliveryService?: string): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: anyObject = {
    date_from: dateFrom.format(SERVER_DATE_FORMAT),
    date_to: dateTo.format(SERVER_DATE_FORMAT),
  };
  if (deliveryService) params.delivery_service = deliveryService;
  return http.get(
    '/api/v1/core/collision_report/',
    params,
  ).then(
    (response: {report: CollisionReportItem[]}) => {
      dispatch(setCollisionReportItemList(response.report));
      return Promise.resolve(response);
    },
  );
};
