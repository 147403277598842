import {
  DashboardItem,
  ReportAction,
  SET_WAYBILL_DASHBOARD_COUNT,
  SET_WAYBILL_DASHBOARD_LIST,
  CollisionReportSummary, SET_COLLISION_REPORT_SUMMARY,
} from './types';

export interface DashboardState {
  waybillDashboardList: DashboardItem[],
  waybillDashboardCount: number,
  collisionReportSummary: CollisionReportSummary[]
}

const initialState: DashboardState = {
  waybillDashboardList: [],
  waybillDashboardCount: 0,
  collisionReportSummary: [],
};

export default function (state = initialState, action: any | ReportAction): DashboardState {
  switch (action.type) {
    case SET_WAYBILL_DASHBOARD_LIST:
      return { ...state, waybillDashboardList: action.list };
    case SET_WAYBILL_DASHBOARD_COUNT:
      return { ...state, waybillDashboardCount: action.count };
    case SET_COLLISION_REPORT_SUMMARY:
      return { ...state, collisionReportSummary: action.summary };
    default:
      return state;
  }
}
