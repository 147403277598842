import {
  ADD_TENDER_RATE_PRIORITY,
  AddTenderRatePriority,
  REMOVE_TENDER_RATE_PRIORITY,
  RemoveTenderRatePriority,
  REPLACE_TENDER_RATE_PRIORITY,
  ReplaceTenderRatePriority,
  RESET_TENDER_RATE_PRIORITY_LIST,
  ResetTenderRatePriorityList,
  SET_TENDER_RATE_PRIORITY_LIST,
  SetTenderRatePriorityList,
  TenderRatePriority
} from "./types";
import {ThunkAction} from "../../utils/types";
import {adaptToApi} from "../../utils/adapter";

export const setTenderRatePriorityList = (tenderRatePriorityList: TenderRatePriority[]): SetTenderRatePriorityList => ({
  type: SET_TENDER_RATE_PRIORITY_LIST,
  tenderRatePriorityList,
});

export const resetTenderRatePriorityList = (): ResetTenderRatePriorityList => ({
  type: RESET_TENDER_RATE_PRIORITY_LIST,
});

export const replaceTenderRatePriority = (tenderRatePriority: TenderRatePriority): ReplaceTenderRatePriority => ({
  type: REPLACE_TENDER_RATE_PRIORITY,
  tenderRatePriority,
});

export const removeTenderRatePriority = (tenderRatePriorityId: number): RemoveTenderRatePriority => ({
  type: REMOVE_TENDER_RATE_PRIORITY,
  tenderRatePriorityId,
});

export const addTenderRatePriority = (tenderRatePriority: TenderRatePriority): AddTenderRatePriority => ({
  type: ADD_TENDER_RATE_PRIORITY,
  tenderRatePriority,
});


export const loadTenderRatePriorityList = (): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/rates/tender/rate_priority/list/',
).then(
  ({ rates }: { rates: TenderRatePriority[]}) => {
    dispatch(setTenderRatePriorityList(rates));
    return Promise.resolve();
  },
);

export const createTenderRatePriority = (data: anyObject): ThunkAction => (dispatch, getState, http) => http.post(
  '/api/v1/rates/tender/rate_priority/create/',
  adaptToApi(data),
).then(
  (response: TenderRatePriority) => {
    dispatch(addTenderRatePriority(response));
    return Promise.resolve(response);
  },
);

export const updateTenderRatePriority = (tenderRatePriorityId: number, data: anyObject): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/rates/tender/rate_priority/${tenderRatePriorityId}/update/`,
  adaptToApi(data),
).then(
  (tenderRate: TenderRatePriority) => {
    dispatch(replaceTenderRatePriority(tenderRate));
    return Promise.resolve(tenderRate);
  },
);

export const switchTenderRatePriority = (tenderRatePriorityId: number, enabled: boolean): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/rates/tender/rate_priority/${tenderRatePriorityId}/switch/`,
  { enabled },
).then(
  (tenderRate: TenderRatePriority) => {
    dispatch(replaceTenderRatePriority(tenderRate));
    return Promise.resolve(tenderRate);
  },
);

export const deleteTenderRatePriority = (tenderRatePriorityId: number): ThunkAction => (dispatch, getState, http) => http.delete(
  `/api/v1/rates/tender/rate_priority/${tenderRatePriorityId}/delete/`,
).then(
  () => {
    dispatch(removeTenderRatePriority(tenderRatePriorityId));
    return Promise.resolve();
  },
);
