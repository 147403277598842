export const SET_DIALOG_REVIEW_LIST = 'SET_DIALOG_REVIEW_LIST';
export const SET_DIALOG_REVIEW_COUNT = 'SET_DIALOG_REVIEW_COUNT';
export const SET_CURRENT_DIALOG_REVIEW = 'SET_CURRENT_DIALOG_REVIEW';
export const RESET_CURRENT_DIALOG_REVIEW = 'RESET_CURRENT_DIALOG_REVIEW';
export const SET_CURRENT_DIALOG_REVIEW_QUESTION_LIST = 'SET_CURRENT_DIALOG_REVIEW_QUESTION_LIST';
export const RESET_CURRENT_DIALOG_REVIEW_QUESTION_LIST = 'RESET_CURRENT_DIALOG_REVIEW_QUESTION_LIST';

export type DialogReview = {
  reviewId: number;
  dialogId: string;
  createdAt: string;
  date: string;
  doneCount: number,
  totalCount: number,
};

export type ReviewAnswer = {
  text: string;
  answerId: number;
};

export enum ReviewAnswerType {
  OPTIONS = 'OPTIONS',
  MARK = 'MARK'
}

export type ReviewQuestion = {
  questionId: number;
  text: string;
  answerType: ReviewAnswerType;
  answerList: ReviewAnswer[];
};

export interface SetDialogReviewList {
  type: typeof SET_DIALOG_REVIEW_LIST,
  list: DialogReview[],
}

export interface SetDialogReviewCount {
  type: typeof SET_DIALOG_REVIEW_COUNT,
  count: number,
}

export interface SetCurrentDialogReview {
  type: typeof SET_CURRENT_DIALOG_REVIEW,
  review: DialogReview,
}

export interface ResetCurrentDialogReview {
  type: typeof RESET_CURRENT_DIALOG_REVIEW,
}

export interface SetCurrentDialogReviewQuestionList {
  type: typeof SET_CURRENT_DIALOG_REVIEW_QUESTION_LIST,
  list: ReviewQuestion[]
}

export interface ResetCurrentDialogReviewQuestionList {
  type: typeof RESET_CURRENT_DIALOG_REVIEW_QUESTION_LIST,
}

export type ReviewActions =
  SetDialogReviewList |
  SetDialogReviewCount |
  SetCurrentDialogReview |
  ResetCurrentDialogReview |
  SetCurrentDialogReviewQuestionList |
  ResetCurrentDialogReviewQuestionList;
