import { Moment } from 'moment';

export const SET_WAYBILL_PROCESSING_LIST = 'SET_WAYBILL_PROCESSING_LIST';
export const SET_WAYBILL_PROCESSING_COUNT = 'SET_WAYBILL_PROCESSING_COUNT';
export const SET_TRACKING_REPORT_ITEM_LIST = 'SET_TRACKING_REPORT_ITEM_LIST';
export const RESET_TRACKING_REPORT_ITEM_LIST = 'RESET_TRACKING_REPORT_ITEM_LIST';
export const SET_TRACKING_REPORT_ITEM_COUNT = 'SET_TRACKING_REPORT_ITEM_COUNT';
export const SET_TRACKING_STATUSES_LIST = 'SET_TRACKING_STATUSES_LIST';
export const SET_TRACKING_REPORT_LIST = 'SET_TRACKING_REPORT_LIST';
export const SET_TRACKING_REPORT_COUNT = 'SET_TRACKING_REPORT_COUNT';
export const SET_COLLISION_REPORT_ITEM_LIST = 'SET_COLLISION_REPORT_ITEM_LIST';

export interface TrackingStatus {
  code: string
}

export interface TrackingReport {
  reportId: number,
  file: string,
  createdDate: string,
}

export type ProcessingEvent = {
  eventName: string,
  date: Moment,
};

export type WaybillProcessing = {
  beginDate: Moment,
  endDate: Moment,
  plsWaybillNumber: string,
  serviceWaybillNumber: string,
  serviceOrderNumber: string,
  deliveryService: string,
  rate: string,
  maxDays: number,
  minDays: number,
  events: ProcessingEvent[],
};

export type TrackingReportItem = {
  waybillId: number;
  plsWaybillNumber: string;
  serviceWaybillNumber: string;
  deliveryService: string;
  createdDate: string;
  deliveryDate: string;
  receiverCity: string;
  currentCity: string;
  lastStatus: string;
  duration: number;
  revertStatus: string;
  accountTitle: string;
  sellerTitle: string;
};

export type CollisionReportItem = {
  date: string;
  dayStartActive: number;
  dayEndActive: number;
  newActive: number;
  closed: number;
};

export interface SetWaybillProcessingList {
  type: typeof SET_WAYBILL_PROCESSING_LIST,
  list: WaybillProcessing[],
}

export interface SetWaybillProcessingCount {
  type: typeof SET_WAYBILL_PROCESSING_COUNT,
  count: number,
}

export interface SetTrackingReportItemList {
  type: typeof SET_TRACKING_REPORT_ITEM_LIST,
  list: TrackingReportItem[],
}

export interface ResetTrackingReportItemList {
  type: typeof RESET_TRACKING_REPORT_ITEM_LIST,
}

export interface SetTrackingReportItemCount {
  type: typeof SET_TRACKING_REPORT_ITEM_COUNT,
  count: number,
}

export interface SetTrackingStatusesList {
  type: typeof SET_TRACKING_STATUSES_LIST,
  statuses: TrackingStatus[],
}

export interface SetTrackingReportList {
  type: typeof SET_TRACKING_REPORT_LIST,
  reportList: TrackingReport[],
}

export interface SetTrackingReportCount {
  type: typeof SET_TRACKING_REPORT_COUNT,
  count: number,
}

export interface SetCollisionReportItemList {
  type: typeof SET_COLLISION_REPORT_ITEM_LIST,
  list: CollisionReportItem[],
}

export type ReportAction =
  SetWaybillProcessingList
  | SetWaybillProcessingCount
  | SetTrackingReportItemList
  | ResetTrackingReportItemList
  | SetTrackingReportItemCount
  | SetTrackingStatusesList
  | SetTrackingReportList
  | SetTrackingReportCount
  | SetCollisionReportItemList;
