import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ForeignInvoiceList = React.lazy(() => import('../components/ForeignInvoiceList'));
function ForeignInvoiceListView(params: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ForeignInvoiceList {...params} />
    </Suspense>
  );
}

export default ForeignInvoiceListView;
