export const SET_TERMINALS_LIST = 'SET_TERMINALS_LIST';
export const SET_TERMINALS_COUNT = 'SET_TERMINALS_COUNT';

export type TerminalItem = {
  terminalId: number,
  code: string,
  title: string,
  address: string,
  deliveryService: string,
  city: string,
};

export interface SetTerminalsList {
  type: typeof SET_TERMINALS_LIST,
  payload: TerminalItem[]
}

export interface SetTerminalsCount {
  type: typeof SET_TERMINALS_COUNT,
  payload: number,
}

export type TerminalActions =
  SetTerminalsList |
  SetTerminalsCount;
