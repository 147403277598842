import { RateGroup, Rate } from '../rate/types';

export const SET_DELIVERY_SERVICES = 'SET_DELIVERY_SERVICES';
export const SET_SELLER_LIST = 'SET_SELLER_LIST';
export const SET_SURCHARGE_TYPES_LIST = 'SET_SURCHARGE_TYPES_LIST';
export const SET_REFERENCE_RATE_GROUP_LIST = 'SET_REFERENCE_RATE_GROUP_LIST';
export const SET_RATE_LIST = 'SET_REFERENCE_RATE_LIST';
export const ADD_REFERENCE_RATE = 'ADD_REFERENCE_RATE';
export const REMOVE_REFERENCE_RATE = 'REMOVE_REFERENCE_RATE';
export const UPDATE_REFERENCE_RATE = 'UPDATE_REFERENCE_RATE';
export const SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST = 'SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST';

export interface DeliveryService {
  key: string,
  title: string,
  icon: string,
  rating: number,
}

export type Seller = {
  sellerId: number,
  title: string,
  nds: boolean
};

export type SurchargeType = {
  id: number,
  title: string,
  code: string,
};

export interface AdditionalServiceDescription {
  key: string,
  title: string,
  description: string,
}

export interface SetDeliveryServices {
  type: typeof SET_DELIVERY_SERVICES,
  deliveryServices: Array<DeliveryService>
}

export interface SetSellerList {
  type: typeof SET_SELLER_LIST,
  list: Seller[],
}

export interface SetSurchargeTypesList {
  type: typeof SET_SURCHARGE_TYPES_LIST,
  list: SurchargeType[],
}

export interface SetReferenceRateGroupList {
  type: typeof SET_REFERENCE_RATE_GROUP_LIST,
  list: RateGroup[],
}

export interface SetRateList {
  type: typeof SET_RATE_LIST,
  list: Rate[],
}

export interface AddReferenceRate {
  type: typeof ADD_REFERENCE_RATE,
  rate: Rate,
}

export interface RemoveReferenceRate {
  type: typeof REMOVE_REFERENCE_RATE,
  rateId: number,
}

export interface UpdateReferenceRate {
  type: typeof UPDATE_REFERENCE_RATE,
  rate: Rate
}

export interface SetAdditionalServiceDescList {
  type: typeof SET_ADDITIONAL_SERVICE_DESCRIPTION_LIST,
  payload: Array<AdditionalServiceDescription>
}

export type ReferenceActions = SetDeliveryServices | SetSellerList | SetSurchargeTypesList | SetReferenceRateGroupList |
SetRateList | AddReferenceRate | RemoveReferenceRate | UpdateReferenceRate | SetAdditionalServiceDescList;
