import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const OperatorKeyTable = React.lazy(() => import('../components/OperatorKeyTable'));
function OperatorKeyTableView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <OperatorKeyTable />
    </Suspense>
  );
}

export default OperatorKeyTableView;
