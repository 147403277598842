import React from 'react';
import moment from 'moment';
import { Layout } from 'antd';
import TopBar from '../customComponents/Topbar';
import useDispatch from '../utils/hooks/useDispatch';
import { getWaybillControlFiltersList } from '../app/waybills/action-creators';
import useSelector from '../utils/hooks/useSelector';
import { FastFilter } from '../components/FastFilter';
import { FilterDateKeys, WaybillControlFilter } from '../app/waybills/types';
import { SERVER_DATE_FORMAT } from '../utils/constants';
import { isArray } from 'lodash';

interface MainViewProps {
  children: React.ReactNode,
  wrapperClassName?: string,
}

const QUERY_CONTROL_PARAMS_CONFIG = {
  query: { type: 'string' },
  page: { type: 'pageNumber' },
  createdAtFrom: { type: 'date' },
  createdAtTo: { type: 'date' },
  pickupDateFrom: { type: 'date' },
  pickupDateTo: { type: 'date' },
  billingDateFrom: { type: 'date' },
  billingDateTo: { type: 'date' },
  deliveryDateFrom: { type: 'date' },
  deliveryDateTo: { type: 'date' },
  service: { type: 'array' },
  status: { type: 'array' },
  additionalService: { type: 'array' },
  tariff: { type: 'array' },
  overdue: { type: 'number' },
  insuranceFrom: { type: 'number' },
  insuranceTo: { type: 'number' }
};

function MainView({ children, wrapperClassName }: MainViewProps) {
  const dispatch = useDispatch();
  const { controlFilterList } = useSelector((state) => state.waybills);
  React.useEffect(() => {
    dispatch(getWaybillControlFiltersList())
  }, [])
  const prepareFieldValue = (name: string, value: any, searchParams: any) => {
    if (value === null) {
      return;
    }
    //@ts-ignore
    switch (QUERY_CONTROL_PARAMS_CONFIG[name].type) {
      case 'date':
        if (value) searchParams.append(name, moment(value).format(SERVER_DATE_FORMAT));
        return;
      case 'array':
        if (value && value.length) value.forEach((_: string) => searchParams.append(name, _));
        return;
      case 'pageNumber':
        if (value && value > 1) searchParams.append(name, value);
        return;
      case 'integer':
      case 'string':
      default:
        if (value) searchParams.append(name, value);
    }
  };

  const buildFilterUrl = (i: WaybillControlFilter) => {
    const searchParams = new URLSearchParams();
    const tmp: any = { ...i };
    Object.keys(tmp).forEach(key => {
      if (['count', 'title', 'filterId'].includes(key)) delete tmp[key];
      else if (!tmp[key] || isArray(tmp[key]) && !tmp[key].length) tmp[key] = null;
    })
    if (tmp.hasOwnProperty('deliveryService')) {
      tmp.service = tmp.deliveryService;
      delete tmp.deliveryService;
    }
    if (tmp.hasOwnProperty('rateCode')) {
      tmp.tariff = tmp.rateCode;
      delete tmp.rateCode;
    }
    Object.keys(QUERY_CONTROL_PARAMS_CONFIG).forEach((name) => {
      // @ts-ignore
      prepareFieldValue(name, tmp[name], searchParams);
    });
    window.location.href = `${window.location.origin}/control_360?${searchParams.toString()}`
  }
  return (
    <Layout className="ant-layout">
      <TopBar />
      <div className="gx-layout-content gx-container-wrap  ant-layout-content">
        <div className={wrapperClassName || 'gx-main-content-wrapper'}>
          {
            window.location.pathname.indexOf("control_360") === -1  
            ? 
            controlFilterList.map(i => (
              <FastFilter
                item={i}
                isLinkView
                handleSetFilter={() => buildFilterUrl(i)}/>
            ))
            :
            null
          }
          { children }
        </div>
      </div>
    </Layout>
  );
}

export default MainView;
