import {
  SET_COUNTRIES_COUNT,
  SET_COUNTRIES_LIST,
  CountryActions,
  CountryItem
} from './types';

export interface CountryState {
  countriesList: Array<CountryItem>,
  countriesCount: number,
}

const initialState: CountryState = {
  countriesList: [],
  countriesCount: 0,
};

export default function (state = initialState, action: CountryActions): CountryState {
  switch (action.type) {
    case SET_COUNTRIES_LIST:
      return { ...state, countriesList: action.payload };
    case SET_COUNTRIES_COUNT:
      return { ...state, countriesCount: action.payload };
    default:
      return state;
  }
}
