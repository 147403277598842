import { loadGlobalCounters } from '../counter/action-creators';
import connectWS from '../../utils/websocket';
import { startDialogChecker, stopDialogChecker } from '../chat/action-creators';
import { loadReferences } from '../reference/action-creators';

export const setUser = (user) => ({
  type: 'SET_USER',
  user,
});


export const setToken = (accessToken, refreshToken, expiredAt, remember) => ({
  type: 'SET_TOKEN',
  accessToken,
  refreshToken,
  expiredAt,
  remember,
});

export const setCent = (cent) => ({
  type: 'SET_CENT',
  cent,
});


export const getCurrentUserData = () => (dispatch, getState, http) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken && accessToken !== 'NaN' && accessToken !== 'null') {
    return http.get('/api/v1/user/detail/')
      .then(
        (response) => {
          dispatch(setUser(response));
          dispatch(loadGlobalCounters());
          dispatch(connectWS());
          dispatch(loadReferences());
          // dispatch(startDialogChecker());
          return Promise.resolve(response);
        },
        (reject) => Promise.reject(reject),
      );
  }
  return Promise.reject();
};


export const authorizeUser = (username, password, remember = true) => (dispatch, getState, http) => http.post('/api/v1/token/', { username, password })
  .then(
    (response) => {
      const { accessToken, refreshToken } = response;
      const expiredAt = new Date().getTime() + 300000;
      dispatch(setToken(accessToken, refreshToken, expiredAt, remember));
      return dispatch(getCurrentUserData());
    },
    (reject) => Promise.reject(reject),
  );


export const signOut = () => (dispatch) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpired');
  dispatch(setUser(null));
  dispatch(setToken(null));
  // dispatch(stopDialogChecker());
};
