import React from 'react';
import { Col, Row } from 'antd';
import List from 'antd/es/list';
import Pagination from 'antd/es/pagination';
import Spin from 'antd/es/spin';
import Card from 'antd/es/card';
import withQueryParams, { SetFieldValueFunc } from '../../../utils/withQueryParams';
import useDispatch from '../../../utils/hooks/useDispatch';
import useSelector from '../../../utils/hooks/useSelector';
import { loadWaybillProcessingList } from '../action-creators';
import WaybillProcessingListItem from './WaybillProcessingListItem';
import SimplePagination from '../../../components/SimplePagination';
import SearchBox from '../../../components/List/SearchBox';

const QUERY_PARAMS_CONFIG = {
  query: { type: 'string' },
  page: { type: 'pageNumber' },
};

interface WaybillProcessingReportProps {
  setFieldValue: SetFieldValueFunc,
  params: {
    query: string | null,
    page: number,
  },
}

function WaybillProcessingReport({ setFieldValue, params: { page, query } }: WaybillProcessingReportProps) {
  const dispatch = useDispatch();
  const { waybillProcessingCount, waybillProcessingList } = useSelector((state) => state.report);
  const [loading, setLoading] = React.useState(false);

  const handlePageChange = React.useCallback(
    (newPage) => {
      setLoading(true);
      setFieldValue({ page: newPage });
      dispatch(loadWaybillProcessingList(newPage, query)).finally(() => setLoading(false));
    },
    [query],
  );

  const handleSearchChange = React.useCallback(
    (newQuery) => {
      setLoading(true);
      setFieldValue({ query: newQuery, page: 1 });
      dispatch(loadWaybillProcessingList(1, newQuery)).finally(() => setLoading(false));
    },
    [page],
  );


  React.useEffect(() => {
    setLoading(true);
    dispatch(loadWaybillProcessingList(page, query)).finally(() => setLoading(false));
  }, []);


  return (
    <Row>
      <Col lg={13} md={10} sm={12} xs={12}>
        <div className="title h1"><p>Отчет о нарушенных сроках сбора и доставки</p></div>
      </Col>

      <Col xs={24} md={14} lg={11}>
        <SimplePagination
          current={page}
          pageSize={25}
          total={waybillProcessingCount}
          loading={loading}
          onChange={handlePageChange}
        />
      </Col>
      <Col xs={24}>
        <SearchBox
          searchValue={query}
          searchPlaceholder="Поиск"
          onSearchChange={handleSearchChange}
          filters={undefined}
          tags={undefined}
          withFavorites={false}
        />
      </Col>
      <Col xs={24}>
        <Card>
          <List
            itemLayout="horizontal"
            renderItem={(_) => <WaybillProcessingListItem item={_} />}
            dataSource={waybillProcessingList}
            loading={loading}
          >

            {/* {waybillProcessingCount > 5 */}
            {/* && ( */}
            {/*  <Pagination */}
            {/*    total={waybillProcessingCount} */}
            {/*    onChange={handlePageChange} */}
            {/*    current={page} */}
            {/*    pageSize={25} */}
            {/*  /> */}
            {/* )} */}

          </List>
        </Card>
      </Col>
    </Row>
  );
}

export default withQueryParams(QUERY_PARAMS_CONFIG)(WaybillProcessingReport);
