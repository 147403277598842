import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const TerminalList = React.lazy(() => import('../components/TerminalList'));

function WaybillListView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <TerminalList {...props} />
    </Suspense>
  );
}

export default WaybillListView;
