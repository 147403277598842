import {
  ADD_APISHIP_DELIVERY_SERVICE,
  ApishipDeliveryService,
  DeliveryServiceActions,
  GeoPolygonDetailItem,
  GeoPolygonListItem,
  OperatorKey,
  OperatorSchedulerSetting,
  REMOVE_APISHIP_DELIVERY_SERVICE,
  REPLACE_APISHIP_DELIVERY_SERVICE,
  RESET_GEO_POLYGON_DETAIL,
  SET_APISHIP_DELIVERY_SERVICE_LIST,
  SET_GEO_POLYGON_COUNT,
  SET_GEO_POLYGON_DETAIL,
  SET_GEO_POLYGON_LIST,
  SET_OPERATOR_KEY_LIST,
  SET_OPERATOR_SCHEDULER_SETTINGS,
  UPDATE_GEO_POLYGON_DETAIL,
} from './types';
import removeByFieldValue from '../../utils/array/remove';
import replaceByFieldValue from '../../utils/array/replace';

type DeliveryServiceState = {
  operatorKeyList: OperatorKey[],
  operatorSchedulerSettings: OperatorSchedulerSetting[],
  geoPolygonList: GeoPolygonListItem[],
  geoPolygonCount: number,
  geoPolygonDetail: GeoPolygonDetailItem | null,
  apishipServices: ApishipDeliveryService[],
};

const initialState: DeliveryServiceState = {
  operatorKeyList: [],
  operatorSchedulerSettings: [],
  geoPolygonList: [],
  geoPolygonCount: 0,
  geoPolygonDetail: null,
  apishipServices: [],
};

export default function (state = initialState, action: DeliveryServiceActions): DeliveryServiceState {
  switch (action.type) {
    case SET_OPERATOR_KEY_LIST:
      return { ...state, operatorKeyList: action.list };
    case SET_OPERATOR_SCHEDULER_SETTINGS:
      return { ...state, operatorSchedulerSettings: action.settings };
    case SET_GEO_POLYGON_LIST:
      return { ...state, geoPolygonList: action.list };
    case SET_GEO_POLYGON_COUNT:
      return { ...state, geoPolygonCount: action.count };
    case SET_GEO_POLYGON_DETAIL:
      return { ...state, geoPolygonDetail: action.detail };
    case RESET_GEO_POLYGON_DETAIL:
      return { ...state, geoPolygonDetail: initialState.geoPolygonDetail };
    case UPDATE_GEO_POLYGON_DETAIL:
      if (state.geoPolygonDetail) {
        return { ...state, geoPolygonDetail: { ...state.geoPolygonDetail, ...action.detail } };
      }
      return state;
    case ADD_APISHIP_DELIVERY_SERVICE:
      return {
        ...state, apishipServices: [...state.apishipServices, action.service],
      };
    case SET_APISHIP_DELIVERY_SERVICE_LIST:
      return {
        ...state, apishipServices: action.list,
      };
    case REMOVE_APISHIP_DELIVERY_SERVICE:
      return {
        ...state, apishipServices: removeByFieldValue(state.apishipServices, 'deliveryServiceId', action.serviceId),
      };
    case REPLACE_APISHIP_DELIVERY_SERVICE:
      return {
        ...state, apishipServices: replaceByFieldValue(state.apishipServices, 'deliveryServiceId', action.service),
      };
    default:
      return state;
  }
}
