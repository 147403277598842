import {
  Claim,
  claimActions,
  ClaimDetail,
  RESET_CURRENT_CLAIM,
  SET_CLAIM_COUNT,
  SET_CLAIM_LIST,
  SET_CURRENT_CLAIM,
} from './types';

type claimState = {
  claimList: Claim[],
  claimCount: number,
  currentClaim: ClaimDetail | null,
}

const initialState: claimState = {
  claimList: [],
  claimCount: 0,
  currentClaim: null,
};

export default function (state = initialState, action: claimActions): claimState {
  switch (action.type) {
    case SET_CLAIM_LIST:
      return { ...state, claimList: action.list };
    case SET_CLAIM_COUNT:
      return { ...state, claimCount: action.count };
    case SET_CURRENT_CLAIM:
      return { ...state, currentClaim: action.claim };
    case RESET_CURRENT_CLAIM:
      return { ...state, currentClaim: initialState.currentClaim };
    default: return state;
  }
}
