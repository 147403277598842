export const SET_RATES_CASHBACK_LIST = 'SET_RATES_CASHBACK_LIST';
export const SET_RATES_CASHBACK_COUNT = 'SET_RATES_CASHBACK_COUNT';
export const SET_RATES_CASHBACK_ITEM = 'SET_RATES_CASHBACK_ITEM';

export const RESET_RATES_CASHBACK = 'RESET_RATES_CASHBACK';


export interface RateCashback {
  id: number,
  rateCode: string,
  rank?: string,
  group?: string,
  cashbackPercent: number,
}

export interface SetRatesCashbackList {
  type: typeof SET_RATES_CASHBACK_LIST,
  payload: Array<RateCashback>,
}

export interface SetRatesCashbackCount {
  type: typeof SET_RATES_CASHBACK_COUNT,
  payload: number,
}

export interface SetRatesCashbackItem {
  type: typeof SET_RATES_CASHBACK_ITEM,
  payload: RateCashback,
}

export interface ResetRatesCashback {
  type: typeof RESET_RATES_CASHBACK,
}

export type RatesCashbackAction = 
  SetRatesCashbackList | 
  SetRatesCashbackCount | 
  SetRatesCashbackItem |
  ResetRatesCashback;
