import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const RateMarketing = React.lazy(() => import('../components/RateMarketing'));
function RateDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <RateMarketing {...props} />
    </Suspense>
  );
}

export default RateDetailView;
