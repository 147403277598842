import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';


const TrackingReport = React.lazy(() => import('../components/TrackingReport'));
function TrackingReportView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <TrackingReport {...props} />
    </Suspense>
  );
}

export default TrackingReportView;
