import React, { useCallback, useState } from 'react';
import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

export interface AsyncSwitchProps extends SwitchProps{
  onChange: (checked: boolean, event: MouseEvent) => Promise<any>;
}

function AsyncSwitch({ onChange, loading, ...rest }: AsyncSwitchProps) {
  const [curLoading, setCurLoading] = useState(loading);

  const handleChange = useCallback((checked, event) => {
    setCurLoading(true);
    onChange(checked, event).then(
      () => setCurLoading(false),
      () => setCurLoading(false),
    );
  }, [onChange]);

  return (
    <Switch {...rest} loading={curLoading} onChange={handleChange} />
  );
}

export default AsyncSwitch;
