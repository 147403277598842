import React from 'react';
import moment from 'moment';
import {
  Card, Col, Row, Table,
} from 'antd';
import withQueryParams, { SetFieldValueFunc } from '../../../utils/withQueryParams';
import useDispatch from '../../../utils/hooks/useDispatch';
import useSelector from '../../../utils/hooks/useSelector';
import { loadCollisionReport } from '../action-creators';
import DateRangeField from '../../../components/form/DateRangeField';
import { USER_DATE_FORMAT } from '../../../utils/constants';
import { match as matchType } from 'react-router-dom';

const QUERY_PARAMS = {
  dateFrom: { type: 'date' },
  dateTo: { type: 'date' },
};

interface CollisionReportRoute {
  deliveryService: string
}

type CollisionReportParams = {
  dateFrom: moment.Moment,
  dateTo: moment.Moment,
};

interface CollisionReportProps {
  setFieldValue: SetFieldValueFunc,
  params: CollisionReportParams,
  match: matchType<CollisionReportRoute>,
}

function CollisionReport({ setFieldValue, params, match: { params: { deliveryService } } }: CollisionReportProps) {
  const dispatch = useDispatch();
  const { collisionReportItems } = useSelector((state) => state.report);
  const { deliveryServices } = useSelector((state) => state.reference);
  const [loading, setLoading] = React.useState(false);
  const FROM_DEFAULT_VALUE = moment().subtract(30, 'days');
  const TO_DEFAULT_VALUE = moment();

  const loadReport = React.useCallback(
    ({ dateFrom, dateTo }) => {
      setLoading(true);
      dispatch(loadCollisionReport(dateFrom ?? FROM_DEFAULT_VALUE, dateTo ?? TO_DEFAULT_VALUE, deliveryService)).finally(
        () => setLoading(false),
      );
    },
    [deliveryService],
  );

  const handleDateRangeChange = React.useCallback(
    ([newDateFrom, newDateTo]) => {
      setFieldValue({
        dateFrom: newDateFrom || FROM_DEFAULT_VALUE,
        dateTo: newDateTo || TO_DEFAULT_VALUE,
      }, (newParams) => loadReport(newParams));
    },
    [setFieldValue, loadReport],
  );

  React.useEffect(() => {
    loadReport(params);
  }, [deliveryService]);

  return (
    <Row>
      <Col xs={24}>
        <div className="title h1"><p>Отчет по контрольным статусам {deliveryServices[deliveryService]?.title}</p></div>
      </Col>
      <Col xs={24}>
        <Card
          extra={(
            <div className="gx-d-flex">
              <DateRangeField
                value={[params.dateFrom ?? FROM_DEFAULT_VALUE, params.dateTo ?? TO_DEFAULT_VALUE]}
                onChange={handleDateRangeChange}
                singleLine
                style={{
                  display: 'flex',
                  float: 'right',
                }}
                fromLabel="Дата с:&nbsp;"
                toLabel="&nbsp;&nbsp;&nbsp;по:&nbsp;"
              />
            </div>
          )}
        >
          <Table
            loading={loading}
            pagination={false}
            dataSource={collisionReportItems}
            className="gx-table-responsive"
            columns={[
              {
                title: 'Дата',
                render: (_, record) => moment(record.date).format(USER_DATE_FORMAT),
              },
              {
                title: 'Открытых на начало дня',
                dataIndex: 'dayStartActive',
              },
              {
                title: 'Новых',
                dataIndex: 'newActive',
              },
              {
                title: 'Закрытых',
                dataIndex: 'closed',
              },
              {
                title: 'Открытых на конец дня',
                dataIndex: 'dayEndActive',
              },
            ]}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default withQueryParams(QUERY_PARAMS)(CollisionReport);
