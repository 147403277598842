import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ChatAnswerStatistic = React.lazy(() => import('../components/ChatAnswerStatistic'));

function ChatAnswerStatisticView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ChatAnswerStatistic {...props} />
    </Suspense>
  );
}

export default ChatAnswerStatisticView;
