import { Package } from './types';


export interface CargoState {
  packages: Package[]
}

const initialState: CargoState = {
  packages: [{
    width: undefined,
    height: undefined,
    length: undefined,
    weight: undefined,
    description: '',
    amount: undefined,
  }],
};

function removeAtIndex(index: number, arr: [] | Package[]) {
  const tmp = [...arr];
  tmp.splice(index, 1);
  return tmp;
}

function replaceAtIndex(index: number, arr: [] | Package[], newItem: any) {
  const tmp = [...arr];
  tmp.splice(index, 1, newItem);
  return tmp;
}

export default function (state = initialState, action: any): CargoState {
  switch (action.type) {
    case 'ADD_PACKAGE':
      return { ...state, packages: [...state.packages, initialState.packages[0]] };
    case 'DELETE_PACKAGE':
      if (state.packages.length === 1) {
        return { ...state, packages: initialState.packages };
      }
      return { ...state, packages: removeAtIndex(action.packageId, state.packages) };
    case 'SET_PACKAGE':
      return { ...state, packages: action.packages };
    case 'REPLACE_PACKAGE':
      return { ...state, packages: replaceAtIndex(action.packageId, state.packages, action.package) };
    case 'RESET_CARGO':
      return { ...state, ...initialState };
    default:
      return state;
  }
}
