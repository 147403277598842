import {
  ADD_WAYBILL_FILTER,
  AddWaybillFilter,
  InsuranceListItem,
  Rate, REMOVE_WAYBILL_FILTER, RemoveWaybillFilter, SET_INSURANCE_ITEM_COUNT,
  SET_INSURANCE_ITEMS,
  SET_WAYBILL_FILTERS_COUNT,
  SET_WAYBILL_FILTERS_LIST,
  SetInsuranceItems, SetInsuranceItemsCount,
  SetWaybillFiltersCount,
  SetWaybillFiltersList,
  Waybill,
  WaybillControlFilter,
  WaybillPricing,
  WaybillTracking,
  UpdateWaybillFilter,
  UPDATE_WAYBILL_FILTER
} from './types';
import { ThunkAction } from '../../utils/types';
import { Cargo } from './components/cargo/types';
import { resetCargo } from './components/cargo/action-creators';
import { InsuranceListParams } from './components/InsuranceList';
import { SERVER_DATE_FORMAT } from '../../utils/constants';
import {adaptFromApi} from "../../utils/adapter";
import { isString } from 'lodash';

export const setWaybillsList = (waybills: Array<Waybill>) => ({
  type: 'SET_WAYBILLS_LIST',
  waybills,
});

export const setWaybillsCount = (count: number) => ({
  type: 'SET_WAYBILLS_COUNT',
  count,
});

export const setCurrentWaybill = (waybill: Waybill) => ({
  type: 'SET_CURRENT_WAYBILL',
  waybill,
});

export const resetCurrentWaybill = () => ({
  type: 'RESET_CURRENT_WAYBILL',
});

export const setCurrentWaybillTracking = (tracking: WaybillTracking[]) => ({
  type: 'SET_CURRENT_WAYBILL_TRACKING',
  tracking,
});

export const setCurrentWaybillPricing = (pricing: WaybillPricing[]) => ({
  type: 'SET_CURRENT_WAYBILL_PRICING',
  pricing,
});

export const setCurrentWaybillHistory = (history: any[]) => ({
  type: 'SET_CURRENT_WAYBILL_HISTORY',
  history,
});

export const resetCurrentWaybillTracking = () => ({
  type: 'SET_CURRENT_WAYBILL_TRACKING',
  tracking: [],
});

export const resetCurrentWaybillPricing = () => ({
  type: 'SET_CURRENT_WAYBILL_PRICING',
  pricing: [],
});

export const setRateTask = (taskId: string) => ({
  type: 'SET_RATE_TASK',
  taskId,
});

export const resetRateTask = () => ({
  type: 'SET_RATE_TASK',
  taskId: '',
});

export const setRates = (rates: Rate[]) => ({
  type: 'SET_RATE_LIST',
  rates,
});

export const resetRates = () => ({
  type: 'SET_RATE_LIST',
  rates: [],
});

export const setSurchargeTypes = (surchargeTypes: Array<any>) => ({
  type: 'SET_SURCHARGE_TYPES',
  surchargeTypes,
});

export const setInsuranceItems = (items: InsuranceListItem[]): SetInsuranceItems => ({
  type: SET_INSURANCE_ITEMS,
  items,
});

export const setInsuranceItemCount = (count: number): SetInsuranceItemsCount => ({
  type: SET_INSURANCE_ITEM_COUNT,
  count
})

export const setWaybillFiltersList = (payload: WaybillControlFilter[]): SetWaybillFiltersList => ({
  type: SET_WAYBILL_FILTERS_LIST,
  payload,
});

export const addWaybillFilter = (payload: WaybillControlFilter): AddWaybillFilter => ({
  type: ADD_WAYBILL_FILTER,
  payload,
});

export const setWaybillFiltersCount = (payload: {filterId: string, count: number}[]): SetWaybillFiltersCount => ({
  type: SET_WAYBILL_FILTERS_COUNT,
  payload,
});

export const removeWaybillFilter = (payload: string): RemoveWaybillFilter => ({
  type: REMOVE_WAYBILL_FILTER,
  payload
})

export const updateWaybillFilter = (payload: WaybillControlFilter): UpdateWaybillFilter => ({
  type: UPDATE_WAYBILL_FILTER,
  payload
})

export const getOldWaybillsList = (
  query = '',
  page = 1,
  status: any,
  service: any,
  createdAtFrom: any,
  createdAtTo: any,
  pickupDateFrom: any,
  pickupDateTo: any,
) : ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => {
  const params: any = { query, limit: 25, skip: page * 25 - 25 };
  if (status && status.length) {
    params.status = status;
  }
  if (service) {
    params.delivery_service = service;
  }
  if (createdAtFrom) {
    params.created_at_from = createdAtFrom;
  }
  if (createdAtTo) {
    params.created_at_to = createdAtTo;
  }
  if (pickupDateFrom) {
    params.pickup_date_from = pickupDateFrom;
  }
  if (pickupDateTo) {
    params.pickup_date_to = pickupDateTo;
  }
  params.ordering = '-created_at';

  return http.get('/api/v1/core/waybill_old/list/', params).then(
    (response: any) => {
      dispatch(setWaybillsList(response.waybills));
      dispatch(setWaybillsCount(response.count));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const getWaybillsList = (
  query = '',
  page = 1,
  status: any,
  service: any,
  createdAtFrom: any,
  createdAtTo: any,
  pickupDateFrom: any,
  pickupDateTo: any,
  billingDateFrom: any,
  billingDateTo: any,
  restFilters?: anyObject,
) : ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { limit: 25, skip: page * 25 - 25, query: isString(query) ? query : '' };
  if (status && status.length) params.status = status;
  if (service) params.delivery_service = service;
  if (query) params.query = query;
  if (createdAtFrom) params.created_at_from = createdAtFrom;
  if (createdAtTo) params.created_at_to = createdAtTo;
  if (pickupDateFrom) params.pickup_date_from = pickupDateFrom;
  if (pickupDateTo) params.pickup_date_to = pickupDateTo;
  if (billingDateFrom) params.billing_date_from = billingDateFrom;
  if (billingDateTo) params.billing_date_to = billingDateTo;
  if (restFilters?.additionalService) params.additional_service = restFilters.additionalService;
  if (restFilters?.deliveryDateFrom) params.delivery_date_from = restFilters.deliveryDateFrom;
  if (restFilters?.deliveryDateTo) params.delivery_date_to = restFilters.deliveryDateTo;
  if (restFilters?.tariff && restFilters.tariff.length) params.rate_code = restFilters.tariff;
  if (restFilters?.insuranceFrom) params.insurance_from = restFilters.insuranceFrom;
  if (restFilters?.insuranceTo) params.insurance_to = restFilters.insuranceTo;
  if (restFilters?.overdue) params.overdue = restFilters.overdue;


  return http.get('/api/v1/core/waybill/list/', params).then(
    (response: any) => {
      dispatch(setWaybillsList(response.waybills));
      dispatch(setWaybillsCount(response.count));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const getWaybillControlFiltersList = () : ThunkAction => (
  dispatch, getState, http,
) => {
  return http.get('/api/v1/core/waybill_filter/list/').then(
    (response: any) => {
      dispatch(setWaybillFiltersList(response.items));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const addWaybillControlFilter = (
  query = '',
  title: string,
  status: any,
  service: any,
  createdAtFrom: any,
  createdAtTo: any,
  pickupDateFrom: any,
  pickupDateTo: any,
  billingDateFrom: any,
  billingDateTo: any,
  restFilters?: anyObject,
) : ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { title, query: isString(query) ? query : '' };
  if (status && status.length) params.status = status;
  if (service) params.delivery_service = service;
  if (query) params.query = query;
  if (createdAtFrom) params.created_at_from = createdAtFrom;
  if (createdAtTo) params.created_at_to = createdAtTo;
  if (pickupDateFrom) params.pickup_date_from = pickupDateFrom;
  if (pickupDateTo) params.pickup_date_to = pickupDateTo;
  if (billingDateFrom) params.billing_date_from = billingDateFrom;
  if (billingDateTo) params.billing_date_to = billingDateTo;
  if (restFilters?.additionalService) params.additional_service = restFilters.additionalService;
  if (restFilters?.deliveryDateFrom) params.delivery_date_from = restFilters.deliveryDateFrom;
  if (restFilters?.deliveryDateTo) params.delivery_date_to = restFilters.deliveryDateTo;
  if (restFilters?.tariff && restFilters.tariff.length) params.rate_code = restFilters.tariff;
  if (restFilters?.insuranceFrom) params.insurance_from = restFilters.insuranceFrom;
  if (restFilters?.insuranceTo) params.insurance_to = restFilters.insuranceTo;
  if (restFilters?.overdue) params.overdue = restFilters.overdue;

  return http.post(`/api/v1/core/waybill_filter/add/`, params).then(
    (response: any) => {
      const adapted = adaptFromApi(response)
      dispatch(addWaybillFilter(adapted));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const updateWaybillControlFilter = (
  query = '',
  filterId: string,
  title: string,
  status: any,
  service: any,
  createdAtFrom: any,
  createdAtTo: any,
  pickupDateFrom: any,
  pickupDateTo: any,
  billingDateFrom: any,
  billingDateTo: any,
  restFilters?: anyObject,
) : ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { title, query: isString(query) ? query : '' };
  if (status && status.length) params.status = status;
  if (service) params.delivery_service = service;
  if (query) params.query = query;
  if (createdAtFrom) params.created_at_from = createdAtFrom;
  if (createdAtTo) params.created_at_to = createdAtTo;
  if (pickupDateFrom) params.pickup_date_from = pickupDateFrom;
  if (pickupDateTo) params.pickup_date_to = pickupDateTo;
  if (billingDateFrom) params.billing_date_from = billingDateFrom;
  if (billingDateTo) params.billing_date_to = billingDateTo;
  if (restFilters?.additionalService) params.additional_service = restFilters.additionalService;
  if (restFilters?.deliveryDateFrom) params.delivery_date_from = restFilters.deliveryDateFrom;
  if (restFilters?.deliveryDateTo) params.delivery_date_to = restFilters.deliveryDateTo;
  if (restFilters?.tariff && restFilters.tariff.length) params.rate_code = restFilters.tariff;
  if (restFilters?.insuranceFrom) params.insurance_from = restFilters.insuranceFrom;
  if (restFilters?.insuranceTo) params.insurance_to = restFilters.insuranceTo;
  if (restFilters?.overdue) params.overdue = restFilters.overdue;

  return http.patch(`/api/v1/core/waybill_filter/${filterId}/update/`, params).then(
    (response: any) => {
      const adapted = adaptFromApi(response)
      dispatch(updateWaybillFilter(adapted));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const loadControlFilterCounters = () : ThunkAction => (
  dispatch, getState, http,
) => {
  const { controlFilterList } = getState().waybills;
  const params = {
    filter_ids: controlFilterList.map(_ => _.filterId)
  }
  return http.get(`/api/v1/core/waybill_filter/count/`, params).then(
    (response: any) => {
      if (response.items && response.items.length) dispatch(setWaybillFiltersCount(response.items));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const removeWaybillControlFilter = (filterId: string) : ThunkAction => (
  dispatch, getState, http,
) => {
  return http.get(`/api/v1/core/waybill_filter/${filterId}/remove/`).then(
    (response: any) => {
      if (response.status === 'success') {
        dispatch(removeWaybillFilter(filterId));
      }
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const loadWaybill = (waybillId: number | string) : ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => http.get(`/api/v1/core/waybill/${waybillId}/`).then(
  (response: any) => {
    if (response.record?.newSenderContact) {
      try {
        response.record.newSenderContact = adaptFromApi(JSON.parse(response.record.newSenderContact));
      } catch (e) {
        console.error(e);
      }
    }

    if (response.record?.newReceiverContact) {
      try {
        response.record.newReceiverContact = adaptFromApi(JSON.parse(response.record.newReceiverContact));
      } catch (e) {}
    }

    dispatch(setCurrentWaybill(response));
    return Promise.resolve(response);
  },
  (errors: any) => Promise.reject(errors),
);

export const rejectWaybill = (waybillId: number): ThunkAction => (
  dispatch, getState, http,
) => http.put(`/api/v1/core/waybill/${waybillId}/reject/`).then(
  (response: any) => {
    dispatch(loadWaybill(waybillId));
    return Promise.resolve(response);
  },
  (errors: any) => Promise.reject(errors),
);

export const completeWaybill = (waybillId: number | string, date: string): ThunkAction => (
  dispatch, getState, http,
) => http.put(`/api/v1/core/waybill/${waybillId}/complete/`, { date }).then(
  () => dispatch(loadWaybill(waybillId)),
  (errors: any) => Promise.reject(errors),
);

export const problemWaybill = (waybillId: number | string, date: string): ThunkAction => (
  dispatch, getState, http,
) => http.put(`/api/v1/core/waybill/${waybillId}/problem/`, { date }).then(
  () => dispatch(loadWaybill(waybillId)),
  (errors: any) => Promise.reject(errors),
);

export const pickupWaybill = (waybillId: number | string, date: string, status: string): ThunkAction => (
  dispatch, getState, http,
) => http.put(`/api/v1/core/waybill/${waybillId}/pickup/`, { date, status }).then(
  () => dispatch(loadWaybill(waybillId)),
  (errors: any) => Promise.reject(errors),
);

export const pickupWaitingWaybill = (waybillId: number | string, status: string): ThunkAction => (
  dispatch, getState, http,
) => http.put(`/api/v1/core/waybill/${waybillId}/pickup_waiting/`, { status }).then(
  () => dispatch(loadWaybill(waybillId)),
  (errors: any) => Promise.reject(errors),
);

export const loadWaybillTracking = (waybillId: number) : ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => http.get(`/api/v1/tracking/waybill/${waybillId}/`).then(
  (response: any) => {
    dispatch(setCurrentWaybillTracking(response.tracking));
    return Promise.resolve(response);
  },
  (errors: any) => Promise.reject(errors),
);

export const loadWaybillPricing = (waybillId: number | string) : ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => http.get(`/api/v1/pricing/waybill/${waybillId}/`).then(
  (response: any) => {
    dispatch(setCurrentWaybillPricing(response.pricing));
    return Promise.resolve(response);
  },
  (errors: any) => Promise.reject(errors),
);

export const initRateTask = (waybillId: number, cargo: Cargo) : ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => {
  const params = { packages: cargo.packages };

  return http.put(`/api/v1/core/waybill/${waybillId}/conversion/init/`, params).then(
    (response: any) => {
      dispatch(setRateTask(response.taskId));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const loadTaskRates = (waybillId: number, taskId: string) : ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => {
  const params = { task_id: taskId };

  return http.put(`/api/v1/core/waybill/${waybillId}/conversion/result/`, params).then(
    (response: any) => {
      dispatch(setRates(response.rates));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const applyRateTask = (waybillId: number, taskId: string, rateId: string) : ThunkAction<Promise<any>> => (
  dispatch, getState, http,
) => {
  const params = { task_id: taskId, rate_id: rateId };

  return http.put(`/api/v1/core/waybill/${waybillId}/conversion/apply/`, params).then(
    (response: any) => {
      dispatch(resetRates());
      dispatch(resetRateTask());
      dispatch(resetCargo());
      dispatch(loadWaybill(waybillId));
      return Promise.resolve(response);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const loadSurchargeTypes = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/billing/surcharge/type/list/').then(
  (response: {items: Array<any>}) => {
    dispatch(setSurchargeTypes(response.items));
    return Promise.resolve(response);
  },
);

export const createSurcharge = (waybillId: number, surchargeType: string, surchargeCost: number): ThunkAction => (dispatch, getState, http) => {
  const params = { id: waybillId, surcharge_type_id: surchargeType, surcharge_cost: surchargeCost };
  return http.post('/api/v1/billing/surcharge/create/', params).then(
    (response: any) => Promise.resolve(response),
  );
};

export const getWaybillHistory = (plsWaybillNumber: string): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/core/waybill/history/', { pls_number: plsWaybillNumber },
)
  .then(
    (response: any) => {
      dispatch(setCurrentWaybillHistory(response.items));
      return Promise.resolve(response);
    },
  );

export const returnWaybillToWork = (waybillId: number | string): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/core/waybill/${waybillId}/return/`,
).then(
  () => {
    dispatch(loadWaybillPricing(waybillId));
    return dispatch(loadWaybill(waybillId));
  },
);

export const changeWaybillServiceNumbers = (waybillId: string | number, serviceNumber: string, serviceOrderNumber: string): ThunkAction => (
  dispatch, getState, http,
) => http.put(
  `/api/v1/core/waybill/${waybillId}/numbers_change/`,
  {
    service_number: serviceNumber,
    service_order_number: serviceOrderNumber,
  },
).then(
  () => dispatch(loadWaybill(waybillId)),
);

export const updateWaybillManagerComment = (waybillId: string | number, managerComment: string): ThunkAction => (
  dispatch, getState, http,
) => http.put(
  `/api/v1/core/waybill/${waybillId}/comment/update/`,
  { manager_comment: managerComment },
).then(
  () => dispatch(loadWaybill(waybillId)),
);

export const loadInsuranceItems = (filters: InsuranceListParams): ThunkAction => (dispatch, getState, http) => {
  const params: anyObject = {
    limit: 25,
    skip: filters.page * 25 - 25,
  };

  if (filters.query) params.query = filters.query;
  if (filters.createdAtFrom) params.created_at_from = filters.createdAtFrom.format(SERVER_DATE_FORMAT);
  if (filters.createdAtTo) params.created_at_to = filters.createdAtTo.format(SERVER_DATE_FORMAT);
  if (filters.pickupDateFrom) params.pickup_date_from = filters.pickupDateFrom.format(SERVER_DATE_FORMAT);
  if (filters.pickupDateTo) params.pickup_date_to = filters.pickupDateTo.format(SERVER_DATE_FORMAT);
  if (filters.billingDateFrom) params.billing_date_from = filters.billingDateFrom.format(SERVER_DATE_FORMAT);
  if (filters.billingDateTo) params.billing_date_to = filters.billingDateTo.format(SERVER_DATE_FORMAT);
  if (filters.deliveryDateFrom) params.delivery_date_from = filters.deliveryDateFrom.format(SERVER_DATE_FORMAT);
  if (filters.deliveryDateTo) params.delivery_date_to = filters.deliveryDateTo.format(SERVER_DATE_FORMAT);
  if (filters.service) params.delivery_service = filters.service;
  if (filters.status && filters.status.length) params.status = filters.status;
  if (filters.tariff && filters.tariff.length) params.tariff = filters.tariff;

  return http.get('/api/v1/core/insurance/list/', params).then(
    ({ items, count }: { items: InsuranceListItem[], count: number }) => {
      dispatch(setInsuranceItems(items));
      dispatch(setInsuranceItemCount(count));
      return Promise.resolve(items);
    },
    (errors: any) => Promise.reject(errors),
  );
};

export const updateWaybillPickupDate = (waybillId: number | string, data: anyObject): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/core/waybill/${waybillId}/pickup_date/change/`,
  data,
).then(
  () => dispatch(loadWaybill(waybillId)),
);

export const updateWaybillDeliveryDate = (waybillId: number | string, data: anyObject): ThunkAction => (dispatch, getState, http) => http.patch(
  `/api/v1/core/waybill/${waybillId}/delivery_date/change/`,
  data,
).then(
  () => dispatch(loadWaybill(waybillId)),
);

export const lostWaybill = (waybillId: number): ThunkAction => (
  dispatch, getState, http,
) => http.put(`/api/v1/core/waybill/${waybillId}/lost/`).then(
  (response: any) => {
    dispatch(loadWaybill(waybillId));
    return Promise.resolve(response);
  },
);

export const scrapeWaybill = (waybillId: number): ThunkAction => (
  dispatch, getState, http,
) => http.put(`/api/v1/core/waybill/${waybillId}/scrape/`).then(
  (response: any) => {
    dispatch(loadWaybill(waybillId));
    return Promise.resolve(response);
  },
);
