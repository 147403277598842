import 'isomorphic-fetch';
import axios, {
  AxiosInstance, AxiosPromise, AxiosStatic, CancelToken,
} from 'axios';

import * as FormData from 'form-data'; // a nodejs module.
import { serializeObjectToQuery } from './serialize';
import { adaptFromApi } from './adapter';

(global as any).FormData = FormData; // hack for nodejs;
type methodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
interface Options {
  cancelToken?: CancelToken,
  critical?: boolean,
  includeCode?: boolean,
  json?: boolean,
  [key: string]: any
}

export interface Request {
  (
    url: string,
    data?: {
      [key: string]: any
    } | FormData | null | undefined | string,
    options?: Options,
    onUploadProgress?: (progressEvent: any) => void
  ): any
}

export interface SendRequest {
  (method: methodType): Request
}

export type HttpService = {
  get: Request,
  post: Request,
  put: Request,
  patch: Request,
  delete: Request,
  axiosInstance: AxiosInstance
};
export interface CreateHttpService {
  (axiosInstance: AxiosInstance): HttpService
}

function getHeaders(options: Options) {
  const headers: any = {
    // 'X-Requested-With': 'XMLHttpRequest',
    Accept: '*/*',
  };
  let authToken = null;

  if (typeof window !== 'undefined') {
    authToken = localStorage.getItem('accessToken');
    if (authToken && authToken === 'null') {
      authToken = null;
    }
  }
  if (options.FORCE_TOKEN) {
    authToken = options.FORCE_TOKEN;
  }

  if (authToken && !options.NO_AUTH) headers.Authorization = `Bearer ${authToken}`;

  if (options.json) {
    headers['Content-Type'] = 'application/json';
  }
  return headers;
}

const createHttpService: CreateHttpService = (axiosInstance) => {
  const defaultOptions = { json: true };

  const sendRequest: SendRequest = (method: methodType) => (url, data = {}, options: Options = {}, onUploadProgress) => {
    const { cancelToken } = options;
    const newOptions = {
      ...defaultOptions,
      ...options,
    };
    let newUrl = url;
    let newData = data;

    if (method === 'GET') {
      newUrl = `${newUrl}?${serializeObjectToQuery(newData)}`;
      newData = null;
    } else if (newOptions.json && typeof newData === 'object') {
      newData = JSON.stringify(data);
    }

    // В сафари если через формдату шлешь пустой файл, все наебывается
    if (!newOptions.json && newData) {
      try {
        // @ts-ignore
        for (const pair of newData.entries()) {
          if (pair[1] instanceof File && pair[1].name === '' && pair[1].size === 0) {
            // @ts-ignore
            newData.delete(pair[0]);
          }
        }
      } catch (e) {
      }
    }
    const headers = getHeaders(newOptions);
    return axiosInstance({
      url: newUrl,
      method,
      data,
      headers,
      cancelToken,
      onUploadProgress,
    })
      .then(
        (response) => Promise.resolve(adaptFromApi(response.data)),
        (reason) => {
          if (axios.isCancel(reason)) {
            return Promise.reject(reason);
          }
          if (newOptions.critical && reason.response && reason.response.status === 404) {
            throw 'Error404';
          }

          let resp: any = reason.response ? adaptFromApi(reason.response.data) : null;
          if (newOptions.includeCode) {
            resp = [
              resp,
              reason?.response?.status,
            ];
          }
          return Promise.reject(resp);
        },
      );
  };

  return {
    get: sendRequest('GET'),
    post: sendRequest('POST'),
    put: sendRequest('PUT'),
    patch: sendRequest('PATCH'),
    delete: sendRequest('DELETE'),
    axiosInstance,
  };
};

export default createHttpService;
