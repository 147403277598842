import {
  SET_TERMINALS_COUNT,
  SET_TERMINALS_LIST,
  TerminalItem,
  TerminalActions
} from './types';

export interface TerminalState {
  terminalsList: Array<TerminalItem>,
  terminalsCount: number,
}

const initialState: TerminalState = {
  terminalsList: [],
  terminalsCount: 0,
};

export default function (state = initialState, action: TerminalActions): TerminalState {
  switch (action.type) {
    case SET_TERMINALS_LIST:
      return { ...state, terminalsList: action.payload };
    case SET_TERMINALS_COUNT:
      return { ...state, terminalsCount: action.payload };
    default:
      return state;
  }
}
