import axios from 'axios';
import { setToken, signOut } from '../app/users/action-creators';
import createHttpService from './http';

export default function JWTMiddleware() {
  return ({ dispatch, getState }) => (next) => (action) => {
    const { token } = getState().users;
    if ((typeof action !== 'function') || !token || !token.refreshToken) return next(action);

    const refreshThreshold = new Date().getTime();
    if (refreshThreshold >= token.expiredAt) {
      const http = createHttpService(axios.create());
      return http.post('/api/v1/token/refresh/', { refresh_token: token.refreshToken }, { NO_AUTH: true }).then(
        (response) => {
          const { accessToken, refreshToken } = response;
          const expiredAt = new Date().getTime() + 300000;
          dispatch(setToken(accessToken, refreshToken, expiredAt));
          return next(action);
        },
        () => {
          dispatch(signOut());
          return next(action);
        },
      );
    }

    return next(action);
  };
}
