import React from 'react';
import CircularProgress from '../../../components/CircularProgress';

const GeoPolygonDetail = React.lazy(() => import('../components/GeoPolygonDetail'));

function GeoPolygonDetailView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <GeoPolygonDetail {...props} />
    </React.Suspense>
  );
}

export default GeoPolygonDetailView;
