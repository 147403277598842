export const SET_TENDER_RATE_PRIORITY_LIST = 'SET_TENDER_RATE_PRIORITY_LIST';
export const RESET_TENDER_RATE_PRIORITY_LIST = 'RESET_TENDER_RATE_PRIORITY_LIST';
export const REPLACE_TENDER_RATE_PRIORITY = 'REPLACE_TENDER_RATE_PRIORITY';
export const REMOVE_TENDER_RATE_PRIORITY = 'REMOVE_TENDER_RATE_PRIORITY';
export const ADD_TENDER_RATE_PRIORITY = 'ADD_TENDER_RATE_PRIORITY';

export enum TenderRatePriorityConfigurationFieldChoices {
  WEIGHT = 'weight',
  SEND_ISO = 'send_iso',
  SEND_CITY = 'send_city',
  SEND_DISTRICT = 'send_district',
  SEND_REGION = 'send_region',
  REC_ISO = 'rec_iso',
  REC_CITY = 'rec_city',
  REC_DISTRICT = 'rec_district',
  REC_REGION = 'rec_region',
  DELIVERY_SERVICE = 'delivery_service',
  RATE_CODE = 'rate_code'
}

export enum TenderRatePriorityConfigurationConditionChoices {
  EXACT = 'exact',
  GT = 'gt',
  GTE = 'gte',
  LT = 'lt',
  LTE = 'lte'
}

export type TenderRatePriorityConfiguration = {
  field: TenderRatePriorityConfigurationFieldChoices,
  condition: TenderRatePriorityConfigurationConditionChoices,
  value?: number,
  textValue?: string,
};

export type TenderRatePriority = {
  deliveryService: string,
  rateCode: number,
  rateDisplay: string,
  enabled: boolean,
  configurations: TenderRatePriorityConfiguration[],
  tenderRatePriorityId: number,
};

export interface SetTenderRatePriorityList {
  type: typeof SET_TENDER_RATE_PRIORITY_LIST,
  tenderRatePriorityList: Array<TenderRatePriority>
}

export interface ResetTenderRatePriorityList {
  type: typeof RESET_TENDER_RATE_PRIORITY_LIST,
}

export interface ReplaceTenderRatePriority {
  type: typeof REPLACE_TENDER_RATE_PRIORITY,
  tenderRatePriority: TenderRatePriority,
}

export interface RemoveTenderRatePriority {
  type: typeof REMOVE_TENDER_RATE_PRIORITY,
  tenderRatePriorityId: number,
}

export interface AddTenderRatePriority {
  type: typeof ADD_TENDER_RATE_PRIORITY,
  tenderRatePriority: TenderRatePriority,
}

export type TenderActions =
  SetTenderRatePriorityList
  | ResetTenderRatePriorityList
  | ReplaceTenderRatePriority
  | RemoveTenderRatePriority
  | AddTenderRatePriority;
