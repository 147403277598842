import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';


const WaybillNewList = React.lazy(() => import('../components/WaybillList'));
function WaybillListView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <WaybillNewList {...props} />
    </Suspense>
  );
}

export default WaybillListView;
