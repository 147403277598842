import React from 'react';
import moment from 'moment';
import qs from 'query-string';
import { SERVER_DATE_FORMAT } from './constants';

type configValue = { type: string, defaultValue?: string | number | null | string[] };

type configType = {
  [key: string] : configValue
};

export type SetFieldValueFunc<T extends anyObject = {}> = (data: Partial<T>, callback?: (newParams: T) => void) => void;

type withQueryParamsType = (config: configType) => (WrappedComponent: React.ElementType) => any;

const withQueryParams: withQueryParamsType = (config) => (WrappedComponent) => class WithQueryParams extends React.Component {
  constructor(props: any) {
    super(props);
    // @ts-ignore
    const { location: { search } } = this.props;
    let state: anyObject = {};
    if (search && search.length > 1) {
      const rawFilters = qs.parse(search.slice(1));
      state = {
        ...Object.keys(config).reduce((acc: anyObject, name) => {
          if (name in rawFilters) {
            acc[name] = this.normalizeFieldValue(name, rawFilters[name]);
          }
          return acc;
        }, {}),
      };
    }
    state = {
      ...this.generateStateFromConfig(),
      ...state,
    };
    this.state = state;
  }

  normalizeFieldValue = (name: string, value: any) => {
    if (value === null) {
      return null;
    }
    console.log(name, config[name])
    switch (config[name].type) {
      case 'date':
        return moment(value);
      case 'array':
        return Array.isArray(value) ? value : [value];
      case 'integer':
        return parseInt(value, 10);
      case 'pageNumber':
        return parseInt(value, 10);
      case 'string':
      default:
        return value;
    }
  };

  writeFiltersToQueryString = () => {
    const searchParams = new URLSearchParams();
    // @ts-ignore
    const { history } = this.props;
    Object.keys(config).forEach((name) => {
      // @ts-ignore
      this.prepareFieldValue(name, this.state[name], searchParams);
    });
    history.push(`?${searchParams.toString()}`);
  };

  prepareFieldValue = (name: string, value: any, searchParams: any) => {
    if (value === null) {
      return;
    }
    switch (config[name].type) {
      case 'date':
        if (value) searchParams.append(name, value.format(SERVER_DATE_FORMAT));
        return;
      case 'array':
        if (value && value.length) value.forEach((_: string) => searchParams.append(name, _));
        return;
      case 'pageNumber':
        if (value && value > 1) searchParams.append(name, value);
        return;
      case 'integer':
      case 'string':
      default:
        if (value) searchParams.append(name, value);
    }
  };

  setFieldValue: SetFieldValueFunc = (data: anyObject, callback?: (newParams: anyObject) => void) => {
    const newState: anyObject = {};
    Object.entries(data).forEach(([name, value]) => {
      newState[name] = this.normalizeFieldValue(name, value);
    });
    this.setState(newState, () => {
      this.writeFiltersToQueryString();
      if (callback) callback(this.state);
    });
  };

  generateStateFromConfig = () => Object.entries(config).reduce((acc: anyObject, [name, field]) => {
    switch (field.type) {
      case 'date':
        acc[name] = field.defaultValue || null;
        return acc;
      case 'array':
        acc[name] = field.defaultValue || [];
        return acc;
      case 'integer':
        acc[name] = field.defaultValue || null;
        return acc;
      case 'pageNumber':
        acc[name] = field.defaultValue || 1;
        return acc;
      case 'string':
      default:
        acc[name] = field.defaultValue || '';
        return acc;
    }
  }, {});

  render() {
    return <WrappedComponent {...this.props} setFieldValue={this.setFieldValue} params={this.state} />;
  }
};

export default withQueryParams;
