import findIndexByFieldValue from "./find";

type anyObject = {
  [key: string]: any;
};

export default function replaceByFieldValue<T extends anyObject>(arr: Array<T>, key: keyof T | Array<keyof T>, newObject: T): Array<T> {
  const tmp = arr.slice();
  const index = findIndexByFieldValue(arr, key, newObject);
  if (index > -1) tmp[index] = newObject;
  return tmp;
}
