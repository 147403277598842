import React from 'react';
import { List, Row } from 'antd';
import { Col } from 'antd/es/grid';
import { DropboxOutlined, PhoneOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Steps from 'antd/es/steps';
import { Icon, Popover } from 'antd/es';
import { ProcessingEvent, WaybillProcessing } from '../types';
import { RENDER_DATE_FORMAT } from '../../../utils/constants';
import MailIcon from '../../../components/svg/Mail';
import HomeIcon from '../../../components/svg/Home';
import TruckIcon from '../../../components/svg/Truck';
import HomeOutlinedIcon from '../../../components/svg/HomeOutlined';
import TruckOutline from '../../../components/svg/TruckOutline';
import useSelector from '../../../utils/hooks/useSelector';
import Flag from '../../../components/svg/Flag';
import { getWaybillProcessingRateName } from '../helpers';

const processingMap: processingMapItem[] = [
  {
    name: 'Создано',
    icon: <Icon component={MailIcon} />,
  },
  {
    name: 'Плановый сбор',
    icon: <Icon component={TruckOutline} />,
    failIfOlder: 'Фактический сбор',
  },
  {
    name: 'Фактический сбор',
    icon: <Icon component={TruckIcon} />,
  },
  {
    name: 'Плановая доставка',
    icon: <Icon component={HomeOutlinedIcon} />,
    failIfOlder: 'Фактическая доставка',
  },
  {
    name: 'Фактическая доставка',
    icon: <Icon component={HomeIcon} />,
  },
  {
    name: 'Звонок отправителю (ошибка)',
    icon: <PhoneOutlined className="gx-text-danger" />,
    // status: 'error',
  },
  {
    name: 'Звонок отправителю (успешно)',
    icon: <PhoneOutlined />,
  },
  {
    name: 'Звонок получателю (ошибка)',
    icon: <PhoneOutlined className="gx-text-danger" />,
    // status: 'error',
  },
  {
    name: 'Звонок получателю (успешно)',
    icon: <PhoneOutlined />,
  },
  {
    name: 'Максимальный срок доставки',
    icon: <Icon component={Flag} />,
  },
];

const EventDescription = ({ events, item }: {events: ProcessingEvent[], item: WaybillProcessing}) => {
  const tmp = events.map((_) => {
    const predifined = processingMap.find((__) => __.name === _.eventName);
    let fail = false;
    if (predifined?.failIfOlder) {
      const _tmp = item.events.find((__) => __.eventName === predifined.failIfOlder);
      if (_tmp && !_tmp.date.isSame(_.date, 'day') && _tmp.date.isAfter(_.date, 'days')) fail = true;
      if (fail) console.debug(_tmp, _);
    }

    return (
      <Popover
        destroyTooltipOnHide
        content={(
          <span>
            {_.eventName}
          </span>
            )}
      >
        <span className={`gx-fs-xl ${fail ? 'gx-text-danger' : ''}`}>
          {predifined?.icon || <QuestionCircleOutlined />}
        </span>
      </Popover>
    );
  });
  return <span>{tmp}</span>;
};


interface WaybillProcessingListItemProps {
  item: WaybillProcessing
}

type StepStatus = "error" | "finish" | "wait" | "process" | undefined

type StepItem = {
  icon: React.ReactNode,
  status: StepStatus,
  title: string,
  popover: string,
}

type processingMapItem = {
  name: string,
  icon: React.ReactNode,
  status?: StepStatus,
  failIfOlder?: string,
}
const orderByText = ['Звонок отправителю (ошибка)', 'Звонок отправителю (успешно)', 'Фактическая доставка', 'Плановая доставка'];

function WaybillProcessingListItem({ item }: WaybillProcessingListItemProps) {
  const multipleServices = useSelector((state) => state.users.user?.multipleServices);
  const { deliveryServices } = useSelector((state) => state.reference);
  // const steps = React.useMemo(() => {
  //   const _steps: StepItem[] = [];
  //   item.events.sort((a, b) => {
  //     if (orderByText.includes(a.eventName) && orderByText.includes(b.eventName)) {
  //       if (a.eventName < b.eventName) return -1;
  //       if (a.eventName > b.eventName) return 1;
  //     }
  //     return 0;
  //   }).sort((a, b) => {
  //     if (a.date.isBefore(b.date)) return -1;
  //     if (a.date.isAfter(b.date)) return 1;
  //     return 0;
  //   }).forEach((_) => {
  //     const predifined = processingMap.find((__) => __.name === _.eventName);
  //     let status: StepStatus = 'finish';
  //     if (predifined?.failIfOlder) {
  //       const tmp = item.events.find((__) => __.eventName === predifined.failIfOlder);
  //       if (tmp && tmp.date.isAfter(_.date)) status = 'error';
  //     }
  //     _steps.push({
  //       status: predifined?.status || status,
  //       title: _.date.format(RENDER_DATE_FORMAT),
  //       icon: predifined?.icon || <QuestionCircleOutlined/>,
  //       popover: `${_.eventName}`,
  //     });
  //   });
  //   return _steps;
  // }, [item]);
  //
  //
  // const customDot = React.useCallback(
  //   (dot, {index}) => (
  //     <Popover
  //       content={(
  //         <span>
  //           {steps[index].popover}
  //         </span>
  //       )}
  //     >
  //       {steps[index].icon}
  //     </Popover>
  //   ),
  //   [steps],
  // );


  const grouped = React.useMemo(() => {
    const tmp: anyObject = {};

    item.events.forEach((_) => {
      const key = _.date.format(RENDER_DATE_FORMAT);
      if (tmp[key]) tmp[key].push(_);
      else tmp[key] = [_];
    });
    return tmp;
  }, [item]);

  const getDescription = React.useCallback(
    (events: ProcessingEvent[], debug?: boolean) => {
      const tmp = events.map((_) => {
        const predifined = processingMap.find((__) => __.name === _.eventName);
        let fail = false;
        if (predifined?.failIfOlder) {
          const _tmp = item.events.find((__) => __.eventName === predifined.failIfOlder);
          if (_tmp && !_tmp.date.isSame(_.date, 'day') && _tmp.date.isAfter(_.date, 'days')) fail = true;
          if (fail) console.debug(_tmp, _);
        }

        return (
          <Popover
            destroyTooltipOnHide
            content={(
              <span>
                {_.eventName}
              </span>
            )}
          >
            <span className={`gx-fs-xl ${fail ? 'gx-text-danger' : ''}`}>
              {predifined?.icon || <QuestionCircleOutlined />}
            </span>
          </Popover>
        );
      });
      return tmp;
    },
    [],
  );

  return (
    <List.Item>
      <Row className="gx-w-100">
        <Col xs={24} md={8}>
          {item.serviceWaybillNumber}
          <br />
          {item.serviceOrderNumber}
        </Col>

        <Col xs={24} md={12}>
          {multipleServices
          && <img src={deliveryServices[item.deliveryService]?.icon} alt={item.deliveryService} className="delivery_service-logo gx-mr-3" />}
          {getWaybillProcessingRateName(item)}
        </Col>

        <Col xs={24}>
          <Steps progressDot>
            {Object.keys(grouped).map((_) => (
              <Steps.Step
                title={_}
                status="finish"
                description={<EventDescription events={grouped[_]} item={item} />}
              />
            ))}
          </Steps>
        </Col>
      </Row>
    </List.Item>

  );
}

export default WaybillProcessingListItem;
