import moment from "moment";

export const RENDER_DATE_FORMAT = 'DD.MM.YYYY';
export const USER_DATE_FORMAT = RENDER_DATE_FORMAT;
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const MAX_FILE_UPLOAD_SIZE = 20 * 1024 * 1024;
export const CLIENT_TIME_FORMAT = 'HH:mm';
export const EXPANDED_CLIENT_DATE_FORMAT = 'DD MMMM YYYY';
export const LG_SCREEN_WIDTH = 992;

export const WAYBILL_STATUSES: {[key: string]: any} = {
  new: {
    title: 'Новый',
    status: 'Заказ создан',
    color: 'blue',
  },
  registered: {
    title: 'Зарегистрированный',
    status: 'Заказ создан',
    color: 'gray',
  },
  pickup_waiting: {
    title: 'Ожидает сбора',
    status: ({ pickupDate }: any) => `Заказ создан${pickupDate ? `. Ожидаемая дата сбора ${moment(pickupDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'blue',
  },
  delivery_waiting: {
    title: 'Груз в пути',
    status: ({ deliveryDate }: any) => `Груз в пути${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  courier_search: {
    title: 'Ищем курьера',
    status: ({ pickupDate }: any) => `Ищем курьера${pickupDate ? `. Ожидаемая дата сбора ${moment(pickupDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'blue',
  },
  courier_on_way: {
    title: 'Курьер едет к отправителю',
    status: ({ pickupDate }: any) => `Курьер едет к отправителю${pickupDate ? `. Ожидаемая дата сбора ${moment(pickupDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'blue',
  },
  received_from_sender: {
    title: 'Принят у отправителя',
    status: ({ deliveryDate }: any) => `Принят у отправителя${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  ready_to_send: {
    title: 'Готово к отправке',
    status: ({ deliveryDate }: any) => `Готово к отправке${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  sent_to_receiver_office: {
    title: 'Отправлено в офис получателя',
    status: ({ deliveryDate }: any) => `Отправлено в офис получателя${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  met_in_receiver_office: {
    title: 'Встретили в офисе получателя',
    status: ({ deliveryDate }: any) => `Встретили в офисе получателя${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  received_in_delivery_office: {
    title: 'Принято в офисе доставки',
    status: ({ deliveryDate }: any) => `Принято в офисе доставки${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  courier_delivery: {
    title: 'Доставляет курьер',
    status: ({ deliveryDate }: any) => `Доставляет курьер${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  receiver_waiting_in_office: {
    title: 'Ожидаем получателя в офисе',
    status: ({ deliveryDate }: any) => `Ожидаем получателя в офисе${deliveryDate ? `. Ожидаемая дата доставки ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  delivered: {
    title: 'Выполненный',
    status: ({ deliveryDate }: any) => `Заказ выполнен${deliveryDate ? ` ${moment(deliveryDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'green',
  },
  canceled: {
    title: 'Отмененный',
    status: ({ cancelDate }: any) => `Заказ отменен${cancelDate ? ` ${moment(cancelDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
  lost: {
    title: 'Утерян',
    status: ({ cancel_date }: any) => `Заказ утерян${cancel_date ? ` ${moment(cancel_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
  scrapped: {
    title: 'Утилизирован',
    status: ({ cancel_date }: any) => `Заказ утилизирован${cancel_date ? ` ${moment(cancel_date).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
  problem: {
    title: 'Проблема',
    status: ({ problemDate }: any) => `Проблема в процессе доставки${problemDate ? ` ${moment(problemDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'yellow2',
  },
  canceled_negotiation: {
    title: 'Согласование отмены',
    status: ({ canceledNegotiationDate }: any) => `Согласование отмены${canceledNegotiationDate ? ` ${moment(canceledNegotiationDate).format(USER_DATE_FORMAT)}` : ''}`,
    color: 'red',
  },
};

export const conditionChoices: {[key: string]: string} = {
  exact: 'Равно',
  gt: 'Больше',
  gte: 'Больше или равно',
  lt: 'Меньше',
  lte: 'Меньше или равно',
};

export const COMMON_EXCLUDED_FOR_MANAGER = [
  '/rates', 
  '/rates/marketing',
  '/operator/keys',
  '/operator/scheduler',
  '/tender/rates',
  '/rates/cart/marketing',
  '/operator/geo',
  '/service/apiship',
  '/fake_service',
  '/rates_cashback',
  '/additional_service',
  '/report',
  '/processing',
  '/dashboard/cdek',
  '/dashboard/cse',
  '/statistic/chat',
  '/collision_report/summary',
  '/collision_report/cdek',
  '/collision_report/cse',
  '/phones',
  '/terminals',
  '/dialog_reviews'
];