import {
  ForeignInvoice,
  ForeignInvoiceActions,
  ForeignInvoiceDetail,
  RESET_CURRENT_FOREIGN_INVOICE,
  SET_FOREIGN_INVOICE_COUNT,
  SET_FOREIGN_INVOICE_LIST,
  SET_CURRENT_FOREIGN_INVOICE,
} from './types';

type ForeignInvoiceState = {
  foreignInvoiceList: ForeignInvoice[],
  foreignInvoiceCount: number,
  currentForeignInvoice: ForeignInvoiceDetail | null,
}

const initialState: ForeignInvoiceState = {
  foreignInvoiceList: [],
  foreignInvoiceCount: 0,
  currentForeignInvoice: null,
};

export default function (state = initialState, action: ForeignInvoiceActions): ForeignInvoiceState {
  switch (action.type) {
    case SET_FOREIGN_INVOICE_LIST:
      return { ...state, foreignInvoiceList: action.payload };
    case SET_FOREIGN_INVOICE_COUNT:
      return { ...state, foreignInvoiceCount: action.payload };
    case SET_CURRENT_FOREIGN_INVOICE:
      return { ...state, currentForeignInvoice: action.payload };
    case RESET_CURRENT_FOREIGN_INVOICE:
      return { ...state, currentForeignInvoice: initialState.currentForeignInvoice };
    default: return state;
  }
}
