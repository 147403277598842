import { GlobalCounters, SET_GLOBAL_COUNTERS, SetGlobalCounters } from './types';
import { ThunkAction } from '../../utils/types';

export const setGlobalCounters = (counters: GlobalCounters): SetGlobalCounters => ({
  type: SET_GLOBAL_COUNTERS,
  counters,
});

export const loadGlobalCounters = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/core/counters/').then(
  (response: GlobalCounters) => {
    dispatch(setGlobalCounters(response));
    return Promise.resolve(response);
  },
);
