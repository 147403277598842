import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const RateCashbackList = React.lazy(() => import('../components/RateCashbackList'));
function RateCashbackListView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <RateCashbackList {...props} />
    </Suspense>
  );
}

export default RateCashbackListView;
