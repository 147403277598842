import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const FakeServiceDetail = React.lazy(() => import('../components/FakeServiceDetail'));
function FakeServiceDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <FakeServiceDetail {...props} />
    </Suspense>
  );
}

export default FakeServiceDetailView;
