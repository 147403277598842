import {
  CargoCast, CargoCastAdditionalServices, ContactCast, RateCast, TerminalCast,
} from '../generic_types';
import { Waybill } from '../waybills/types';

export const SET_CLAIM_LIST = 'SET_CLAIM_LIST';
export const SET_CLAIM_COUNT = 'SET_CLAIM_COUNT';
export const SET_CURRENT_CLAIM = 'SET_CURRENT_CLAIM';
export const RESET_CURRENT_CLAIM = 'RESET_CURRENT_CLAIM';

export enum ClaimMethod {
  SIMPLE=1,
  TO_TERMINAL=2,
  FOREIGN=3,
  ONLINE_STORE=4
}

export const ClaimState = ['new', 'accept', 'complete', 'dismiss'];
export const ClaimProblemType = ['calculation', 'creation'];

export type ClaimAccountDetail = {
  accountId: number,
  accountTitle: string,
};

export type Claim = {
  claimId: number,
  method: ClaimMethod,
  state: typeof ClaimState[number],
  number: string,
  accountId: number,
  createdAt: string,
  problemType: typeof ClaimProblemType[number],
  comment: string | null,

  senderContactId: string,
  receiverContactId: string,
  cargoId: string,

  rateResultId: string,
  receiverTerminalId: number,
  senderTerminalId: number,
  pickupAt: string,
  account: null | ClaimAccountDetail,
  waybill: null | Waybill,
  linkedClaimList: string[],
  deliveryService?: string,
  senderTerminal: null | TerminalCast,
  receiverTerminal: null | TerminalCast,
  estimatedProcessingDate?: string
};

export type ClaimDetail = Claim & {
  cargo: CargoCast,
  senderContact: null | ContactCast,
  receiverContact: null | ContactCast,
  rateResult: null | RateCast,
  senderHiddenPhone: string,
  receiverHiddenPhone: string,
  senderHiddenPhoneList: string[],
  receiverHiddenPhoneList: string[],
  encryptSender: boolean,
  encryptReceiver: boolean,
  pickupPeriod: string,
  additionalServices?: CargoCastAdditionalServices[],
};

export interface SetClaimList {
  type: typeof SET_CLAIM_LIST,
  list: Claim[]
}

export interface SetClaimCount {
  type: typeof SET_CLAIM_COUNT,
  count: number,
}

export interface SetCurrentClaim {
  type: typeof SET_CURRENT_CLAIM,
  claim: ClaimDetail,
}

export interface ResetCurrentClaim {
  type: typeof RESET_CURRENT_CLAIM,
}

export type claimActions = SetClaimList | SetClaimCount | SetCurrentClaim | ResetCurrentClaim;
