import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withQueryParams } from 'helpers';
import List from 'components/List';
import AccountTable from '../components/AccountTable';
import {
  loadAccounts,
} from '../action-creators';

const QUERY_PARAMS_CONFIG = {
  query: { type: 'string' },
  page: { type: 'pageNumber' },
};

class AccountView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.loadAccounts();
  }

  setLoading = (loading) => this.setState({ loading });

  loadAccounts = () => {
    const {
      params: {
        query, page
      },
    } = this.props;
    this.setLoading(true);
    this.props.loadAccounts(
      query,
      page,
    ).finally(
      () => { this.setLoading(false); },
    );
  };

  handlePageChange = (page) => this.props.setFieldValue({ page }, this.loadAccounts);

  handleSearchChange = (query) => this.props.setFieldValue({ query, page: 1 }, this.loadAccounts);

  render() {
    const { params: { query, page }, accountsCount, user } = this.props;
    const { loading } = this.state;
    return (
      <List
        title="Клиенты"
        totalCount={accountsCount}
        currentPage={page}
        handlePageChange={this.handlePageChange}
        searchValue={query}
        searchPlaceholder="Введите название компании"
        onSearchChange={this.handleSearchChange}
        loading={loading}
      >
        <AccountTable loading={loading} />
      </List>
    );
  }
}

export default connect(
  ({ accounts: { accountsCount }, users: { user } }) => ({ accountsCount, user }),
  { loadAccounts },
)(withQueryParams(QUERY_PARAMS_CONFIG)(AccountView));
