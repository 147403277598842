import React from 'react';
import CircularProgress from '../../../components/CircularProgress';

const GeoPolygonList = React.lazy(() => import('../components/GeoPolygonList'));

function GeoPolygonListView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <GeoPolygonList {...props} />
    </React.Suspense>
  );
}

export default GeoPolygonListView;
