export const SET_ADDITIONAL_SERVICES_CONFIGURATION_LIST = 'SET_ADDITIONAL_SERVICES_CONFIGURATION_LIST';
export const SET_ADDITIONAL_SERVICES_CONFIGURATION_COUNT = 'SET_ADDITIONAL_SERVICES_CONFIGURATION_COUNT';
export const SET_CURRENT_ADDITIONAL_SERVICES_CONFIGURATION = 'SET_CURRENT_ADDITIONAL_SERVICES_CONFIGURATION';

export const SET_ADDITIONAL_SERVICES_SETTING_LIST = 'SET_ADDITIONAL_SERVICES_SETTING_LIST';
export const SET_CURRENT_ADDITIONAL_SERVICES_SETTING = 'SET_CURRENT_ADDITIONAL_SERVICES_SETTING';

export const RESET_ADDITIONAL_SERVICE = 'RESET_ADDITIONAL_SERVICE';

export interface AdditionalServiceSetting {
  id?: number,
  field?: string,
  condition?: string,
  value: number,
  price: number,
}

export interface AdditionalServiceConfiguration {
  id: number,
  title: string,
  description: string,
  key: string,
  deliveryService: string[],
}

export interface AutoWeightSettings {
  diffWeight?: number,
  costWeight?: number,
  toWeight?: number,
  fromWeight?: number,
  startCost?: number
}


export interface SetAdditionalServicesConfigurationList {
  type: typeof SET_ADDITIONAL_SERVICES_CONFIGURATION_LIST,
  payload: Array<AdditionalServiceConfiguration>
}

export interface SetAdditionalServicesConfigurationCount {
  type: typeof SET_ADDITIONAL_SERVICES_CONFIGURATION_COUNT,
  payload: number
}

export interface SetAdditionalServicesSettingList {
  type: typeof SET_ADDITIONAL_SERVICES_SETTING_LIST,
  payload: Array<AdditionalServiceSetting>
}

export interface SetCurrentAdditionalServiceConfiguration {
  type: typeof SET_CURRENT_ADDITIONAL_SERVICES_CONFIGURATION,
  payload: AdditionalServiceConfiguration
}

export interface ResetAdditionalService {
  type: typeof RESET_ADDITIONAL_SERVICE
}

export type AdditionalServiceAction = 
  SetAdditionalServicesConfigurationList | 
  SetAdditionalServicesConfigurationCount | 
  SetAdditionalServicesSettingList |
  SetCurrentAdditionalServiceConfiguration |
  ResetAdditionalService;
