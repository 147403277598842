import React, { useEffect, useRef, useState } from 'react';
import {
  Row, Col, Popover, Collapse, Tag, Tooltip, Button,
} from 'antd';
import { useLocation } from 'react-router-dom'
import Icon from 'antd/es/icon';
import SearchInput from './SearchInput';
import { CONTROL_360_PRESETS } from '../../../app/waybills/constants';
import classNames from 'classnames';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';

const SIMULATED_FIELD_ID = 'simulatedField';
const REAL_FIELD_ID = 'searchField';

const TooltipWrapper = ({
  tooltip,
  children
}) => {
  if (tooltip) {
    return <Tooltip title={tooltip}>{children}</Tooltip>;
  }

  return children;
};

export default function SearchBox({
  searchValue,
  searchPlaceholder,
  onSearchChange,

  filters,
  tags,
  withFavorites,
  handleSaveFilter = () => {},
  handleCancelEdit = () => {},
  filterEditId = ''
}) {

  const currentLocation = useLocation();

  const popoverBtnRef = useRef(null);
  const tagsInputRef = useRef(null);

  const [isSearchOpened, setIsSearchOpened] = useState(false);

  useClickOutside(tagsInputRef, () => {
    setIsSearchOpened(false)
  })

  const handleSimulatedFieldClick = (e) => {
    if (!isSearchOpened) setIsSearchOpened(true);
    if (e.target.id === SIMULATED_FIELD_ID) {
      document.getElementById(REAL_FIELD_ID)
        .focus();
    }
  };

  useEffect(() => {
    if (filterEditId && popoverBtnRef && popoverBtnRef.current && !popoverBtnRef.current.classList.contains('ant-popover-open')) {
      popoverBtnRef.current.click();
    }
  }, [filterEditId])

  const elementStyles = {
    mainInput: {},
    filtersButton: {},
    favoriesButton: {},
  };
  if (filters && filters.length && withFavorites) {
    elementStyles.mainInput = { width: 'calc(100% - 94px)' };
    elementStyles.filtersButton = { right: '48px' };
    elementStyles.favoriesButton = { right: '0' };
  } else if (filters && filters.length) {
    elementStyles.mainInput = { width: 'calc(100% - 47px)' };
    elementStyles.filtersButton = { right: '0' };
  } else {
    elementStyles.mainInput = { width: '100%' };
    elementStyles.filtersButton = { right: '0' };
  }

  return (
    <div className="gx-search-bar">
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          <span
            style={{ marginBottom: '20px' }}
            className="ant-input-search-enter-button ant-input-search-large ant-input-group-wrapper ant-input-group-wrapper-lg"
          >
            <span
              className="ant-input-wrapper ant-input-group ant-input-group-compact"
              style={{
                position: 'relative',
                width: '100%',
              }}
            >
              <div
                style={elementStyles.mainInput}
                className={classNames("ant-input ant-input-lg search-input-double-button simulated-search-field", {"is-filters": filters && filters.length && !isSearchOpened})}
                onClick={handleSimulatedFieldClick}
                id={SIMULATED_FIELD_ID}
                ref={tagsInputRef}
              >
                {!!tags && !!tags.length && tags.map((tag, tagKey) => (
                  <Tag
                    key={tagKey}
                    onClose={tag.onClose}
                    closable
                    className="ant-select-selection__choice simulated-search-field__tag"
                  >
                    <TooltipWrapper tooltip={tag.tooltip}>
                      <span>{tag.content}</span>
                    </TooltipWrapper>
                  </Tag>
                ))}
                <div className="simulated-search-field__container">
                  <SearchInput
                    inputId={REAL_FIELD_ID}
                    value={searchValue}
                    placeholder={searchPlaceholder}
                    onChange={onSearchChange}
                  />
                </div>
              </div>
              {!!filters && !!filters.length && (
                <Popover
                  placement="bottomRight"
                  content={(
                    <>
                      <Collapse style={{
                        margin: '-12px -16px',
                        width: '320px'
                      }} accordion>
                        {filters.map(({
                          title,
                          Component,
                          value,
                          onChange,
                          componentProps,
                        }, key) => {
                          return (
                            <Collapse.Panel header={title} key={key}>
                                <Component {...componentProps} value={value} onChange={onChange}/>
                            </Collapse.Panel>
                          );
                        })}
                      </Collapse>

                      {
                        currentLocation.pathname.indexOf("control_360") > -1
                        &&
                        <div className="gx-d-flex gx-flex-column gx-w-100">
                          <Button 
                            type="primary" 
                            className="gx-mt-2 gx-mb-0 gx-w-100"
                            onClick={handleSaveFilter}>{ filterEditId ? "Изменить фильтр" : "Сохранить фильтр" }</Button>
                            {
                              filterEditId
                              ?
                              <Button 
                                type="danger" 
                                className="gx-mt-2 gx-mb-0 gx-w-100"
                                onClick={handleCancelEdit}>Отменить редактирование</Button>
                              :
                              null
                            }
                        </div>
                        ||
                        null
                      }
                    </>
                  )}
                  open={filterEditId}
                  trigger="click"
                >
                  <button
                    type="button"
                    style={elementStyles.filtersButton}
                    className="ant-btn ant-input-search-button ant-btn-default ant-btn-lg search-box__button"
                    ref={(el) => popoverBtnRef.current = el}
                  >
                    <Icon type="filter" theme="twoTone" style={{ color: '#fadb14' }} twoToneColor="#fa8c16"/>
                  </button>
                </Popover>
              )}
              {withFavorites && (
                <button
                  type="button"
                  style={elementStyles.favoriesButton}
                  className="ant-btn ant-input-search-button ant-btn-default ant-btn-lg search-box__button"
                >
                  <Icon type="star" theme="twoTone" style={{ color: '#fadb14' }} twoToneColor="#fa8c16"/>
                </button>
              )}
            </span>
          </span>
        </Col>
      </Row>
    </div>
  );
}
