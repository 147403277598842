import UIFx from 'uifx';
import { CounterActions, GlobalCounters, SET_GLOBAL_COUNTERS } from './types';
// @ts-ignore
import notificationSound from '../../assets/notification.mp3';

const notification = new UIFx(notificationSound);

export interface CounterState {
  globalCounters: GlobalCounters
}

const initialState: CounterState = {
  globalCounters: {
    activeDialogs: 0,
    activeClaims: 0,
    activeForeignInvoices: 0,
  },
};

const processCounters = (currentCounters: GlobalCounters, newCounters: GlobalCounters): GlobalCounters => {
  if (newCounters.activeDialogs > currentCounters.activeDialogs 
    || newCounters.activeClaims > currentCounters.activeClaims
    || newCounters.activeForeignInvoices > currentCounters.activeForeignInvoices) {
    notification.play();
  }

  return newCounters;
};

export default function (state = initialState, action: CounterActions): CounterState {
  switch (action.type) {
    case SET_GLOBAL_COUNTERS:
      return { ...state, globalCounters: processCounters(state.globalCounters, action.counters) };
    default:
      return state;
  }
}
