import moment from 'moment';
import {
  TrackingStatus,
  TrackingReport,
  ReportAction,
  SET_WAYBILL_PROCESSING_LIST,
  WaybillProcessing,
  SET_WAYBILL_PROCESSING_COUNT,
  TrackingReportItem,
  SET_TRACKING_REPORT_ITEM_LIST,
  RESET_TRACKING_REPORT_ITEM_LIST,
  SET_TRACKING_REPORT_ITEM_COUNT,
  SET_TRACKING_REPORT_COUNT,
  SET_TRACKING_REPORT_LIST,
  SET_TRACKING_STATUSES_LIST,
  CollisionReportItem,
  SET_COLLISION_REPORT_ITEM_LIST,
} from './types';

export interface ReportState {
  trackingStatuses: Array<TrackingStatus>,
  reportList: Array<TrackingReport>,
  reportCount: number,
  waybillProcessingList: WaybillProcessing[],
  waybillProcessingCount: number,
  trackingReportItemList: TrackingReportItem[],
  trackingReportItemCount: 0,
  collisionReportItems: CollisionReportItem[],
}

const initialState: ReportState = {
  trackingStatuses: [],
  reportList: [],
  reportCount: 0,
  waybillProcessingList: [],
  waybillProcessingCount: 0,
  trackingReportItemList: [],
  trackingReportItemCount: 0,
  collisionReportItems: [],
};

const orderByText = ['Звонок отправителю (ошибка)', 'Звонок отправителю (успешно)', 'Фактическая доставка', 'Плановая доставка', 'Звонок получателю (ошибка)', 'Звонок получателю (успешно)'];

const prepareWaybillProcessing = (orig: WaybillProcessing): WaybillProcessing => {
  const copy = { ...orig };
  copy.beginDate = moment(copy.beginDate);
  copy.endDate = moment(copy.endDate);
  copy.events = copy.events.map((_) => ({ ..._, date: moment(_.date) })).sort((a, b) => {
    if (orderByText.includes(a.eventName) && orderByText.includes(b.eventName)) {
      if (a.eventName < b.eventName) return -1;
      if (a.eventName > b.eventName) return 1;
    }
    return 0;
  }).sort((a, b) => {
    if (a.date.isBefore(b.date)) return -1;
    if (a.date.isAfter(b.date)) return 1;
    return 0;
  });
  return copy;
};

const prepareWaybillProcessingList = (list: WaybillProcessing[]): WaybillProcessing[] => {
  const copy = list.map((_) => prepareWaybillProcessing(_));
  return copy;
};

export default function (state = initialState, action: any | ReportAction): ReportState {
  switch (action.type) {
    case SET_TRACKING_STATUSES_LIST:
      return { ...state, trackingStatuses: action.statuses };
    case SET_TRACKING_REPORT_LIST:
      return { ...state, reportList: action.reportList };
    case SET_TRACKING_REPORT_COUNT:
      return { ...state, reportCount: action.count };
    case SET_WAYBILL_PROCESSING_LIST:
      return { ...state, waybillProcessingList: prepareWaybillProcessingList(action.list) };
    case SET_WAYBILL_PROCESSING_COUNT:
      return { ...state, waybillProcessingCount: action.count };
    case SET_TRACKING_REPORT_ITEM_LIST:
      return { ...state, trackingReportItemList: action.list };
    case RESET_TRACKING_REPORT_ITEM_LIST:
      return { ...state, trackingReportItemList: initialState.trackingReportItemList };
    case SET_TRACKING_REPORT_ITEM_COUNT:
      return { ...state, trackingReportItemCount: action.count };
    case SET_COLLISION_REPORT_ITEM_LIST:
      return { ...state, collisionReportItems: action.list };
    default:
      return state;
  }
}
