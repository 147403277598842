import React from 'react';
import CircularProgress from '../../../components/CircularProgress';

const InsuranceList = React.lazy(() => import('../components/InsuranceList'));
function InsuranceListView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <InsuranceList {...props} />
    </React.Suspense>
  );
}

export default InsuranceListView;
