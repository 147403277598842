import {
  ForeignInvoice,
  ForeignInvoiceDetail,
  RESET_CURRENT_FOREIGN_INVOICE,
  ResetCurrentForeignInvoice,
  SET_FOREIGN_INVOICE_COUNT,
  SET_FOREIGN_INVOICE_LIST,
  SET_CURRENT_FOREIGN_INVOICE,
  SetForeignInvoiceCount,
  SetForeignInvoiceList,
  SetCurrentForeignInvoice,
} from './types';
import { ThunkAction } from '../../utils/types';

export const setForeignInvoiceList = (payload: ForeignInvoice[]): SetForeignInvoiceList => ({
  type: SET_FOREIGN_INVOICE_LIST,
  payload,
});

export const setForeignInvoiceCount = (payload: number): SetForeignInvoiceCount => ({
  type: SET_FOREIGN_INVOICE_COUNT,
  payload,
});

export const setCurrentForeignInvoice = (payload: ForeignInvoiceDetail): SetCurrentForeignInvoice => ({
  type: SET_CURRENT_FOREIGN_INVOICE,
  payload,
});

export const resetCurrentForeignInvoice = (): ResetCurrentForeignInvoice => ({
  type: RESET_CURRENT_FOREIGN_INVOICE,
});

export const loadForeignInvoiceList = (page = 1, filters: anyObject = {}): ThunkAction => (dispatch, getState, http) => {
  const params: anyObject = {
    page,
  };

  const {
    query, status, deliveryService, createdAtFrom, 
    createdAtTo, senderIso, receiverIso, cargoType
  } = filters;

  if (query) params.query = query;
  if (status && status.length) params.status = status;
  if (deliveryService && deliveryService.length) params.delivery_service = deliveryService;
  if (createdAtFrom) params.created_at_from = createdAtFrom;
  if (createdAtTo) params.created_at_to = createdAtTo;
  if (senderIso) params.sender_iso = createdAtFrom;
  if (receiverIso) params.receiver_iso = createdAtTo;
  if (cargoType) params.cargo_type = cargoType;
  return http.get(
    '/api/v1/shipping/foreign_invoice/list/',
    params,
  ).then(
    ({ items, count }: { items: ForeignInvoice[], count: number}) => {
      dispatch(setForeignInvoiceList(items));
      dispatch(setForeignInvoiceCount(count));
      return Promise.resolve();
    },
  );
};

export const loadForeignInvoiceDetail = (foreignInvoiceId: string | number): ThunkAction => (dispatch, getState, http) => http.get(
  `/api/v1/shipping/foreign_invoice/${foreignInvoiceId}/detail/`,
).then(
  (response: ForeignInvoiceDetail) => {
    dispatch(setCurrentForeignInvoice(response));
    return Promise.resolve(response);
  },
);

export const approveForeignInvoice = (foreignInvoiceId: string | number): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/foreign_invoice/${foreignInvoiceId}/approve/`
).then(
  () => {
    dispatch(loadForeignInvoiceDetail(foreignInvoiceId));
    return Promise.resolve();
  },
);

export const dismissForeignInvoice = (foreignInvoiceId: string | number): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/foreign_invoice/${foreignInvoiceId}/dismiss/`,
).then(
  () => {
    dispatch(loadForeignInvoiceDetail(foreignInvoiceId));
    return Promise.resolve();
  },
);

export const waitForeignInvoice = (foreignInvoiceId: string | number): ThunkAction => (dispatch, getState, http) => http.put(
  `/api/v1/shipping/foreign_invoice/${foreignInvoiceId}/wait/`,
).then(
  () => {
    dispatch(loadForeignInvoiceDetail(foreignInvoiceId));
    return Promise.resolve();
  },
);