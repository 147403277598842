export const SET_RATES_LIST = 'SET_RATES_LIST';
export const SET_RATES_COUNT = 'SET_RATES_COUNT';
export const SET_RATE_GROUPS_LIST = 'SET_RATE_GROUPS_LIST';
export const SET_RATE_GROUPS_COUNT = 'SET_RATE_GROUPS_COUNT';
export const SET_CURRENT_RATE_GROUP = 'SET_CURRENT_RATE_GROUP';
export const SET_CURRENT_GROUP_MARKETING_RULES = 'SET_CURRENT_GROUP_MARKETING_RULES';
export const SET_CURRENT_RATE = 'SET_CURRENT_RATE';
export const SET_CURRENT_RATE_CONFIGURATIONS = 'SET_CURRENT_RATE_CONFIGURATIONS';
export const SET_RATE_MARKETING = 'SET_RATE_MARKETING';

export const SET_CART_MARKETING_LIST = 'SET_CART_MARKETING_LIST';
export const REPLACE_CART_MARKETING = 'REPLACE_CART_MARKETING';

export enum RateSpeedChoices {
  DEFAULT = 'DEFAULT',
  DAILY = 'DAILY',
  BY_TIME = 'BY_TIME'
}

export interface Rate {
  code: string,
  deliveryService: string,
  title: string,
  rateId: number,
  groupId: number,
  enabled: boolean,
  marketing: anyObject,
  enableForPublic: boolean,
  enableForCarrier: boolean,
  enableForShop: boolean,
  rateSpeed: RateSpeedChoices,
  deliveryTime: string,
  description?: string,
}

export interface RateGroup {
  groupId: number,
  title: string,
}

export type RateConfigurationLineFilter = {
  field: 'weight',
  condition: 'exact' | 'gt' | 'gte' | 'lt' | 'lte',
  value: number,
};

export type RateConfigurationLine = {
  property: 'price_multi' | 'price_addition',
  value: number,
  filter: Array<RateConfigurationLineFilter>,
  configurationId?: number,
};

export interface RateConfiguration {
  percentCost: number,
  configurations: Array<RateConfigurationLine>
}

export interface GroupMarketingRule {
  ruleId: number,
  groupId: number,
  value: number,
  rank: string,
  accountGroup: string,
}

export type CartMarketing = {
  bottomCap: number,
  topCap?: number,
  value: number,
  rateId: number,
  today: boolean,
};

export interface SetRatesList {
  type: typeof SET_RATES_LIST,
  rates: Array<Rate>
}

export interface SetRatesCount {
  type: typeof SET_RATES_COUNT,
  count: number,
}

export interface SetRateGroupsList {
  type: typeof SET_RATE_GROUPS_LIST,
  groups: Array<RateGroup>
}

export interface SetRateGroupsCount {
  type: typeof SET_RATE_GROUPS_COUNT,
  count: number,
}

export interface SetCurrentRate {
  type: typeof SET_CURRENT_RATE,
  rate: Rate,
}

export interface SetCurrentRateGroup {
  type: typeof SET_CURRENT_RATE_GROUP,
  group: RateGroup,
}

export interface SetCurrentRateConfigurations {
  type: typeof SET_CURRENT_RATE_CONFIGURATIONS,
  configurations: RateConfiguration
}

export interface SetCurrentGroupMarketingRules {
  type: typeof SET_CURRENT_GROUP_MARKETING_RULES,
  rules: Array<GroupMarketingRule>
}

export interface SetRateMarketing {
  type: typeof SET_RATE_MARKETING,
  rateId: number,
  marketing: any,
}

export interface SetCartMarketingList {
  type: typeof SET_CART_MARKETING_LIST,
  marketingList: CartMarketing[],
}

export interface ReplaceCartMarketing {
  type: typeof REPLACE_CART_MARKETING,
  marketing: CartMarketing,
}

export type RateAction = SetRatesList | SetRatesCount | SetRateGroupsList | SetRateGroupsCount |
  SetCurrentRate | SetCurrentRateGroup | SetCurrentRateConfigurations | SetCurrentGroupMarketingRules |
  SetRateMarketing | SetCartMarketingList | ReplaceCartMarketing;
