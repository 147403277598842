import {ShortUserInfo} from '../../chat/types';

export interface WaybillContactPhone {
  number: string
}

const WaybillHistoryTypes = ['WEIGHT', 'PRICE', 'REPORT_WEIGHT'];

export type WaybillHistory = {
  date: string,
  type: typeof WaybillHistoryTypes[number],
  value: string,
};

export interface WaybillContactType {
  iso: string,
  postcode: string,
  region: string,
  district: string,
  city: string,
  street: string,
  house: string,
  companyName: string,
  contactName: string,
  longitude: number,
  latitude: number,
  phones: Array<WaybillContactPhone>,
  office: string,
  email: string,
  comment: string,
  addressLine?: string,
  contactId: string,

  intercomNumber?: string,
  intercomCode?: string,
  needPass?: boolean,
  needIdCard?: boolean,
  workingFrom?: string,
  workingTo?: string,
  lunchFrom?: string,
  lunchTo?: string,
  preCallPeriod?: number,
}

export interface WaybillCargoPackage {
  width: number,
  height: number,
  length: number,
  weight: number,
  description: string,
  amount: number,
}

export enum WaybillCargoAdditionalServiceCode {
  INSURANCE = 'INSURANCE',
  CARGO_LOADING = 'CARGO_LOADING',
  CARGO_UNLOADING = 'CARGO_UNLOADING',
  BY_HAND = 'BY_HAND'
}

export type WaybillCargoAdditionalService = {
  serviceCode: string;
};

export type WaybillCargoInsurance = {
  insuranceValueRate: number,
  insuranceAmount: number,
  insuranceRate: string,
};

export interface WaybillCargo {
  cargoId: string,
  packages: Array<WaybillCargoPackage>
  services: WaybillCargoAdditionalService[]
  insurance: WaybillCargoInsurance,
}

export type WaybillRate = {
  rateResultId: string,
  deliveryService: string,
  code: string,
  name: string,
  cost: number,
  days: number,
  price: number
};

export type WaybillOrder = {
  cost: number,
  orderNumber: string,
  status: string
};

export type WaybillTerminal = {
  terminalId: number,
  code: string,
  title: string,
  iso: string,
  city: string,
  address: string,
  comment: string,
  longitude: number,
  latitude: number,
  deliveryService: string,
  geographyId: string,
  postalCode: string,
};

export type WaybillClaimDetail = {
  claimNumber: string,
  claimId: number,
};

export type Waybill = {
  id: number,
  plsNumber: string,
  serviceNumber: string,
  deliveryService: string,
  plsOrderNumber: string,
  serviceOrderNumber: string,
  plsStatus: string,
  createdAt: string,
  record: {
    senderContact: WaybillContactType | null,
    receiverContact: WaybillContactType | null,
    senderTerminal: WaybillTerminal | null,
    receiverTerminal: WaybillTerminal | null,
    cargo: WaybillCargo | null,
    rate: WaybillRate | null,
    pickupDate: string,
    invoiceUrl: string,
    deliveryDate: string,
    exportInvoiceUrl: string,
    forwardPlsWaybillNumber?: string,
    returnPlsWaybillNumber?: string,
    forwardWaybillServiceNumber?: string,
    returnWaybillServiceNumber?: string,
    forwardWaybillId?: string,
    returnWaybillId?: string,
    createdBy?: ShortUserInfo,
    pickupPeriod: string,
    newSenderContact: Partial<WaybillContactType> | null,
    newReceiverContact: Partial<WaybillContactType> | null,
  },
  order?: WaybillOrder,
  attentionId?: number,
  stateData: string,
  state: string,
  cost: number,
  accountId: number,
  sellerId: number,
  creationKey: string | null,
  claim?: WaybillClaimDetail,
  managerComment: string,
  account?: {
    accountId?: number,
    accountTitle?: string,
  }
};

export interface WaybillTracking {
  id: number,
  plsStatus: string,
  description: string,
  date: string,
}

export interface WaybillPricing {
  code: string,
  amount: number,
  comment: string
}

export interface Rate {
  price: number;
  rateResultId: string,
  code: string,
  name: string,
  cost: number,
  maxDays: number,
  minDays: number,
}

export type InsuranceListItem = {
  id: number,
  plsWaybillNumber: string,
  serviceWaybillNumber: string,
  plsOrderNumber: string,
  serviceOrderNumber: string,
  deliveryService: string,
  rate: string,
  createdAt: number,
  accountId: number,

  totalWeight: number,
  totalPlaces: number,

  insuranceValueRate: number,
  insuranceAmount: number,
  insuranceRate: string,
}

export type WaybillControlFilter = {
  filterId: string,
  title: string,
  query: string | null,
  createdAtFrom: null | moment.Moment,
  createdAtTo: null | moment.Moment,
  pickupDateFrom: null | moment.Moment,
  pickupDateTo: null | moment.Moment,
  billingDateFrom: null | moment.Moment,
  billingDateTo: null | moment.Moment,
  deliveryService: string[],
  status: string[],
  additionalService: string[],
  deliveryDateFrom: null | moment.Moment,
  deliveryDateTo: null | moment.Moment,
  rateCode: string[],
  overdue: number | null,
  insuranceFrom: number | null,
  insuranceTo: number | null,
  count: number
}

export enum FilterDateKeys {
  ORDINARY = 'ordinary',
  YESTERDAY = 'yesterday'
}
