import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const getBreadcrumbs = ({ waybills: { currentWaybill: waybill } }: any) => {
  return [
     {
      title: 'Заказы',
      to: '/waybills',
    },
    {
      title: waybill?.serviceNumber,
      to: '/waybills/' + waybill?.id,
    },
    {
      title: 'Изменение веса'
    },
  ]
};

const WaybillDetailForm = React.lazy(() => import('../components/WaybillDetailForm'));
function WaybillView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <WaybillDetailForm
        {...props}
        getBreadcrumbs={getBreadcrumbs}
      />
    </Suspense>
  );
}

export default WaybillView;
