import React from "react";
import { Badge, Icon, Tag } from 'antd';
import { WaybillControlFilter } from "../../app/waybills/types";
import classNames from "classnames";
import useSelector from "../../utils/hooks/useSelector";

type Props = {
    item: WaybillControlFilter;
    filterEditId?: string | null;
    isLinkView?: boolean;
    handleRemove?: () => void;
    handleSetFilter?: () => void;
    handleEditFilter?: () => void;
}

const FastFilter = ({
    item,
    isLinkView,
    filterEditId,
    handleRemove,
    handleSetFilter,
    handleEditFilter
}: Props) => {
    const { user } = useSelector((state) => state.users);
    return (
        <Badge count={item.count} overflowCount={999}>
            <Tag 
                className={classNames("gx-mr-2 gx-mb-2 gx-d-flex gx-align-items-center custom-fast-filter", { "gx-border-primary": filterEditId === item.filterId, "custom-fast-filter-padding": user?.isManager && !isLinkView })} 
                closable={user?.isManager && !isLinkView}
                onClose={() => {
                    if (user?.isManager && !isLinkView && handleRemove) handleRemove();
                }}
                onClick={() => {
                    if (handleSetFilter) handleSetFilter();
                }}>
                <div className="gx-d-flex gx-align-items-center">
                    { item.title }
                    {
                        user?.isManager && !isLinkView
                        ?
                        <div 
                            role="button"
                            onClick={() => {
                                if (handleEditFilter) handleEditFilter();
                            }}
                            className="gx-ml-3 gx-mr-2 gx-pointer">
                            <Icon type="edit" className="custom-fast-filter__edit"/>
                        </div>
                        :
                        null
                    }
                </div>
            </Tag>
        </Badge>
    )
}

FastFilter.defaultProps = {
    filterEditId: null,
    isLinkView: false,
    handleRemove: () => {},
    handleSetFilter: () => {},
    handleEditFilter: () => {},
}

export { FastFilter };