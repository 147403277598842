import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const TrackingReportCreate = React.lazy(() => import('../components/TrackingReport/TrackingReportCreate'));
function CreateTrackingReportView(props: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <TrackingReportCreate {...props} />
    </Suspense>
  );
}

export default CreateTrackingReportView;
