import {
  CartMarketing,
  GroupMarketingRule,
  Rate,
  RateAction,
  RateConfiguration,
  RateGroup,
  REPLACE_CART_MARKETING,
  SET_CART_MARKETING_LIST,
  SET_CURRENT_GROUP_MARKETING_RULES,
  SET_CURRENT_RATE,
  SET_CURRENT_RATE_CONFIGURATIONS,
  SET_CURRENT_RATE_GROUP,
  SET_RATE_GROUPS_COUNT,
  SET_RATE_GROUPS_LIST,
  SET_RATE_MARKETING,
  SET_RATES_COUNT,
  SET_RATES_LIST,
} from './types';
import replaceByFieldValue from "../../utils/array/replace";

export interface RateState {
  ratesList: Array<Rate>,
  ratesCount: number,
  rateGroupsList: Array<RateGroup>,
  rateGroupsCount: number,
  currentRate: null | Rate,
  currentRateGroup: null | RateGroup,
  currentRateConfigurations: null | RateConfiguration,
  currentGroupMarketingRules: Array<GroupMarketingRule>,
  cartMarketing: CartMarketing[],
}

const initialState: RateState = {
  ratesList: [],
  ratesCount: 0,
  rateGroupsList: [],
  rateGroupsCount: 0,
  currentRate: null,
  currentRateGroup: null,
  currentRateConfigurations: null,
  currentGroupMarketingRules: [],
  cartMarketing: [],
};

export default function (state = initialState, action: RateAction): RateState {
  switch (action.type) {
    case SET_RATES_LIST:
      return { ...state, ratesList: action.rates };
    case SET_RATE_MARKETING:
      return {
        ...state,
        ratesList: state.ratesList.map((rate) => {
          if (rate.rateId === action.rateId) {
            return { ...rate, marketing: action.marketing };
          }
          return rate;
        }),
      };
    case SET_RATES_COUNT:
      return { ...state, ratesCount: action.count };
    case SET_RATE_GROUPS_LIST:
      return { ...state, rateGroupsList: action.groups };
    case SET_RATE_GROUPS_COUNT:
      return { ...state, rateGroupsCount: action.count };
    case SET_CURRENT_RATE:
      return { ...state, currentRate: action.rate };
    case SET_CURRENT_RATE_GROUP:
      return { ...state, currentRateGroup: action.group };
    case SET_CURRENT_RATE_CONFIGURATIONS:
      return { ...state, currentRateConfigurations: action.configurations };
    case SET_CURRENT_GROUP_MARKETING_RULES:
      return { ...state, currentGroupMarketingRules: action.rules };
    case SET_CART_MARKETING_LIST:
      return { ...state, cartMarketing: action.marketingList };
    case REPLACE_CART_MARKETING:
      return { ...state, cartMarketing: replaceByFieldValue(state.cartMarketing, ['rateId', 'today', 'bottomCap', 'topCap'], action.marketing) };
    default: return state;
  }
}
