import { ChatAnswerSeries, SET_CHAT_ANSWER_STAT, StatisticActions } from './types';

type StatisticState = {
  chatAnswerStat: ChatAnswerSeries[],
};

const initialState: StatisticState = {
  chatAnswerStat: [],
};

export default function (state = initialState, action: StatisticActions): StatisticState {
  switch (action.type) {
    case SET_CHAT_ANSWER_STAT:
      return { ...state, chatAnswerStat: action.stat };
    default: return state;
  }
}
