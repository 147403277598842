/*  eslint-disable camelcase */
import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import users from './app/users/reducer';
import waybills from './app/waybills/reducer';
import chat from './app/chat/reducer';
import reference from './app/reference/reducer';
import counter from './app/counter/reducer';
import cargo from './app/waybills/components/cargo/reducer';
import rate from './app/rate/reducer';
import dashboard from './app/dashboards/reducer';
import report from './app/report/reducer';
import claim from './app/claim/reducer';
import settings from './app/settings/reducer';
import delivery_service from './app/delivery_service/reducer';
import statistic from './app/statistic/reducer';
import reviews from './app/reviews/reducer';
import tender from './app/tender/reducer';
import notifications from './app/notifications/reducer';
import fakeService from './app/fakeService/reducer';
import phones from './app/phones/reducer';
import terminals from './app/terminals/reducer';
import countries from './app/countries/reducer';
import foreignInvoices from './app/foreignInvoice/reducer';
import ratesCashback from './app/rateCashback/reducer';
import additionalServices from './app/additionalServices/reducer';
import accounts from './app/accounts/reducer';

const history = createBrowserHistory();

const rootReducer = combineReducers({
  users,
  waybills,
  chat,
  reference,
  counter,
  cargo,
  rate,
  report,
  dashboard,
  settings,
  claim,
  delivery_service,
  statistic,
  reviews,
  tender,
  notifications,
  fakeService,
  phones,
  terminals,
  countries,
  foreignInvoices,
  ratesCashback,
  additionalServices,
  accounts,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;
export { history };
