import {
  ADD_WAYBILL_FILTER,
  InsuranceListItem,
  Rate,
  REMOVE_WAYBILL_FILTER,
  RESET_CURRENT_WAYBILL,
  SET_CURRENT_WAYBILL,
  SET_CURRENT_WAYBILL_HISTORY,
  SET_CURRENT_WAYBILL_PRICING,
  SET_CURRENT_WAYBILL_TRACKING,
  SET_INSURANCE_ITEM_COUNT,
  SET_INSURANCE_ITEMS,
  SET_RATE_LIST,
  SET_RATE_TASK,
  SET_SURCHARGE_TYPES,
  SET_WAYBILL_FILTERS_COUNT,
  SET_WAYBILL_FILTERS_LIST,
  SET_WAYBILLS_COUNT,
  SET_WAYBILLS_LIST,
  SET_WAYBILLS_PAGE,
  SET_WAYBILLS_SEARCH,
  UPDATE_WAYBILL_FILTER,
  Waybill,
  WaybillActions,
  WaybillControlFilter,
  WaybillHistory,
  WaybillPricing,
  WaybillTracking,
} from './types';

export interface WaybillState {
  waybillsList: Array<Waybill>,
  currentWaybill: Waybill | null,
  currentWaybillTracking: WaybillTracking[],
  currentWaybillHistory: WaybillHistory[],
  currentWaybillPricing: WaybillPricing[],
  waybillsCount: number,
  waybillsPage: number,
  waybillsSearch: string,
  rateTaskId: string,
  surchargeTypes: Array<any>,
  rateList: Rate[],
  insuranceItems: InsuranceListItem[],
  insuranceItemsCount: number,
  controlFilterList: WaybillControlFilter[],
}

const initialState: WaybillState = {
  waybillsList: [],
  currentWaybill: null,
  currentWaybillTracking: [],
  currentWaybillHistory: [],
  currentWaybillPricing: [],
  waybillsCount: 0,
  waybillsPage: 1,
  waybillsSearch: '',
  rateTaskId: '',
  rateList: [],
  surchargeTypes: [],
  insuranceItems: [],
  insuranceItemsCount: 0,
  controlFilterList: []
};

function fixWaybillCargo(waybill: Waybill): Waybill {
  const _ = { ...waybill };
  if (_?.record?.cargo?.packages) {
    // Фикс точности веса для GRPC
    _.record.cargo.packages = _.record.cargo.packages.map((p) => ({
      ...p,
      weight: parseFloat(p.weight.toPrecision(2)),
    }));
  }
  return _;
}

export default function (state = initialState, action: WaybillActions): WaybillState {
  switch (action.type) {
    case SET_WAYBILLS_LIST:
      return { ...state, waybillsList: action.waybills };
    case SET_CURRENT_WAYBILL:
      return { ...state, currentWaybill: fixWaybillCargo(action.waybill) };
    case SET_CURRENT_WAYBILL_TRACKING:
      return { ...state, currentWaybillTracking: action.tracking };
    case SET_CURRENT_WAYBILL_PRICING:
      return { ...state, currentWaybillPricing: action.pricing };
    case SET_CURRENT_WAYBILL_HISTORY:
      return { ...state, currentWaybillHistory: action.history };
    case SET_WAYBILLS_COUNT:
      return { ...state, waybillsCount: action.count };
    case SET_WAYBILLS_PAGE:
      return { ...state, waybillsPage: action.page };
    case SET_WAYBILLS_SEARCH:
      return { ...state, waybillsSearch: action.search };
    case SET_RATE_TASK:
      return { ...state, rateTaskId: action.taskId };
    case SET_RATE_LIST:
      return { ...state, rateList: action.rates };
    case SET_SURCHARGE_TYPES:
      return { ...state, surchargeTypes: action.surchargeTypes };
    case RESET_CURRENT_WAYBILL:
      return { ...state, currentWaybill: initialState.currentWaybill };
    case SET_INSURANCE_ITEMS:
      return { ...state, insuranceItems: action.items };
    case SET_INSURANCE_ITEM_COUNT:
      return { ...state, insuranceItemsCount: action.count };

    case SET_WAYBILL_FILTERS_LIST:
      return { ...state, controlFilterList: action.payload };
    case ADD_WAYBILL_FILTER:
      return { ...state, controlFilterList: [...state.controlFilterList, action.payload] };
    case SET_WAYBILL_FILTERS_COUNT:
      return { ...state, controlFilterList: state.controlFilterList.map(i => {
        const item = action.payload.find(_ => _.filterId === i.filterId)
        if (item) {
          return {
            ...i,
            count: item.count
          }
        }
        return i
      }) };
    case REMOVE_WAYBILL_FILTER:
      return { ...state, controlFilterList: state.controlFilterList.filter(i => i.filterId !== action.payload) }
    case UPDATE_WAYBILL_FILTER:
      return { ...state, controlFilterList: state.controlFilterList.map(i => {
        if (i.filterId === action.payload.filterId) return action.payload;
        return i;
      }) }

    default:
      return state;
  }
}
