import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const AdditionalServiceList = React.lazy(() => import('../components/AdditionalServiceList'));
function AdditionalServiceListView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AdditionalServiceList {...props} />
    </Suspense>
  );
}

export default AdditionalServiceListView;
