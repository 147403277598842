import { 
  PhonesAction,
  RESET_PHONES,
  SET_PHONES_COUNT,
  SET_PHONES_LIST,
  SET_SINGLE_PHONE,
  SinglePhone 
} from './types';

export interface PhonesState {
  phonesList: Array<SinglePhone>,
  phonesCount: number
}

const initialState: PhonesState = {
  phonesList: [],
  phonesCount: 0
};

export default function (state = initialState, action: PhonesAction): PhonesState {
  switch (action.type) {
    case SET_PHONES_LIST:
      return { ...state, phonesList: action.payload }
    case SET_PHONES_COUNT:
      return { ...state, phonesCount: action.payload }
    case SET_SINGLE_PHONE:
      return { ...state, phonesList: [...state.phonesList.map(i => i.id === action.payload.id ? action.payload : i)] };

    case RESET_PHONES:
      return { ...initialState };

    default: return state;
  }
}
