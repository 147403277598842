import _camelCase from 'lodash/camelCase';
import _forEach from 'lodash/forEach';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';
import _map from 'lodash/map';
import _snakeCase from 'lodash/snakeCase';


function adapt(sourceObject, adaptFunction) {
    if (typeof File !== 'undefined' && sourceObject && sourceObject.constructor === File) {
        // Файлы
        return sourceObject;
    } else if (_isArray(sourceObject)) {
        return _map(sourceObject, value => adapt(value, adaptFunction));
    } else if (_isObject(sourceObject)) {
        let adaptedObject = {};
        let length = null;
        let hasLength = false;
        if (sourceObject.hasOwnProperty('length')){
            length = sourceObject.length;
            hasLength = true;
            delete sourceObject['length'];
        }
        _forEach(sourceObject, (value, key) => {
            const adaptedKey = adaptFunction(key);
            if (_isObject(value) || _isArray(value))
                adaptedObject[adaptedKey] = adapt(value, adaptFunction);
            else
                adaptedObject[adaptedKey] = value;
        });
        if (hasLength){
            adaptedObject.length = length;
        }
        return adaptedObject;
    } else {
        return sourceObject;
    }
}

export const adaptFromApi = sourceObject => adapt(sourceObject, _camelCase);

export const adaptToApi = sourceObject => adapt(sourceObject, _snakeCase);
