import React from 'react';
import ReactDOM from 'react-dom';
import 'moment/locale/ru';
import moment from 'moment';

import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import ConfigProvider from 'antd/es/config-provider';
import antRu from 'antd/es/locale/ru_RU';
import DatePickerRu from 'antd/es/date-picker/locale/ru_RU';
import { YMaps } from 'react-yandex-maps';
import App from './App';
import { history } from './reducers';
import configureStore from './store';
import httpService from './utils/httpService';
import { setToken } from './app/users/action-creators';
import { CLIENT_TIME_FORMAT, USER_DATE_FORMAT } from './utils/constants';

moment.locale('ru');
const store = configureStore({}, httpService);

const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');
let expiredAt = localStorage.getItem('tokenExpire');
if (expiredAt) expiredAt = parseInt(expiredAt, 10);

store.dispatch(setToken(accessToken, refreshToken, expiredAt, false));

// Wrap the rendering in a function:
const render = (Component) => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <YMaps version="2.1" query={{ apikey: 'c7512878-bbda-455d-bd8f-733206352d59' }}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ConfigProvider
              locale={{
                ...antRu,
                DatePicker: {
                  ...DatePickerRu,
                  dateFormat: USER_DATE_FORMAT,
                  dateTimeFormat: `${USER_DATE_FORMAT} ${CLIENT_TIME_FORMAT}`,
                },
              }}
            >
              <Component />
            </ConfigProvider>
          </ConnectedRouter>
        </Provider>
      </YMaps>
    </AppContainer>,
    document.getElementById('root'),
  );
};

// Do this once
// registerServiceWorker();

// Render once
render(App);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./App', () => {
    render(App);
  });
}
