import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const RateCashbackDetail = React.lazy(() => import('../components/RateCashbackDetail'));
function RateCashbackDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <RateCashbackDetail {...props} />
    </Suspense>
  );
}

export default RateCashbackDetailView;
