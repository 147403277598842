import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const RateGroupDetail = React.lazy(() => import('../components/RateGroupDetail'));
function RateGroupDetailView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <RateGroupDetail {...props} />
    </Suspense>
  );
}

export default RateGroupDetailView;
