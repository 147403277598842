import Centrifuge from 'centrifuge';
import { setCent, setToken, signOut } from '../app/users/action-creators';
import { adaptFromApi } from './adapter';
import { ThunkAction } from './types';
import { WSDialogEvent } from '../app/chat/action-creators';
import {loadGlobalCounters} from "../app/counter/action-creators";
import getAppConfig from '../util/getAppConfig';
import { loadControlFilterCounters } from '../app/waybills/action-creators';
import { loadForeignInvoiceDetail } from '../app/foreignInvoice/action-creators';

export default function subscribe(): ThunkAction<void> {
  return (dispatch, getState, http) => {
    const { users: { user, cent, token } } = getState();
    if (!user || !token) return;
    const { id } = user;
    if (cent) cent.disconnect();
    const centrifugeHost = getAppConfig().centrifugeHost;
    const c = new Centrifuge(`wss://${centrifugeHost}/connection/websocket`, {
      onRefresh: (ctx, cb) => {
        http.post('/api/v1/token/refresh/', { refresh_token: token.refreshToken }, { NO_AUTH: true }).then(
          (response: any) => {
            const { accessToken, refreshToken } = response;
            const expiredAt = new Date().getTime() + 300000;
            dispatch(setToken(accessToken, refreshToken, expiredAt));
            cb({ status: 200, data: { token: accessToken } });
          },
          () => {
            dispatch(signOut());
            c.disconnect();
          },
        );
      },
      onPrivateSubscribe: (ctx, cb) => {
        http.post('/api/v1/user/cent/subscribe/', ctx.data).then(
          (response: any) => {
            cb({ status: 200, data: response });
          },
        );
      },
    });
    if (token.accessToken) c.setToken(token.accessToken);
    c.subscribe('$tms:chat', (message) => {
      const { data: { event, ...data } } = adaptFromApi(message);
      console.debug('data', message, event, data);
      dispatch(WSDialogEvent(data, event));
      dispatch(loadGlobalCounters());
      dispatch(loadControlFilterCounters());
    });
    c.subscribe('$tms:claims', (message) => {
      console.log("message claim", message)
      const { data: { event, ...data } } = adaptFromApi(message);
      dispatch(loadGlobalCounters());
    });
    c.subscribe('$tms:foreign_invoice', (message) => {
      console.log("message claim", message)
      const { data: { event, ...data } } = adaptFromApi(message);
      dispatch(loadGlobalCounters());
      if (event === "complete_foreign_invoice") {
        dispatch(loadForeignInvoiceDetail(data.foreignInvoiceId));
      }
    });
    c.connect();
    dispatch(setCent(c));
  };
}
