import {
  Account, 
  AccountActions,
  SET_ACCOUNTS_COUNT,
  SET_ACCOUNTS_LIST,
} from './types';

export interface AccountState {
  accountsList: Account[],
  accountsCount: number,
}

const initialState: AccountState = {
  accountsList: [],
  accountsCount: 0,
};

export default function (state = initialState, action: AccountActions): AccountState {
  switch (action.type) {
    case SET_ACCOUNTS_LIST:
      return { ...state, accountsList: action.accounts };
    case SET_ACCOUNTS_COUNT:
      return { ...state, accountsCount: action.count };
    default:
      return state;
  }
}
