import {
  ADD_TENDER_RATE_PRIORITY,
  REMOVE_TENDER_RATE_PRIORITY,
  REPLACE_TENDER_RATE_PRIORITY,
  RESET_TENDER_RATE_PRIORITY_LIST,
  SET_TENDER_RATE_PRIORITY_LIST,
  TenderActions,
  TenderRatePriority,
} from './types';
import removeByFieldValue from '../../utils/array/remove';
import replaceByFieldValue from '../../utils/array/replace';

type TenderState = {
  tenderRatePriorityList: TenderRatePriority[],
};

const initialState: TenderState = {
  tenderRatePriorityList: [],
};

export default function (state = initialState, action: TenderActions): TenderState {
  switch (action.type) {
    case SET_TENDER_RATE_PRIORITY_LIST:
      return { ...state, tenderRatePriorityList: action.tenderRatePriorityList };
    case ADD_TENDER_RATE_PRIORITY:
      return { ...state, tenderRatePriorityList: [...state.tenderRatePriorityList, action.tenderRatePriority] };
    case REMOVE_TENDER_RATE_PRIORITY:
      return { ...state, tenderRatePriorityList: removeByFieldValue(state.tenderRatePriorityList, 'tenderRatePriorityId', action.tenderRatePriorityId) };
    case REPLACE_TENDER_RATE_PRIORITY:
      return { ...state, tenderRatePriorityList: replaceByFieldValue(state.tenderRatePriorityList, 'tenderRatePriorityId', action.tenderRatePriority) };
    case RESET_TENDER_RATE_PRIORITY_LIST:
      return { ...state, tenderRatePriorityList: initialState.tenderRatePriorityList };
    default: return state;
  }
}
