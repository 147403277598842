export const SET_ACCOUNTS_LIST = 'SET_ACCOUNTS_LIST';
export const SET_ACCOUNTS_COUNT = 'SET_ACCOUNTS_COUNT';

export interface Owner {
  userId: number,

  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  dateJoined?: string,
}

export interface Account {
  accountId: number,
  title: string,
  owner: Owner,
}


export interface SetAccountsList {
  type: typeof SET_ACCOUNTS_LIST,
  accounts: Account[],
}

export interface SetAccountsCount {
  type: typeof SET_ACCOUNTS_COUNT,
  count: number,
}

export type AccountActions = SetAccountsList | SetAccountsCount;