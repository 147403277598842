import {
  DialogReview,
  RESET_CURRENT_DIALOG_REVIEW,
  RESET_CURRENT_DIALOG_REVIEW_QUESTION_LIST,
  ReviewActions,
  ReviewQuestion,
  SET_CURRENT_DIALOG_REVIEW,
  SET_CURRENT_DIALOG_REVIEW_QUESTION_LIST,
  SET_DIALOG_REVIEW_COUNT,
  SET_DIALOG_REVIEW_LIST,
} from './types';

type ReviewState = {
  dialogReviewList: DialogReview[],
  dialogReviewCount: number,
  currentDialogReview: DialogReview | null,
  currentDialogReviewQuestionList: ReviewQuestion[]
};

const initialState: ReviewState = {
  dialogReviewList: [],
  dialogReviewCount: 0,
  currentDialogReview: null,
  currentDialogReviewQuestionList: [],
};

export default function (state = initialState, action: ReviewActions): ReviewState {
  switch (action.type) {
    case SET_DIALOG_REVIEW_LIST:
      return { ...state, dialogReviewList: action.list };
    case SET_DIALOG_REVIEW_COUNT:
      return { ...state, dialogReviewCount: action.count };
    case SET_CURRENT_DIALOG_REVIEW:
      return { ...state, currentDialogReview: action.review };
    case RESET_CURRENT_DIALOG_REVIEW:
      return { ...state, currentDialogReview: initialState.currentDialogReview };
    case SET_CURRENT_DIALOG_REVIEW_QUESTION_LIST:
      return { ...state, currentDialogReviewQuestionList: action.list };
    case RESET_CURRENT_DIALOG_REVIEW_QUESTION_LIST:
      return { ...state, currentDialogReviewQuestionList: initialState.currentDialogReviewQuestionList };
    default: return state;
  }
}
