import { 
  SET_ADDITIONAL_SERVICES_CONFIGURATION_LIST,
  SET_ADDITIONAL_SERVICES_CONFIGURATION_COUNT,
  SET_CURRENT_ADDITIONAL_SERVICES_CONFIGURATION,
  SET_ADDITIONAL_SERVICES_SETTING_LIST,
  RESET_ADDITIONAL_SERVICE,
  AdditionalServiceAction,
  AdditionalServiceConfiguration,
  AdditionalServiceSetting,
} from './types';

export interface AdditionalServiceState {
  additionalServiceCfgList: Array<AdditionalServiceConfiguration>,
  additionalServiceCfgCount: number,
  currentAdditionalServiceCfg: AdditionalServiceConfiguration | null,
  additionalServiceSettingList: Array<AdditionalServiceSetting>,
}

const initialState: AdditionalServiceState = {
  additionalServiceCfgList: [],
  additionalServiceCfgCount: 0,
  currentAdditionalServiceCfg: null,
  additionalServiceSettingList: [],
};

export default function (state = initialState, action: AdditionalServiceAction): AdditionalServiceState {
  switch (action.type) {
    case SET_ADDITIONAL_SERVICES_CONFIGURATION_LIST:
      return { ...state, additionalServiceCfgList: action.payload }
    case SET_ADDITIONAL_SERVICES_CONFIGURATION_COUNT:
      return { ...state, additionalServiceCfgCount: action.payload }
    case SET_CURRENT_ADDITIONAL_SERVICES_CONFIGURATION:
      return { ...state, currentAdditionalServiceCfg: action.payload };

    case SET_ADDITIONAL_SERVICES_SETTING_LIST:
      return { ...state, additionalServiceSettingList: action.payload };

    case RESET_ADDITIONAL_SERVICE:
      return { ...initialState };

    default: return state;
  }
}
