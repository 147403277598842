import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const OperatorSchedulerSettingsTable = React.lazy(() => import('../components/OperatorSchedulerSettingsTable'));

function OperatorSchedulerSettingsTableView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <OperatorSchedulerSettingsTable />
    </Suspense>
  );
}

export default OperatorSchedulerSettingsTableView;
