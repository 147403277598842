import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';


const WaybillList = React.lazy(() => import('../components/WaybillOldList'));
function WaybillListOldView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <WaybillList {...props} />
    </Suspense>
  );
}

export default WaybillListOldView;
