import React from 'react';
import {
  Button,
  Card,
  Table,
} from 'antd';
import { useHistory } from 'react-router';
import Icon from 'antd/es/icon';
import { ColumnProps } from 'antd/es/table';
import useSelector from '../../../utils/hooks/useSelector';
import { Account } from '../types';
import useDispatch from '../../../utils/hooks/useDispatch';
import { getUserToken } from '../action-creators';

interface AccountTableProps {
  loading: boolean,
}

function AccountTable({ loading }: AccountTableProps) {
  const accountsList = useSelector((state) => state.accounts.accountsList);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (record: Account) => {
    history.push(`/accounts/${record.accountId}`);
  };

  const columns: ColumnProps<Account>[] = [
    {
      title: 'Название юрлица',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (<span>{text}</span>),
    },
    {
      title: 'Зайти в лк',
      key: 'lkFront',
      render: (_, record) => (
        <Button 
          type='primary'
          size='small'
          className='gx-mb-0'
          onClick={(e: any) => {
            e.stopPropagation();
            dispatch(getUserToken(record.owner.userId))
          }}>Авторизоваться</Button>
      ),
    },
  ];

  return (
    <Card>
      <Table
        style={{ margin: -24 }}
        className="gx-table-responsive"
        columns={columns}
        onRowClick={handleClick}
        rowClassName={() => 'gx-pointer'}
        loading={loading}
        dataSource={accountsList}
        pagination={false}
      />
    </Card>
  );
}

export default AccountTable;
