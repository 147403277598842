import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const RateGroupList = React.lazy(() => import('../components/RateGroupList'));
function RateGroupListView(props: Object) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <RateGroupList {...props} />
    </Suspense>
  );
}

export default RateGroupListView;
