import React from 'react';
import CircularProgress from '../../../components/CircularProgress';

const InsuranceDetail = React.lazy(() => import('../components/InsuranceDetail'));

function InsuranceDetailView(props: any) {
  return (
    <React.Suspense fallback={<CircularProgress />}>
      <InsuranceDetail {...props} />
    </React.Suspense>
  );
}

export default InsuranceDetailView;
