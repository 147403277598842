export const SET_FAKE_CONFIGURATIONS_GROUPS_LIST = 'SET_FAKE_CONFIGURATIONS_GROUPS_LIST';
export const SET_FAKE_CONFIGURATIONS_GROUPS_COUNT = 'SET_FAKE_CONFIGURATIONS_GROUPS_COUNT';
export const SET_CURRENT_FAKE_CONFIGURATION_GROUP = 'SET_CURRENT_FAKE_CONFIGURATION_GROUP';

export const SET_FAKE_CONFIGURATIONS_LIST = 'SET_FAKE_CONFIGURATIONS_LIST';
export const SET_FAKE_CONFIGURATIONS_COUNT = 'SET_FAKE_CONFIGURATIONS_COUNT';
export const SET_CURRENT_FAKE_CONFIGURATION = 'SET_CURRENT_FAKE_CONFIGURATION';

export const RESET_CURRENT_GROUP = 'RESET_CURRENT_GROUP';


export interface FakeSetting {
  field: string,
  condition?: string,
  value?: number | null,
  textValue?: string | null,
}

export interface FakeConfiguration {
  configurationId: number,
  groupId: number,
  value: number,
  days: string,
  belkapostRates: string[],
  deliveryType: string,
  settings: FakeSetting[],
  enabled: boolean,
  isReverse: boolean
}

export interface FakeConfigurationGroup {
  groupId: number,
  placeFrom: string,
  placeTo: string,
  days: number,
  tariffs: string,
}

export interface ManualWeightSetting {
  configurationId: number, 
  value?: number, 
  weight?: number, 
  condition?: string
}

export interface AutoWeightSettings {
  diffWeight?: number,
  costWeight?: number,
  toWeight?: number,
  fromWeight?: number,
  startCost?: number
}


export interface SetFakeConfigurationsGroupsList {
  type: typeof SET_FAKE_CONFIGURATIONS_GROUPS_LIST,
  payload: Array<FakeConfigurationGroup>
}

export interface SetFakeConfigurationsGroupsCount {
  type: typeof SET_FAKE_CONFIGURATIONS_GROUPS_COUNT,
  payload: number
}

export interface SetCurrentFakeConfigurationGroup {
  type: typeof SET_CURRENT_FAKE_CONFIGURATION_GROUP,
  payload: FakeConfigurationGroup
}

export interface SetFakeConfigurationsList {
  type: typeof SET_FAKE_CONFIGURATIONS_LIST,
  payload: Array<FakeConfiguration>
}

export interface SetFakeConfigurationsCount {
  type: typeof SET_FAKE_CONFIGURATIONS_COUNT,
  payload: number,
}

export interface SetCurrentFakeConfiguration {
  type: typeof SET_CURRENT_FAKE_CONFIGURATION,
  payload: FakeConfiguration
}

export interface ResetCurrentGroup {
  type: typeof RESET_CURRENT_GROUP
}

export type FakeServiceAction = 
  SetFakeConfigurationsList | 
  SetFakeConfigurationsCount | 
  SetCurrentFakeConfiguration |
  ResetCurrentGroup |
  SetFakeConfigurationsGroupsList |
  SetFakeConfigurationsGroupsCount |
  SetCurrentFakeConfigurationGroup;
