import React from 'react';
import { Col, Row } from 'antd';
import SimplePagination from 'components/SimplePagination';
import { useHistory } from 'react-router';
import SearchBox from './SearchBox';
import ListModeSwitch from './ListModeSwitch';

function List({
  customFilters = null,
  children,
  title,

  searchValue = '',
  searchPlaceholder = '',
  onSearchChange = null,

  pageSize = 25,
  currentPage = 1,
  totalCount = null,
  handlePageChange = null,

  withFavorites = false,
  filters = [],
  tags = [],
  loading = false,
  addon = null,

  withGraphMode = false,
  onChartClick = null,

  fastFilters = [],
  handleSaveFilter = () => {},
  handleCancelEdit = () => {},
  filterEditId = ''
}) {
  const history = useHistory();

  return (
    <>
      <Row>
        {!!fastFilters && (
          <Col xs={24}>
            {fastFilters}
          </Col>
        )}
        <Col lg={withGraphMode ? 10 : 13} md={withGraphMode ? 6 : 10} sm={12} xs={12}>
          <div className="title h1"><p>{title}</p></div>
        </Col>
        <Col lg={!!handlePageChange ? 4 : 11} md={!!handlePageChange ? 5 : 14} sm={12} xs={12}>
          {addon}
        </Col>
        {!!handlePageChange && !!totalCount && (
          <Col lg={7} md={9} sm={withGraphMode ? 18 : 24} xs={withGraphMode ? 16 : 24}>
            <SimplePagination
              current={currentPage}
              pageSize={pageSize}
              total={totalCount}
              loading={loading}
              onChange={handlePageChange}
            />
          </Col>
        )}
        {withGraphMode && (
          <Col lg={3} md={4} sm={6} xs={8}>
            <div className="gx-float-right">
              <ListModeSwitch onChartClick={onChartClick} />
            </div>
          </Col>
        )}
      </Row>
      {!!onSearchChange && (
        <Row>
          <Col xs={24}>
            <SearchBox
              searchValue={searchValue}
              searchPlaceholder={searchPlaceholder}
              onSearchChange={onSearchChange}
              filters={filters}
              tags={tags}
              withFavorites={withFavorites}
              handleSaveFilter={handleSaveFilter}
              handleCancelEdit={handleCancelEdit}
              filterEditId={filterEditId}
            />
          </Col>
        </Row>
      )}
      {!!customFilters && (
        <Row>
          <Col xs={24}>
            {customFilters}
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={24}>
          {children}
        </Col>
      </Row>
    </>
  );
}

export default List;
