import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { omit } from 'lodash';
import { COMMON_EXCLUDED_FOR_MANAGER } from '../utils/constants';

function PrivateRoute({ component: Component, ...rest }) {
  const authorized = useSelector((state) => !!state.users.user);
  const { user } = useSelector((state) => state.users);

  const componentParams = React.useMemo(() => omit(rest, ['render', 'children', 'path', 'exact', 'strict', 'location', 'sensitive']), [rest]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authorized) {
          if ( user.isManager && !user.isAdmin && COMMON_EXCLUDED_FOR_MANAGER.includes(rest.path) ) {
            return <Redirect to="/404" />
          }
          return <Component {...props} {...componentParams} />
        }
        return <Redirect to="/login" from={props.location} />
      }}
    />
  );
}

export default PrivateRoute;
