import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ActiveDialogReviewDetail = React.lazy(() => import('../components/ActiveDialogReviewDetail'));
function ActiveDialogReviewDetailView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ActiveDialogReviewDetail {...props} />
    </Suspense>
  );
}

export default ActiveDialogReviewDetailView;
