export const SET_PHONES_LIST = 'SET_PHONES_LIST';
export const SET_PHONES_COUNT = 'SET_PHONES_COUNT';
export const SET_SINGLE_PHONE = 'SET_SINGLE_PHONE';
export const RESET_PHONES = 'RESET_PHONES';

export interface SinglePhone {
  id: number,
  phoneNumber: string,
  clientPhoneNumber?: string,
  plsWaybillNumber?: string,
  side: number
}

export interface SetPhonesList {
  type: typeof SET_PHONES_LIST,
  payload: Array<SinglePhone>
}

export interface SetPhonesCount {
  type: typeof SET_PHONES_COUNT,
  payload: number,
}

export interface SetSinglePhone {
  type: typeof SET_SINGLE_PHONE,
  payload: SinglePhone
}

export interface ResetPhones {
  type: typeof RESET_PHONES
}

export type PhonesAction = 
  SetPhonesList | 
  SetPhonesCount | 
  SetSinglePhone |
  ResetPhones;
