import { ThunkAction } from '../../utils/types';
import {
  Account,
} from './types';

export const setAccountsList = (accounts: Array<Account>) => ({
  type: 'SET_ACCOUNTS_LIST',
  accounts,
});

export const setAccountsCount = (count: number) => ({
  type: 'SET_ACCOUNTS_COUNT',
  count,
});

export const loadAccounts = (search = '', page = 1): ThunkAction => (
  dispatch, getState, http,
) => {
  const params: any = { query: search, page };
  return http.get('/api/v1/accounts/list/', params).then(
    (response: any) => {
      const { accounts, count } = response;
      dispatch(setAccountsList(accounts || []));
      dispatch(setAccountsCount(count));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const getUserToken = (userId: number): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/get_user_token/', { user_id: userId }).then(
  (response: anyObject) => {
    const { accessToken, refreshToken } = response;
    window.open(`https://lk.belkapost.ru/login_as?accessToken=${accessToken}&refreshToken=${refreshToken}`, '_blank');
  },
);